import { t } from "i18next";
import React from "react";
import { getSiteContent } from "../../Reducer/api";
import { useEffect } from "react";
import { useState } from "react";
import i18n from "../../i18n";
import { marked } from "marked";
import { useSelector } from "react-redux";

export default function Cookies() {
  const language = useSelector((state) => state.auth.language);
  
  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : "ku";
  const [data, setData] = useState("");

  useEffect(() => {
    getSiteContent('cookies').then((res) => {
      res.json().then((r) => {
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);

  // let mammoth = require("mammoth");
  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });

  return (
    <div className=" staticConditions container min-vh-100">

      {/* <p>
        <p style={{ textAlign: "center" }}>
          <strong style={{ fontSize: "30px" }}>Cookie Policy</strong>
        </p>
        <p>What are cookies?</p>
        <p>
          Cookies are tiny text files stored on your computer when you visit
          certain web pages. At Ako Sayara we use cookies to keep track of the
          options you have selected on the Website/App and to remember you when
          you return to our site.
        </p>
        <p>
          To get the best Ako Sayara experience, you need to have cookies
          enabled. If you don't wish to enable cookies, you will still be able
          to use the site but you may not be able to use some of the essential
          features we offer such as placing an advert so we recommend you keep
          them enabled.
        </p>
        <p>
          Cookies don't harm your computer. Like other online companies, we use
          cookies to provide you with more relevant and useful information. We
          don't store personally identifiable information such as credit card
          details in cookies we create, but we do use information gathered from
          cookies to help improve your experience of the site.
        </p>
        <p>
          We use cookies to personalise content and ads, provide social media
          features and analyse traffic. We also share information with social
          media, advertising and analytics partners who may combine it with
          other information that you've provided to them or that they've
          collected from your use of their services. By using our site, you
          consent to our use of cookies. See below for more information about
          the cookies we use.
        </p>
        <p>Can I opt out?</p>
        <p>
          We do have relationships with carefully-selected and monitored
          suppliers who may also set cookies during your visit to Ako Sayara;
          they will show you different products and services based on what you
          appear to be interested in. If you'd like to opt out, please go to the{" "}
          <a href="http://optout.networkadvertising.org/?c=1#!/">
            Network Advertising Initiative website
          </a>{" "}
          (opens in a new window - please note that we're not responsible for
          the content of external websites)
        </p>
        <p>
          <strong>Cookies policy</strong>
        </p>
        <p>
          To make full use of the features on our site you will need to accept
          cookies, as we can only provide you with certain personalised features
          of this website by using them.
        </p>
        <p>
          If your computer is configured to accept cookies we will be able to
          provide time saving functionality such as:
        </p>
        <ul>
          <li>
            The ability to pre-fill the search form with your address, thus
            preventing the need to input it each time you want to perform a
            search.
          </li>
          <li>
            The ability to login to your Ako Sayara account and place adverts on
            the site.
          </li>
        </ul>
        <p>
          However, if you'd prefer to restrict, block or delete cookies from
          akosayara.com, or any other website, you can use your browser to do
          this. Each browser is different, so check the 'Help' menu of your
          particular browser to learn how to change your cookie preferences.
        </p>
        <p>
          For further information on cookies or for details on how to delete
          them or refuse their installation on your computer, please visit{" "}
          <a href="http://www.aboutcookies.org/">
            http://www.aboutcookies.org/
          </a>
        </p>
        <p>Cookies used on the Website/App</p>
        <p>Here is a list of how we use cookies on the Website/App</p>
        <p>Google analytics</p>
        <p>Cookies: ga</p>
        <p>
          We use Google Analytics to carry out statistical analysis of page use,
          page interactions and paths through the Website to evaluate and
          develop our Website/App. This is known as 'digital analytics'. We may
          also record certain information that customers provide during a
          website purchase or other process.
        </p>
        <p>
          This information allows us to understand individual behaviours and
          needs more accurately.{" "}
        </p>
        <p>
          For more information on how Google uses the data collected via this
          service, see here:{" "}
          <a href="http://www.google.com/policies/privacy/partners/">
            http://www.google.com/policies/privacy/partners/
          </a>
        </p>
        <p>
          To opt out of being tracked via Google Analytics, you can also use
          Google's opt-out browser add-on:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
        </p>
        <p>
          <strong>Google DoubleClick</strong>
        </p>
        <p>In their own words:</p>
        <p>
          DoubleClick for Publishers (DFP) products provide ad management and ad
          serving solutions to companies that buy, create or sell online
          advertising.
        </p>
        <p>What does the company do?</p>
        <p>
          Technology that delivers advertisements to websites and monitors
          progress and performance of ad campaigns.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://support.google.com/policies/troubleshooter/2990837?hl=en">
            https://support.google.com/policies/troubleshooter/2990837?hl=en
          </a>
        </p>
        <p>
          More info -{" "}
          <a href="https://www.google.com/policies/technologies/cookies/">
            https://www.google.com/policies/technologies/cookies/
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://adssettings.google.com/">
            https://adssettings.google.com
          </a>
        </p>
        <p>Google Ad Exchange</p>
        <p>In their own words:</p>
        <p>
          DoubleClick Ad Exchange connects ad networks, agencies and third-party
          demand-side platforms with a vast global inventory in real time.
        </p>
        <p>What does the company do?</p>
        <p>
          Technology that delivers advertisements to websites and monitors
          progress and performance of ad campaigns.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://support.google.com/policies/troubleshooter/2990837?hl=en">
            https://support.google.com/policies/troubleshooter/2990837?hl=en
          </a>
        </p>
        <p>
          More info -{" "}
          <a href="https://www.doubleclickbygoogle.com/solutions/digital-marketing/ad-exchange/">
            https://www.doubleclickbygoogle.com/solutions/digital-marketing/ad-exchange/
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://www.google.com/intl/en/policies/technologies/cookies/">
            https://www.google.com/intl/en/policies/technologies/cookies/
          </a>
        </p>
        <p>
          <strong>Sophus3</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Our services range from industry benchmarking services to management
          information solutions to individual projects. Our advanced and
          objective analysis gives you the insight you need to make strong
          business decisions.
        </p>
        <p>What does the company do?</p>
        <p>
          Our services range from industry benchmarking services to management
          information solutions to individual projects. Our advanced and
          objective analysis gives you the insight you need to make strong
          business decisions.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="http://www.sophus3.com/contact-us">
            http://www.sophus3.com/contact-us
          </a>
        </p>
        <p>
          More info -{" "}
          <a href="http://www.sophus3.com/services-privacy-policy/">
            http://www.sophus3.com/services-privacy-policy/
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://www.sophus3.com/privacy-cookie-policy/">
            https://www.sophus3.com/privacy-cookie-policy/
          </a>
        </p>
        <p>
          <strong>The Trade Desk</strong>
        </p>
        <p>In their own words:</p>
        <p>
          The Trade Desk is the world's largest independent buy-side only Demand
          Side Platform (DSP). Our company was founded on the core pillars of
          objectivity and complete transparency in an omnichannel platform built
          for advertisers and their agencies.
        </p>
        <p>What does the company do?</p>
        <p>
          The Trade Desk's platform offers a marketplace of inventory, data,
          targeting, measurement, and verification features aligned with our
          clients' marketing needs. Our business model alignment to the buy-side
          means that we have no conflicting interests with products we develop
          or where we direct working media. Unlike the vast majority of DSPs, we
          do not arbitrage any inventory and do not take undisclosed supply-side
          margins. We provide complete transparency into every dollar spent –
          from platform fees, data, and targeting features, to, of course, media
          itself. Our independence and objectivity are more valuable today than
          ever before. Marketers working with The Trade Desk can be sure they
          are maximizing their return on advertising investment without bias.
          Our position as the leading independent, objective, transparent
          demand-side platform continues to grow. An ever-increasing number of
          marketers and advertisers are telling us that we deliver ROI and
          insights that nobody else can. In addition to our market leading
          strategy and technology, we are also considered the best service
          provider in the industry, offering our clients dedicated teams that
          are experienced in helping brands build out programmatic principles
          and capabilities.
        </p>
        <p>
          <strong>Facebook / Instagram</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Facebook gives people the power to share and makes the world more open
          and connected. Millions of people use Facebook every day to keep up
          with friends, upload an unlimited number of photos, share links and
          videos, and learn more about the people they meet.
        </p>
        <p>What does the company do?</p>
        <p>
          Seller of aggregated publisher ad inventory from many websites. Many
          ad networks use proprietary technology and partner with data
          aggregators to target ads.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://www.facebook.com/full_data_use_policy">
            https://www.facebook.com/full_data_use_policy
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://en-gb.facebook.com/policies/cookies/">
            https://en-gb.facebook.com/policies/cookies/
          </a>
        </p>
        <p>
          <strong>Scorecardresearch.com</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Scorecard Research, a service of Full Circle Studies, Inc. and part of
          the comScore, Inc. market research group, (“Scorecard Research”, “we”,
          “us”, or “our”) collects digital consumption behaviour data, which may
          include information about your activities on your computer or device,
          your online activities, your video streaming activities, and other
          activities, as part of comScore's broad market research efforts. We
          use this data to create reports on digital consumption behaviour and
          trends, and to provide research and information products to our
          clients that inform our clients about consumers' preferences and
          interests.
        </p>
        <p>What does the company do?</p>
        <p>
          comScore collects aggregated consumer behaviour across the internet in
          order to create reports about worldwide internet usage patterns.
        </p>
        <p>
          If you wish to find out more about the supplier's use of cookies,
          please refer to their privacy policy at{" "}
          <a href="https://www.scorecardresearch.com/privacy.aspx">
            https://www.scorecardresearch.com/privacy.aspx
          </a>
        </p>
        <p>
          <strong>Integral Ad Science</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Integral Ad Science is a global technology &amp; data company that
          empowers the ad industry to effectively influence consumers on every
          device.
        </p>
        <p>What does the company do?</p>
        <p>
          Integral Ad Science (originally AdSafe Media) is a privately held
          technology company that analyses the value of digital advertising
          placements. Integral Ad Science is known for addressing issues around
          fraud, viewability, brand risk and TRAQ.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://integralads.com/privacy-policy/">
            https://integralads.com/privacy-policy/
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://integralads.com/cookie-policy/">
            https://integralads.com/cookie-policy/
          </a>
        </p>
        <p>
          <strong>Content Square</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Content Square is a solution which aggregates usage and frequency data
          to improve user experience.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://www.contentsquare.com/privacy-policy/">
            https://www.contentsquare.com/privacy-policy/
          </a>
        </p>
        <p>
          <strong>Get Feedback (Usabilla)</strong>
        </p>
        <p>In their own words:</p>
        <p>
          Get Feedback is a modern CX platform that combines the best of two
          SurveyMonkey acquisitions: Get Feedback and its Salesforce ecosystem
          leadership with Usabilla's popular digital feedback technology.
        </p>
        <p>What does the company do?</p>
        <p>
          Allows you to gather and transform customer insights into meaningful
          action in days, not months, so you can quickly adapt to changing
          customer needs.
        </p>
        <p>
          Privacy contact -{" "}
          <a href="https://www.getfeedback.com/privacy">
            https://www.getfeedback.com/privacy
          </a>
        </p>
        <p>
          Opt out -{" "}
          <a href="https://www.getfeedback.com/privacy">
            https://www.getfeedback.com/privacy
          </a>
        </p>
        <p>
          <strong>Live Chat Service</strong>
        </p>
        <p>
          We use a third party’s supplier to provide the Live Chat Service. The
          cookies are used to manage your live chat session, to track your
          preferences and to personalise your experience. You may disable
          persistent cookies. However, if you disable the session cookie you
          will not be able to use the Live Chat Service. If you wish to find out
          more about the supplier's use of cookies, please refer to their
          privacy policy at{" "}
          <a href="https://www.contactatonce.com/privacy-policy/">
            https://www.contactatonce.com/privacy-policy/
          </a>
        </p>
        <br />
        <br />
        <p style={{textAlign: 'center'}}>
          <strong style={{fontSize: '25px'}}>Third party cookies</strong>
        </p>
        <p>
          <strong>Twitter</strong>
        </p>
        <p>
          Twitter is an online news and social networking service on which users
          post and interact with messages known as "tweets".
        </p>
        <p>Static.ads-twitter.com</p>
        <p>
          Cookies are used to measure the performance of the ads that we pay for
          on Twitter. We also use cookies to deliver targeted advertising on
          Twitter.
        </p>
        <p>
          <strong>Facebook</strong>
        </p>
        <p>Connect.facebook.net</p>
        <p>
          Facebook is an online social media and social networking service.
          Cookies are used to measure the performance of the ads that we pay for
          on Facebook. We also use cookies to deliver targeted advertising on
          Facebook.
        </p>
        <p>
          <strong>Instagram</strong>
        </p>
        <p>Connect.facebook.net</p>
        <p>
          Instagram is a photo and video-sharing social networking service owned
          by Facebook. Cookies are used to measure the performance of the ads
          that we pay for on Instagram. We also use cookies to deliver targeted
          advertising on Instagram.
        </p>
        <p>
          The Trade Desk SaaS Solutions process pseudonymous data. Our Terms of
          Subscription Service prohibit the use of the SaaS Solutions to
          collect, process, and store certain types of data, such as sensitive
          data or data that directly identifies individuals. our Technology
          collects information generally known as pseudonymous or non-personally
          identifiable information (“Non-PII”) that may include, but is not
          limited to, your IP host address, the date and time of the ad request,
          specific location information of your device (which may be derived
          from, for example, your device's GPS, Bluetooth, or WIFI signals),
          pages viewed, browser type, the referring URL, Internet Service
          Provider, and your computer's operating system, as well as how you
          interact with a web page or ad. We may also collect Non-PII such as
          age, gender, income, education, interest, and usage activity from
          Clients or other third parties. We also may collect analogous Non-PII
          from mobile platforms, which may include information about your
          interactions with those applications (including your IP address and
          time-stamp information), and information about your mobile device
          (including device type, handset name, operating system information,
          mobile identifiers such as Apple IDFA and Android Advertising ID,
          app-specific identifiers, and a list of the applications your device
          interacts with).
        </p>
        <p>
          <strong>Doubleclick Bid Manager</strong>
        </p>
        <p>Doubleclick.net</p>
        <p>
          Doubleclick Bid Manager is a Google product. Doubleclick Bid Manager
          is an advertising platform that allows us to buy display and video
          advertising online. Cookies are used to measure the performance of the
          ads that we pay for on Doubleclick Bid Manager. We also use cookies to
          deliver targeted display and video advertising online.
        </p>
        <p>
          <strong>Doubleclick for Search</strong>
        </p>
        <p>Doubleclick.net</p>
        <p>
          Doubleclick for Search is a Google product. Doubleclick for Search is
          an advertising platform that allows us to buy adverts on Google
          Search. Cookies are used to measure the performance of the ads that we
          pay for on Doubleclick for Search. We also use cookies to deliver
          targeted advertising on Doubleclick for Search.
        </p>
        <p>
          <strong>Doubleclick Campaign Manager</strong>
        </p>
        <p>Doubleclick.net</p>
        <p>
          Doubleclick Campaign Manager is a Google product. Doubleclick Campaign
          Manager is an advertising platform that simplifies campaign planning
          and reporting.
        </p>
        <p>
          Cookies are used to measure the performance of the ads that we pay for
          on Doubleclick Bid Manager and Doubleclick for Search. We also use
          cookies to deliver targeted advertising on Doubleclick Bid Manager and
          Doubleclick Search
        </p>
        <p>
          <strong>Google Ad Words</strong>
        </p>
        <p>Googleads.g.doubleclick.net or googleadservices.com</p>
        <p>
          Google Ad Words is an advertising platform that allows us to buy
          adverts on Google Search.
        </p>
        <p>
          Cookies are used to measure the performance of the ads that we pay for
          on Google Ad Words. We also use cookies to deliver targeted
          advertising on Google Ad Words.
        </p>
        <p>
          <strong>Bing</strong>
        </p>
        <p>new UET or bat.bing.com</p>
        <p>
          Bing is an advertising platform that allows us to buy adverts on Bing
          Search. Cookies are used to measure the performance of the ads that we
          pay for on Bing Search. We also use cookies to deliver targeted
          advertising on Bing Search.
        </p>
        <br />
        <br />
        <p style={{textAlign: 'center'}}>
          <strong style={{fontSize: '25px'}}>First party cookies</strong>
        </p>
        <p>
          Ako Sayara first party cookies used on the website - Registration -
          Cookies: user, ursid, urss (session), userid, ursa, UpdateAccount,
          pageParameters
        </p>
        <p>
          We use cookies on the Ako Sayara website/App whenever you login. This
          is done in order to remember who you are, both for your current visit
          and on return visits, saving you having to login again. We store the
          following personal details: first name, year of birth{" "}
          <em>(if supplied), gender, county, address.</em>
        </p>
        <p>
          Whenever you are not logged in, we use a non-personally identifiable
          cookie to understand the number of unique visitors who look at the
          adverts on the site.
        </p>
        <p>
          Used and new vehicle search - Cookies: searches, SearchData, address,
          GeoLocation
        </p>
        <p>
          In order to provide some of the functionality within the new and used
          vehicle search sections of our site, we need to store some information
          in cookies related to the searches you have been performing. For
          example, whenever you compare different vehicles or adverts, we need
          to store the identifiers for those vehicles and adverts. We store the
          postcode you have entered for searches to enable us to show you the
          distance you are from the vehicles in the adverts, and the makes and
          models of vehicles you are currently searching. We also record related
          information about the postcode you supply, including the Acorn
          profiling code for that postcode, longitude and latitude of the
          postcode. Some of this information may also be used to bring you more
          relevant display adverts across our site.
        </p>
        <p>Internal tracking - Cookies: pages, PAGE_REF (session)</p>
        <p>
          There are certain product pages that we like to know that you have
          visited - such as the insurance, finance or car history pages. We
          record this in a cookie which allows us to provide you with adverts
          that are more relevant to you through our display advertising.
        </p>
        <p>
          In addition, we like to understand the response that we are getting to
          our used car advertisers on the site, and so we use a cookie to store
          some information about the search you have performed.
        </p>
        <p>
          <strong>Surveys</strong>
        </p>
        <p>
          In order to gather feedback from our customers about the site's
          products &amp; features you will be invited to provide your feedback
          on occasion by filling in a short survey. Providing feedback to us
          will enable the Ako Sayara team to act on this feedback and improve
          services and features.
        </p>
        <br />
        <br />
        <p style={{textAlign: 'center'}}>
          <strong style={{fontSize: '25px'}}>Managing Cookies</strong>
        </p>
        <p>
          <strong>How to manage cookies.</strong>
        </p>
        <p>
          Browsers are changing all the time, to get the most up to date
          information about managing your cookies in your browser visit{" "}
          <a href="http://www.aboutcookies.org/">
            http://www.aboutcookies.org/
          </a>{" "}
          (this will open in a new window - please note that we can't be
          responsible for the content of external websites)
        </p>
        <p>
          <strong>Appendix</strong>
        </p>
        <p>Essential cookies</p>
        <p>
          These cookies are essential for allowing you to move around the
          website/app and use our features properly. Without these cookies, we
          cannot provide you with the services you need, such as being able to
          search for and save your favourite cars.
        </p>
        <p>Cookie nameTypeData storedPurpose</p>
        <br /><br /><br /><br />
        <p>
          <strong>What is an Accessibility statement?</strong>
        </p>
        <p>
          This is the official accessibility statement for Ako Sayara. If you
          have any comments or suggestions, please email us via
          enquiries@akosayara.com
        </p>
        <p>
          The new website/app uses a technique called 'Progressive Enhancement',
          which means basic content and basic functionality should be accessible
          to all browsers. Visitors to our site who do not have JavaScript
          enabled will still be able to search for a vehicle.
        </p>
        <p>Standards compliance</p>
        <ul>
          <li>
            All pages on this site are WCAG A approved, complying with all
            priority 1 and most of priority 2 guidelines of the W3C Web Content
            Accessibility Guidelines. This is a judgement call; many guidelines
            are intentionally vague and cannot be tested automatically. Ako
            Sayara has reviewed the guidelines and believes all pages are in
            compliance (please see point 5).
          </li>
          <li>
            We aim for the pages on this site to validate as XHTML 1.0
            Transitional. A program of work is in progress to move to XHTML 1.0
            Strict for our top 10 pages within the year.
          </li>
          <li>Most pages on this site use Valid CSS.</li>
          <li>
            We are aiming for pages on this site use structured semantic
            mark-up. H1 tags are used for main titles, H2 tags for subtitles.
          </li>
          <li>
            Applications other than at the following address
            "www.autotrader.co.uk" may not be standards compliant but are
            constantly under review and in the process of being updated for
            accessibility.
          </li>
        </ul>
        <p>
          <strong>Access keys</strong>
        </p>
        <p>
          Ako Sayara does not currently support Access keys, although this will
          be implemented in the future when we will support full WCAG AAA
          guidelines.
        </p>
        <p>
          <strong>Tab key</strong>
        </p>
        <p>
          You can also use the Tab key on your keyboard to move between links on
          a page. A single press of Tab highlights the next link, Shift+Tab
          highlights the previous link.
        </p>
        <p>Links</p>
        <ul>
          <li>
            Many links have title attributes which describe the link in greater
            detail, unless the text of the link already fully describes the
            target (such as the headline of an article)
          </li>
          <li>Links are written to make sense out of context.</li>
        </ul>
        <p>Images</p>
        <ul>
          <li>
            All content images used in this site include descriptive ALT
            attributes. Purely decorative graphics currently include empty ALT
            attributes.
          </li>
          <li>
            Complex images include TITLE attributes or inline descriptions to
            explain the significance of each image to non-visual readers.
          </li>
        </ul>
        <p>Visual design</p>
        <ul>
          <li>
            This site uses cascading style sheets and progressive enhancement
            for visual layout.
          </li>
          <li>
            This site uses only relative font sizes, compatible with the
            user-specified "text size" option in visual browsers.
          </li>
        </ul>
      </p> */}

      <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data ? (data?.[lang]) : t("Please wait...")),
        }}
      ></p>

      {/* {data.map((item) => {
        return (
          <div className="container">
            <h4>{item.title[lang]}</h4>
            <p className="text-center">
              {item.description[lang]}
            </p>
          </div>
        )
      })} */}
    </div>
  );
}
