import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { fetchpopularModal } from "../../../Reducer/newBrandsSlice";
import { homeOptions } from "../../../data/content";
import { NavLink } from "react-router-dom";
import OwlItem from "../OwlItem";
import OwlItemCars from "../OwlItem/owlItemCars";
import { useState } from "react";

export default function PopularModal({ rtl, title, page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const popularmodal = useSelector((state) => state.new_brands.popularmodal);
  const loading = useSelector((state) => state.new_brands.loading);
  const error = useSelector((state) => state.new_brands.error);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page };

  useEffect(() => {
    dispatch(fetchpopularModal(data));
  }, [dispatch]);

  let responce =
    popularmodal.data && popularmodal.data.length > 0 ? popularmodal.data : [];

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchpopularModal(data));
    }
  };

  const [radioLoading, setRadioLoading] = useState(false);
  const [rentType, setRentType] = useState(page == "normal" ? page : "daily");

  const handleChnage = (e) => {
    setRadioLoading(true);
    setTimeout(() => {
      setRadioLoading(false);
    }, 500);
    setRentType(e.target.value);
  };

  return (
    <section class="recently_viewed md-5">
      {responce && responce.length > 0 && (
        <div class="container">
          <h2 class="hed">{t(title)}</h2>
          {/* {page == "rental" && (
            <h6>
              <span>
                <input
                  type="radio"
                  checked={rentType === "daily"}
                  onChange={handleChnage}
                  value="daily"
                />{" "}
                {t("Daily")}
              </span>{" "}
              <span>
                <input
                  type="radio"
                  value={"weekly"}
                  checked={rentType === "weekly"}
                  onChange={handleChnage}
                />{" "}
                {t("Weekly")}
              </span>{" "}
              <span>
                <input
                  type="radio"
                  onChange={handleChnage}
                  value={"monthly"}
                  checked={rentType === "monthly"}
                />{" "}
                {t("Monthly")}
              </span>
            </h6>
          )} */}
          {/* {!radioLoading ? ( */}
            <OwlCarousel
              className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${rtl && 'invertButtons'}`}
              {...homeOptions}
              margin={10}
              rtl={rtl}
              nav
            >
              {/* <OwlItem key={0} fill={'heart_fill.png'} unfill={'heart.webp'} responce={responce} page={'BrandNewSection'} handleClick={handleClick}/> */}
              <OwlItemCars cars={responce} rentType={rentType} />
            </OwlCarousel>
          {/* ) : (
            <div
              style={{ height: "58vh" }}
              className="container d-flex justify-content-center align-items-center"
            >
              <div>
                <a disabled>
                  <span
                    class="spinner-grow spinner-grow"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden" role="status">
                    Loading...
                  </span>
                </a>
              </div>
            </div>
          )} */}
          <div class="w-100 float-left text-center">
            <NavLink className={"viewall"} to={"/RentCar"}>
              {t("View all rental deals")}
            </NavLink>
          </div>
        </div>
      )}
    </section>
  );
}
