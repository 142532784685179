import React, { useCallback, useEffect } from "react";
import ListItem from "../ListItem";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { isImageOrVideo } from "../../../utils/constants";

import ReactImageGallery from "react-image-gallery";

export default function Items({ imageArray, filterImageArray }) {
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const active = i18n.language === 'English' ? 0 : imageArray.length - 1
  const handleCloseModal = () => {
    setModal(false);
  };

  const openModal = (item, type) => {
    if (type === "video") return;
    const i = images.map((v) => v.original).indexOf(item);
    setImage(i);
    setModal(true);
  };

  useEffect(() => {
    if (imageArray) {
      let arr = imageArray.map((v) => {
        return { original: v, thumbnail: v, type: isImageOrVideo(v) };
      });
      setImages(arr);
    }
  }, [imageArray]);

  return (
    <>
      {imageArray && imageArray.length > 0
        ? imageArray.map((item, index) => {
            const type = isImageOrVideo(item);
            switch (type) {
              case "video":
                return (
                  <div
                    onClick={() => openModal(item, type)}
                    class={`h-100 carousel-item${index == active ? " active" : ""} `}
                  >
                    <video
                      controls
                      // muted
                      className="rounded-2"
                      height={400}
                      style={{
                        objectFit: "contain",
                        marginBottom: "-7px",
                        width: "100%",
                      }}
                    >
                      <source src={item} />
                    </video>
                  </div>
                );
              case "image":
                return (
                  <div
                    onClick={() => openModal(item, type)}
                    class={`h-100 carousel-item${index == active ? " active" : ""} `}
                  >
                    <img
                      class={`w-100`}
                      height={400}
                      style={{ objectFit: "cover" }}
                      src={item ? item : "../assets/images/notFound.png"}
                      // onError={() => filterImageArray(item)}
                      // onClick={() => openImageViewer(index)}
                      alt="bg"
                    />
                    {/* <ul class="butm">
                  {isImageOrVideo(item) !== "video" && (
                    <ListItem
                      innerhtmlType={"html"}
                      value={
                        <a
                          onClick={() => window.print()}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="../assets/images/Print.png"
                            class="Print"
                            style={{ width: "unset" }}
                          />{" "}
                          {t("Print")}
                        </a>
                      }
                    />
                  )}
                  <ListItem
                    innerhtmlType={"html"}
                    value={
                      <a href={item} download style={{ cursor: "pointer" }}>
                        <img
                          src="../assets/images/Download.png"
                          class="Print"
                          style={{ width: "unset" }}
                        />{" "}
                        {t("Download")}
                      </a>
                    }
                  />
                </ul> */}
                  </div>
                );
            }
          })
        : ""}
      <Modal
        size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        <ModalHeader style={{alignSelf: 'end'}} toggle={handleCloseModal} className="d-flex justify-content-end border-0"></ModalHeader>
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", padding:'0xp' }}
        >
          {/* <div
          className="bg-light p-2 rounded-2"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 999,
            }}
          >
            <img
              src="/assets/images/Close.png"
              height={25}
              onClick={() => handleCloseModal()}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div> */}
          <ReactImageGallery
            startIndex={image}
            items={images || []}
            renderItem={(e) => {
              const item = e?.original;
              switch (e.type) {
                case "image":
                  return (
                    <img
                      style={{
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                        height: "100vh",
                      }}
                      src={item ? item : "../assets/images/notFound.png"}
                      onError={() => filterImageArray(item)}
                      alt="bg"
                    />
                  );
                case "video":
                  return (
                    <video
                      controls
                      className="rounded-2"
                      // height={400}
                      style={{
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                        height: "100vh",
                      }}
                    >
                      <source src={item} />
                    </video>
                  );
              }
            }}
            showThumbnails={false}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
            isRTL={i18n.language === "English" ? false : true}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
