import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { apiManager } from "../../../../../api/connectAPI";
import { cities } from "../../../../../data/content";
import VerifyWithOtp from "../../../VerifyWithOtp";
import {
  sendResetPasswordOTP,
  sendVerificationOTP,
  sendWhatsappOtp,
} from "../../../../../Reducer/api";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";
import { Icon } from "@iconify/react";
import moment from "moment";

export default function Profile({ data, page }) {
  const info = useSelector((state) => state.auth.auth);
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const navigate = useNavigate();

  var length = Object.keys(data).length;

  const handleClick = () => {
    navigate("/MyAccount/EditProfile", {
      state: { data: data, id: info.id, type: page },
    });
  };

  //Modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const openModal = async (type, identifier) => {
    setVerifyErrors({});
    const tmp = type === "Email" ? "email" : "phoneNumber";
    let response = null;
    if (type === "Email") {
      response = await sendVerificationOTP({ [tmp]: identifier });
    } else {
      response = await sendWhatsappOtp({ phoneNumber: identifier });
    }
    if (response.status) {
      setModalData({ type: type, identifier: identifier });
      setModal(true);
    } else {
      if (tmp === "email") {
        setVerifyErrors({ email: response.message });
      } else {
        setVerifyErrors({ phone: response.message });
      }
    }
    // sendVerificationOTP({ [tmp]: identifier }).then((res) => {
    //   if (res.status) {
    //     setModalData({ type: type, identifier: identifier });
    //     setModal(true);
    //   } else {
    //     console.log(res.message);
    //     if (tmp === "email") {
    //       setVerifyErrors({ email: res.message });
    //     } else {
    //       setVerifyErrors({ phone: res.message });
    //     }
    //   }
    // });
  };

  const handleClose = () => {
    dispatch(myprofilefetch());
    setModal(false);
  };

  const [verifyErrors, setVerifyErrors] = useState({});
  console.log(verifyErrors);

  return (
    <>
      {length > 0 ? (
        <>
          <h3 class="pt-0">
            {t("Basic Details")}
            <a className="edt" onClick={handleClick}>
              <img src="../assets/images/pencil.png" alt="pencil" />
              {t("Edit profile")}
            </a>
          </h3>
          <div class="row">
            <div class="col-sm-3 col-xl-2">
              <div class="imgs">
                <img
                  class={`w-100 bg-light p-${
                    data?.profilePicture && data?.profilePicture != "N/A"
                      ? ""
                      : "4"
                  }`}
                  src={
                    data?.profilePicture && data?.profilePicture != "N/A"
                      ? data.profilePicture
                      : "/assets/images/ap2.png"
                  }
                  style={{ objectFit: "contain" }}
                  align="img"
                />
              </div>
            </div>
            <div class="col-sm-9 col-xl-10">
              <div class="row doloredit">
                <div class="col-md-4 ">
                  <h6>{t("Name")}</h6>
                  <p>{data.fullName ? data.fullName : "--"}</p>
                </div>
                <div class="col-md-4 ">
                  <h6>{t("Gender")}</h6>
                  <p>{data.gender[lang] ? data.gender[lang] : "--"}</p>
                </div>

                {/* for dealer */}
                {page === "dealer" ? (
                  <div class="col-md-4">
                    <h6>{t("Dealership Name")}</h6>
                    <p>{data?.dealerShipName ? data?.dealerShipName : "--"}</p>
                  </div>
                ) : (
                  ""
                )}

                {/* for dealer */}
                {data?.email && data.email !== "N/A" && (
                  <div class="col-md-4">
                    <h6>
                      {t("Email")}{" "}
                      {!data?.emailVerified &&
                      data?.email &&
                      data.email !== "N/A" ? (
                        <span
                          onClick={() => openModal("Email", data.email)}
                          style={{
                            fontSize: "13px",
                            cursor: "pointer",
                            color: "#4C7EE8",
                            float: lang === "en" ? "right" : "left",
                          }}
                        >
                          {t("Verify now")}
                        </span>
                      ) : (
                        data?.emailVerified && (
                          <Icon
                            icon="ic:baseline-verified"
                            style={{
                              color: "#4C7EE8",
                              fontSize: "16px",
                              marginTop: "0px",
                              marginInline: "10px",
                              marginBottom: "3px",
                            }}
                          />
                        )
                      )}
                    </h6>
                    <p>
                      {data?.email?.length != 0 && data?.email != "N/A"
                        ? data?.email
                        : "--"}{" "}
                      <br />
                      <span
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {verifyErrors?.email}
                      </span>
                    </p>
                  </div>
                )}

                {data?.phoneNumber && data.phoneNumber !== "N/A" && (
                  <div class="col-md-4">
                    <h6>
                      {t("Phone")}{" "}
                      {!data?.phoneNumberVerified &&
                      data?.phoneNumber &&
                      data.phoneNumber !== "N/A" ? (
                        <span
                          onClick={() =>
                            openModal("Phone Number", data.phoneNumber)
                          }
                          style={{
                            fontSize: "13px",
                            cursor: "pointer",
                            color: "#4C7EE8",
                            float: lang === "en" ? "right" : "left",
                            fontWeight: "500",
                          }}
                        >
                          {t("Verify now")}
                        </span>
                      ) : (
                        data?.phoneNumberVerified && (
                          <Icon
                            icon="ic:baseline-verified"
                            style={{
                              color: "#4C7EE8",
                              fontSize: "16px",
                              marginTop: "0px",
                              marginInline: "10px",
                              marginBottom: "3px",
                            }}
                          />
                        )
                      )}{" "}
                    </h6>
                    <p>
                      {`${lang === "en" ? "+" : ""}${data.phoneNumber}${
                        lang !== "en" ? "+" : ""
                      }`}{" "}
                      <br />
                      <span
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {verifyErrors?.phone}
                      </span>
                    </p>
                  </div>
                )}

                {/* for dealer */}
                {data?.additionalInfo?.website &&
                  data?.additionalInfo?.website !== "N/A" && (
                    <>
                      {page === "dealer" ? (
                        <div class="col-md-4">
                          <h6>{t("Website")}</h6>
                          <p>
                            {data?.additionalInfo?.website
                              ? data?.additionalInfo?.website
                              : "--"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                {/* end */}
                {data?.city && (
                  <div class="col-md-4">
                    <h6>{t("City")}</h6>
                    <p>{t(data?.city) || "--"}</p>
                  </div>
                )}
                {page === "dealer" ? (
                  <div class="col-md-4">
                    <h6>{t("Address")}</h6>
                    <p>{data.address}</p>
                  </div>
                ) : null}
                {data?.dateOfBirth && (
                  <div class="col-md-4">
                    <h6>{t("Date of birth")}</h6>
                    <p>
                      {moment.unix(data?.dateOfBirth).format("DD/MM/YYYY") ||
                        "--"}
                    </p>
                  </div>
                )}

                {/* for dealer */}
                {data?.additionalInfo?.about?.[lang] &&
                  data?.additionalInfo?.about?.[lang] !== "N/A" && (
                    <>
                      {page === "dealer" ? (
                        <div class="col-md-12">
                          <h6>{t("About")}</h6>
                          <p>
                            {data?.additionalInfo?.about?.[lang]
                              ? data?.additionalInfo?.about?.[lang]
                              : "--"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                {/* end */}
              </div>
            </div>
          </div>
          <VerifyWithOtp
            modal={modal}
            openModal={openModal}
            handleClose={handleClose}
            data={modalData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}
