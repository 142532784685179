import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Login,ElectricCars, ClassicCar, RentalCar, Header,Dealer_search, Footer,Enquiry, Signup, BasicDetail, SellYourCar,RentYourCar, Saved, Compare, CheapCars,NewCars, SingleCar, CheckCar, Search, RentalSearch, DealerRental, SearchResultpage} from "./Components";
import AuthRoute from './AuthRoute';
import { MyVehicle, MyProfile,Sidebar,Advertising_TCs, PrivacyPolicies, Transactions, Messages, Performance, EditWorkingHour} from './Components/Pages/MyAccount';
import ArticleBlog from './Components/Pages/ArticleBlog/inbox';
import SearchResult from './Components/Pages/SearchResult';
import EditVehicle from './Components/Pages/MyAccount/Pages/MyVehicle/EditVehicle';
import EditProfile from './Components/Pages/MyAccount/Pages/MyProfile/EditProfile';
import Security from './Components/Pages/MyAccount/Pages/MyProfile/Security';
import EditSecurity from './Components/Pages/MyAccount/Pages/MyProfile/EditSecurity';
import UploadFormdocument from './Components/Pages/SigupMedia/UploadMedia';
import ForgotPassword from './Components/Pages/ForgotPassword';
import EnterOTP from './Components/Pages/ForgotPassword/enterOTP';
import Policy from './Components/Pages/Policies/Policy';
import TermsAndCondition from './Components/Pages/TermsAndConditions';
import Cookies from './Components/Pages/Cookies'
import AboutPage from './Components/Pages/AboutPage';
import CloseAccount from './Components/MyComponents/ContactUs/CloseAccount';
import Contactus from './Components/MyComponents/ContactUs';
import ContactUS from './Components/Pages/ContactUs';
import WhyAko from './Components/MyComponents/WhyAko';
import Careers from './Components/Pages/Careers';
import './App.css'
import AdvancedSearch from './Components/Pages/AdvancedSearch';
import AllArticles from './Components/Pages/AllArticles';
import Guide from './Components/Pages/Guide/inbox';
import SingleCarCheck from './Components/Pages/CheckCar/SingleCarCheck';
import Plan from './Components/Tools/plan';
import GeneralPayment from './Components/Tools/GeneralPayment';
import Payments from './Components/Tools/Payments';
import ThiredStep from './Components/Tools/SRPages/ThirdStep';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Header/>
          <Routes>
            <Route exact path="/" element={<Home page={'normal'}/>}/>
            <Route exact path="/Login" element={<Login/>}/>
            <Route exact path="/ForgotPassword" element={<ForgotPassword />}/>
            <Route exact path="/ForgotPassword/EnterOTP" element={<EnterOTP />}/>
            <Route exact path="/Signup" element={<Signup/>}/>
            <Route exact path="/Details" element={<AuthRoute element={<BasicDetail />} />}/>
            <Route exact path="/Saved" element={<AuthRoute element={<Saved />} />}/>
            <Route exact path="/Comparing" element={<Compare/>}/>
            <Route exact path="/SellYourCar" element={<SellYourCar/>}/>
            <Route exact path="/RentYourCar" element={<RentYourCar/>}/>
            <Route exact path="/ViewImage" element={<RentYourCar/>}/>
            <Route exact path="/plan" element={<Plan/>}/>
            <Route exact path="/payment" element={<GeneralPayment/>}/>

            <Route exact path="/ViewMap" element={<RentYourCar/>}/>
            <Route exact path="/cheap_cars" element={<CheapCars/>}/>
            <Route exact path="/new_car_deals" element={<NewCars/>}/>
            <Route exact path="/classic_cars" element={<ClassicCar/>}/>
            <Route exact path="/ElectricCars" element={<ElectricCars/>}/>
            <Route exact path="/rent_perfect_cars"  element={<RentalCar page={'rental'}/>}/>

            <Route exact path="/singleBlog/:id"  element={<ArticleBlog/>}/>
            <Route exact path="/singleGuide/:id"  element={<Guide />}/>
            <Route exact path="/articles"  element={<AllArticles/>}/>
            <Route exact path="/SingleCar" element={<SingleCar page={'normal'}/>}/>
            <Route exact path="/SingleRental"  element={<SingleCar page={'rental'}/>}/>
            <Route exact path="/dealership_document"  element={<UploadFormdocument/>}/>
            <Route exact path="/Search"  element={<Search/>}/>
            <Route exact path="/RentalSearch" element={<RentalSearch/>}/>
            <Route exact path="/AdvancedSearch" element={<AdvancedSearch/>}/>

            <Route exact path="/check_your_cars"  element={<CheckCar/>}/>
            <Route exact path="/SingleCarCheck"  element={<SingleCarCheck/>}/>

            <Route exact path="/SearchResult" element={<SearchResult page={''} back={'/'}/>} />
            {/* <Route exact path="/SearchResult/brand/" element={<SearchResult page={''} back={"/"}/>} /> */}
            <Route exact path="/BuyCar" element={<SearchResult page={'normal'} back={"/"} />} />
            <Route exact path="/RentalSearchResult" element={<SearchResult page={'rental'}/>} />
            <Route exact path="/RentCar" element={<SearchResult page={'rental'} back={"/"} />} />
            <Route exact path="/DealerRental" element={<DealerRental page={'rental'}/>} />
            <Route exact path="/DealerCarView" element={<DealerRental page={'normal'}/>} />
            <Route exact path="/search_result_page"  element={<SearchResultpage/>}/>
            <Route exact path="/dealer_search_results"  element={<Dealer_search/>}/>
            <Route exact path="/Enquiry"  element={<Enquiry/>}/>

            <Route exact path="/Policies"  element={<Policy />}/>
            <Route exact path="/conditions"  element={<TermsAndCondition />}/>
            <Route exact path="/cookies"  element={<Cookies />}/>
            <Route exact path="/aboutSayara"  element={<AboutPage />}/>
            <Route exact path="/contact"  element={<ContactUS />}/>
            <Route exact path="/why_ako_sayara"  element={<WhyAko />}/>
            <Route exact path="/careers"  element={<Careers />}/>

            <Route exact path="/MyAccount" element={<AuthRoute element={<Sidebar component={<MyVehicle/>}/>} />}/>
            <Route index path="/MyAccount/Vehicles" element={<AuthRoute element={<Sidebar component={<MyVehicle/>}/>} />}/>
            <Route index path="/MyAccount/Terms-Conditions" element={<AuthRoute element={<Sidebar component={<Advertising_TCs/>}/>} />} />
            <Route index path="/MyAccount/WhyAkoSayara" element={<AuthRoute element={<Sidebar component={<WhyAko/>}/>} />} />
            <Route index path="/MyAccount/PrivacyPolicies" element={<AuthRoute element={<Sidebar component={<PrivacyPolicies/>}/>} />} />
            <Route index path="/MyAccount/ContactUs" element={<AuthRoute element={<Sidebar component={<Contactus/>}/>} />} />
            <Route index path="/Closeaccount" element={<CloseAccount/>} />

            <Route index path="/MyAccount/Closeaccount" element={<AuthRoute element={<Sidebar component={<CloseAccount/>}/>} />} />
            
            <Route index path="/MyAccount/Edit_car" element={<AuthRoute element={<Sidebar component={<EditVehicle/>}/>} />} />
            <Route index path="/MyAccount/EditProfile"  element={<AuthRoute element={<Sidebar component={<EditProfile/>}/>} />} />
            <Route index path="/MyAccount/Security"  element={<AuthRoute element={<Sidebar component={<Security/>}/>} />} />
            <Route index path="/MyAccount/EditSecurity"  element={<AuthRoute element={<Sidebar component={<EditSecurity/>}/>} />} />
            <Route index path="/MyAccount/Transactions"  element={<AuthRoute element={<Sidebar component={<Transactions/>}/>} />} />
            <Route index path="/MyAccount/Messages"  element={<AuthRoute element={<Sidebar component={<Messages/>}/>} />} />
            <Route index path="/MyAccount/Perfomance"  element={<AuthRoute element={<Sidebar component={<Performance/>}/>} />} />
            <Route index path="/MyAccount/MyProfile" element={<AuthRoute element={<Sidebar component={<MyProfile page={'personal'}/>}/>} />} />
            <Route index path="/MyAccount/MyProfile1" element={<AuthRoute element={<Sidebar component={<MyProfile page={'dealer'}/>}/>} />} />
            <Route index path="/MyAccount/EditWorkHour" element={<AuthRoute element={<Sidebar component={<EditWorkingHour/>}/>} />} />  
            <Route index path="/MyAccount/Cookies" element={<AuthRoute element={<Sidebar component={<Cookies/>}/>} />} />  

          </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
