import React, { useEffect, useState } from "react";
import Options from "./options";
import { t } from "i18next";
const LangSelectOptions = ({ style, selectedOption, values, onChange }) => {
  const [showValue, setValue] = useState();
  useEffect(() => {
    if(selectedOption === 'English') {
      setValue(t('Arabic', {lng: 'Arabic'}))
    } else if(selectedOption === 'Arabic') {
      setValue(t('Kurdish', {lng: 'Kurdish'}))
    } else if(selectedOption === 'Kurdish') {
      setValue(t('English', {lng: 'English'}))
    }
  }, [selectedOption])

  return (
    <label htmlFor="languageSelectionTool" style={{ position: "relative", width: '70px' }}>
      <span
        className="w-100"
        style={{ position: "absolute", left: "0px", paddingInline: '5px', color: "#4C698F", pointerEvents: 'none', backgroundColor: 'white' }}
      >
        {showValue}
      </span>
      {/* <img src="../assets/images/gloab_alt.png" alt="globe-alt" /> */}
      <select
        // hidden
        id="languageSelectionTool"
        defaultValue={selectedOption}
        className={style}
        onChange={onChange}
      >
        {values.map((item, key) => {
          return (
            <Options value={item.value} name={item.name} style={""} key={key} />
          );
        })}
      </select>
    </label>
  );
};
export default LangSelectOptions;
