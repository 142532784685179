import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPerfomance } from './api';

export const PerfomanceFetch = createAsyncThunk('detail/fetchPerfomancedata', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetchPerfomance(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const performance = createSlice({
  name: 'performance',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    performanceArr:[],
  },
  reducers: {},
  extraReducers: builder => {
    builder
    // perfomance data 
      .addCase(PerfomanceFetch.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(PerfomanceFetch.fulfilled, (state,action) => {
        state.submitting = false;
        state.performanceArr = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(PerfomanceFetch.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      }) 
  },
});
export default performance.reducer;
