import React, { useEffect } from "react";
import Items from "./items";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { isImageOrVideo } from "../../../utils/constants";
import ScrollMenuComponent from "./ScrollMenuComponent";
import DragScroll from "./DragScroll";
import i18n from "../../../i18n";
export default function Crousel({ data, setShowCarousel }) {
  // if (data.length === 0) data.push("/assets/images/notFound.png");

  const [imageArray, setImageArray] = useState(i18n.language === 'English' ? data : data.reverse());
  const filterImageArray = (item) => {
    let newArr = [...imageArray];
    newArr = newArr.filter((v) => v !== item);
    setImageArray(newArr);
  };
  useEffect(() => {
    if (imageArray.length == 0) {
      setShowCarousel(false);
    }
  }, [imageArray]);

  return (
    <div
      id="carouselSingle"
      // style={{direction: 'ltr'}}
      class="carousel slide"
      // data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <Items
          imageArray={imageArray}
          filterImageArray={filterImageArray}
        />
      </div>
      {/* <DragScroll data={data} /> */}
      <ScrollMenuComponent
        imageArray={imageArray}
        setImageArray={setImageArray}
        filterImageArray={filterImageArray}
      />
    </div>
  );
}
