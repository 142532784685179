import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RentalCarFrom from "./RentalForm";
import { fromDetail } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import { getAdFiltersList, getAllModels, getMake, getModels } from "../../../Reducer/api";
import { bannerRent } from "../../../utils/paths";

export default function Banner({page, carTypes}) {
  const { t, i18n } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.fromsdata);
  // const loading  = useSelector(state => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fromDetail());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    bodyType: [],
    price: {
      min: "",
      max: ""
    }
  };
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState(defaultValues);
  useEffect(() => {
    getMake(true, 200, "", page).then((res) => {
      // res = res?.map((v) => ({ label: v, value: v }));
      let data = {
        name: t("Any"),
        value: "Any",
      };
      setMakes([data, ...res?.makes]);
    });
  }, []);
  useEffect(() => {
    setModels([])
    if (filterData.make) {
      getModels(filterData.make, true, "", page).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    } 
    // else {
    //   getAllModels().then((res) => {
    //     res = res?.map((v) => ({ label: v, value: v }));
    //     setModels(res);
    //   });
    // }
  }, [filterData.make]);

  useEffect(() => console.log(filterData), [filterData]);

  const resetFilters = () => {
    setFilterData(defaultValues);
    setModels([])
  };

  const [cities, setCities] = useState([]);
  useEffect(() => {
    getAdFiltersList(page)
      .then((res) => {
        setCities(res?.cities);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section
      class="banner rentalsbanner"
      style={{
        backgroundImage: `url(${bannerRent ? bannerRent : "/assets/images/Bmw-Wallpaper-04-1920-x-1200.jpg"})`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="setform" style={{overflow: 'unset'}}>
              <h4>{t("Find your rental")}</h4>
              <div class="tab-content" id="myTabContent">
                <RentalCarFrom
                  data={detail}
                  carTypes={carTypes}
                  makes={makes}
                  setMakes={setMakes}
                  models={models}
                  setModels={setModels}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  cities={cities}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2>{t("Find your perfect Rental")}</h2>
          </div>
        </div>
      </div>
    </section>
  );
}
