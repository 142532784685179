import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveContactUsForm } from "../../../Reducer/ConteactUsSilce";
import { fetchdescribe } from "../../../Reducer/ConteactUsSilce";
import { toast } from "react-toastify";
import { NavLink, useLocation } from "react-router-dom";
import { errorSms } from "../../../data/content";
import FormInput from "../../Tools/FormInput";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
export default function Inquireform({ formData, setFormData }) {
  const dispatch = useDispatch();
  const dataContent = useSelector((state) => state.contactus.detailarr);
  const submitting = useSelector((state) => state.contactus.submitting);
  const detail = useSelector((state) => state.contactus.auth);
  const error = useSelector((state) => state.contactus.error);
  const success = useSelector((state) => state.contactus.success);
  const { t, i18n } = useTranslation();
  const [successMessage, setSuccessMessage] = useState("");
  useEffect(() => {
    dispatch(fetchdescribe());
  }, [dispatch]);
  const location = useLocation();
  console.log(location.pathname);
  const schema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, t("Full name must be at least 3 characters"))
      .max(30, t("Full name must be at most 30 characters"))
      .required(t("Your name is a required field")),
    number: Yup.string(),
    about: Yup.string().required(t("About is required")),
    subject: Yup.string().email('Please Enter Valid email address'),
    message: Yup.string().required(t("Message is required")),
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      phoneNumber: ''
    }
  });

  const onError = (errors) => {
    // if (!phoneNumber) setPhoneNumberErrors(t("Phone number is required"));
    console.log(errors);
  };
  console.log(formData);
  const handleReset = () => {
    reset();
    // setPhoneNumber("");
  };
  const onSubmitform = async (data) => {
    setSuccessMessage("");
    console.log(data);
    if (submitting == false) {
      if (!formData?.roleObj) {
        setFormData({ ...formData, roleError: t("This field is requried") });
        return;
      }

      // if (phoneNumberErrors) return;

      const apiData = {
        name: data.fullname,
        phoneNumber: data.phoneNumber,
        email: data.subject,
        about: data.about,
        message: data.message,
        role: formData?.roleObj?.title?.English,
      };

      dispatch(
        saveContactUsForm({
          formData: apiData,
          setLoading: setLoading,
          reset: handleReset,
          setSuccessMessage: setSuccessMessage,
        })
      );
      toast.success("Form Submit successfully");
    }
  };

  console.log(success);

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");

  return (
    <>
      <div class="set_best">
        <h3>{t("Send Inquire or Report an Issue")}</h3>
        <form onSubmit={handleSubmit(onSubmitform, onError)}>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{t("Your Name")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"fullname"}
                  placeholder={t("Your Name")}
                />
                {errors.fullname && (
                  <p className="error">{t(errors.fullname.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>{t("Phone Number")}</label>
                {/* <PhoneNumberInput
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  phoneNumberErrors={phoneNumberErrors}
                  setPhoneNumberErrors={setPhoneNumberErrors}
                /> */}
                <CustomPhoneInput
                  event={register}
                  name={"phoneNumber"}
                  setValue={setValue}
                  getValues={getValues}
                />
                {errors.phoneNumber ? (
                  <p className="error">{t(errors.phoneNumber.message)}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Email (Optional)")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"subject"}
                  placeholder={t("Email")}
                />
                {errors.subject && (
                  <p className="error">{t(errors.subject.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Enquiring About Something")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"about"}
                  placeholder={t("Enquiring About Something")}
                />
                {errors.about && (
                  <p className="error">{t(errors.about.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>{t("Your Message")}</label>
                <FormInput
                  style={"form-control"}
                  intype={"text"}
                  event={register}
                  name={"message"}
                  placeholder={t("Type your message")}
                />
                {errors.message && (
                  <p className="error">{t(errors.message.message)}</p>
                )}
              </div>
            </div>
            <div class="col-sm-12">
              <button disabled={loading} type="submit">
                {loading ? t("Please wait...") : t("Enquire Now")}
              </button>
            </div>
            {successMessage && (
              <div className="col-lg-12">
                <ul
                  class="livetst w-100 mt-4 p-2 rounded"
                  style={{
                    top: "unset",
                    marginBottom: "15px",
                    border: "1px solid #ebebeb",
                    backgroundColor: "#28c5281f",
                  }}
                >
                  <span className={`mx-2 d-block fs-5 text-${i18n.language === 'English' ? 'start' : 'end'}`}>{successMessage}</span>
                </ul>
              </div>
            )}
          </div>
        </form>
      </div>
      <h4>{t("Help buying a car")}</h4>
      <p>{t("help_buy_content")}</p>
      <NavLink
        className="atclose"
        to={
          location.pathname.includes("MyAccount")
            ? "/MyAccount/Closeaccount"
            : "/Closeaccount"
        }
      >
        <img src="../assets/images/close_img.png" />
        {t("Close my Account")}
      </NavLink>
    </>
  );
}
