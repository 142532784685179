import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalButton from "../../Tools/ModalButton";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { headerData } from "../../../data/content";
import {
  changeLanguage,
  clearToken,
  clearUser,
} from "../../../Reducer/authSlice";
import LangSelectOptions from "../../Tools/LangSelectOptions";
import $ from "jquery";
import { Icon } from "@iconify/react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { choiceSelection } from "../../../Reducer/choiceSelection";
import { changePreferredLanguage } from "../../../Reducer/api";

export default function Header() {
  const dispatch = useDispatch();
  const cl = useSelector((state) => state.auth.language);
  const navigate = useNavigate();
  const location = useLocation();
  let mainClass = "main-header";
  switch (location.pathname) {
    case "/Signup":
      mainClass = "main-header headerlogin";
      break;
    case "/Login":
      mainClass = "main-header headerlogin";
      break;
    case "/Details":
      mainClass = "main-header headerlogin";
      break;
  }

  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(i18n.language);
  const token = useSelector((state) => state.auth.token);
  const datalist = headerData;
  document.body.classList.remove(...document.body.classList);
  document.body.classList.add(i18n.language);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [location.pathname]);

  const handleOptionChange = (e) => {
    window.location.reload();
    setSelectedOption(e.target.value);
    $("body").removeClass("side-inherit");
    $(".mob-lang").addClass("d-none");
    $(".navbar-collapse").removeClass("show");

    dispatch(changeLanguage(e.target.value));
    i18n.changeLanguage(e.target.value);
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add(e.target.value);
  };

  const handleListChange = (value) => {
    if (token) {
      changePreferredLanguage({ value: value }).then((res) => {
        if (res.status) {
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
    setSelectedOption(value);
    dispatch(changeLanguage(value));
    i18n.changeLanguage(value);
    $("body").removeClass("side-inherit");
    $(".mob-lang").addClass("d-none");
    $(".navbar-collapse").removeClass("show");
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add(value);
  };

  const handlelogout = () => {
    dispatch(clearUser());
    dispatch(clearToken());
    navigate("/");
    remove();
    window.location.reload();
    console.log("t-token", token);
  };

  const remove = () => {
    var element = document.getElementById("navbarCollapse");
    element.classList.remove("show");
  };
  const optionList = [
    { name: "English", value: "English" },
    { name: "عربي", value: "Arabic" },
    { name: "كوردى", value: "Kurdish" },
  ];

  const [modal, setModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [check, setCheck] = useState("");

  useEffect(() => {
    if (localStorage.getItem("boloBallu")) {
      // setModal(false);
    } else {
      // setModal(true);
    }
  }, []);

  return (
    <header class={mainClass}>
      <div>
        {modal ? (
          <Modal isOpen={true} fullscreen centered style={{ zIndex: 9999 }}>
            {/* <ModalHeader className="w-100">Please enter passcode to continue</ModalHeader> */}
            <ModalBody>
              <div className="d-flex justify-content-evenly align-items-center h-100">
                <div className="d-flex justify-content-evenly align-items-center flex-column">
                  <Icon icon="tabler:password-user" fontSize={100} />
                  <h5
                    className="my-1"
                    style={{
                      fontWeight: "800",
                      fontSize: "35px",
                      textAlign: "center",
                    }}
                  >
                    Please enter passcode to continue
                  </h5>
                  <input
                    placeholder="xxxxxxxxxx"
                    type="password"
                    style={{
                      outline: "none",
                      borderRadius: "25px",
                      border: "1px solid grey",
                      height: "45px",
                      paddingInline: "10px",
                      fontSize: "20px",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                    onChange={(e) => setInputValue(e.target.value)}
                  ></input>
                  <div className="mt-3">
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#334660",
                        border: "none",
                        outline: "none",
                        borderRadius: "16px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        if (inputValue === choiceSelection) {
                          localStorage.setItem("boloBallu", "true");
                          setModal(false);
                        }
                      }}
                    >
                      Continue
                    </Button>{" "}
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
      <div class="container">
        <nav class="navbar navbar-expand-md">
          <NavLink className="logo" to="/">
            <img src={`../assets/images/${t("MainIcon")}`} />
          </NavLink>
          <ModalButton
            type={"button"}
            styleClass={"navbar-toggler"}
            toggle={"collapse"}
            target={"#navbarCollapse"}
            controls={"navbarCollapse"}
            expanded={"expanded"}
            label={"Toggle navigation"}
            title={
              <>
                <i
                  onClick={() => {
                    $("body").addClass("side-inherit");
                  }}
                  class="fa-solid fa-bars"
                ></i>
                <span class="d-none">{t("Menu")}</span>
              </>
            }
          />
          <div class="collapse navbar-collapse " id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <ListItem
                style={"nav-item d-block d-lg-none"}
                innerhtmlType={"html"}
                value={
                  <>
                    <NavLink className="logo d-inline-block mt-1" to="/">
                      <img src={`../assets/images/${t("MainIcon")}`} />
                    </NavLink>
                    <span class="navbar-toggler" onClick={remove}>
                      {" "}
                      <img src="../assets/images/Close.png" />
                    </span>
                  </>
                }
              ></ListItem>
              {datalist.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    listClick={() => remove()}
                    value={t(item.name)}
                    style={`nav-item ${item.segment}`}
                    segment={item.segment}
                    innerhtmlType={"link"}
                  />
                );
              })}
              <ListItem
                key={10}
                btnType={"button"}
                style={"lang-btn"}
                buttonTitle={t("Select Language", {
                  lng:
                    i18n.language === "English"
                      ? "Arabic"
                      : i18n.language === "Arabic"
                      ? "Kurdish"
                      : "English",
                })}
                onClick={() => {
                  $(".mob-lang").removeClass("d-none");
                }}
                innerhtmlType={"button"}
              />
              <div className="mob-lang d-none">
                <h5>
                  <img
                    src="../assets/images/Close.png"
                    onClick={() => {
                      $(".mob-lang").addClass("d-none");
                    }}
                  />
                  {t("Select Language")}
                </h5>
                {/* Mobile view */}
                <ul>
                  {optionList.map((item, key) => {
                    return (
                      <ListItem
                        key={key}
                        value={item.name}
                        listClick={() => {
                          handleListChange(item.value);
                        }}
                        style={`${
                          item.value == selectedOption
                            ? item.value + " active"
                            : item.value
                        }`}
                        innerhtmlType={"html"}
                      />
                    );
                  })}
                </ul>
              </div>
              {token && (
                <ListItem
                  key={6}
                  style="nav-item d-block d-lg-none"
                  innerhtmlType={"html"}
                  value={
                    <NavLink
                      className="nav-link"
                      to={"/MyAccount"}
                      onClick={() => remove()}
                    >
                      {t("My Account")}
                    </NavLink>
                  }
                />
              )}
              {token && (
                <ListItem
                  key={6}
                  style="nav-item d-block d-lg-none"
                  innerhtmlType={"html"}
                  value={
                    <NavLink
                      className="nav-link red"
                      to={"/"}
                      onClick={() => handlelogout()}
                    >
                      {" "}
                      {t("Logout")}
                    </NavLink>
                  }
                />
              )}
              {token == "" && (
                <ListItem
                  key={6}
                  style="nav-item d-block d-lg-none"
                  value={
                    !token && (
                      <NavLink
                        className="nav-link red"
                        to={"/Login"}
                        onClick={() => remove()}
                      >
                        {t("Login")}
                      </NavLink>
                    )
                  }
                  innerhtmlType={"html"}
                />
              )}
            </ul>
          </div>
          <div class="log">
            <NavLink className="Saved" to={"/Saved"}>
              <img
                src={`../assets/images/${
                  location.pathname === "/Saved"
                    ? "red_fill.png"
                    : "red_unfill.png"
                }`}
                style={{margin: "0px 6px 3px 6px" }}
                className="heartIcon"
                alt="heart"
              />
              {t("Saved")}
            </NavLink>
            {token && token != "" ? (
              <NavLink className="login" to={"/MyAccount"}>
                {/* <img src="../assets/images/user.png" alt="user" /> */}
                <Icon
                  icon={`${
                    location.pathname.includes("/MyAccount") ? "fa6-solid:user" : "fa6-regular:user"
                  }`}
                  fontSize={17}
                  style={{ color: location.pathname.includes("/MyAccount") ? "#4C7EE8" : "#414040", margin: "0px 6px 3px 6px" }}
                />
                {t("My Account")}
              </NavLink>
            ) : (
              <NavLink className="login" to={"/Login"}>
                <img src="../assets/images/user.png" alt="user" />
                {t("Login")}
              </NavLink>
            )}
            {/* Web view */}
            <a className="login lang">
              <img src="../assets/images/gloab_alt.png" alt="globe-alt" />
              <LangSelectOptions
                onChange={(e) => handleListChange(e.target.value)}
                selectedOption={selectedOption}
                values={optionList}
              />
            </a>
          </div>
          {console.log(location.pathname)}
          <div class="log mob d-none">
            <NavLink className="Saved" to={"/SellYourCar"}>
              {/* <img src="../assets/images/sell.png" alt="heart" /> */}
              <Icon
                icon="cryptocurrency:usd"
                fontSize={25}
                color={
                  location.pathname === "/SellYourCar" ? "#6cde07" : "#00000094"
                }
                style={{ marginBottom: "-10px" }}
              />
              <span>{t("Sell")}</span>
            </NavLink>
            <NavLink className="logo" to={"/"}>
              <img src={`../assets/images/${t("MoboIcon")}`} />
            </NavLink>
            <NavLink className="Saved" to={"/Saved"}>
              <img
                src={`../assets/images/${
                  location.pathname === "/Saved"
                    ? "red_fill.png"
                    : "red_unfill.png"
                }`}
                className="heartIcon2"
                alt="heart"
              />
              <span>{t("Saved")}</span>
            </NavLink>
            <NavLink
              className="login"
              to={"/AdvancedSearch"}
              state={{
                selectedOption: location.pathname === "/" ? "Cars" : "Rentals",
              }}
              style={{ marginTop: "-4px" }}
            >
              {/* <img src="../assets/images/search.png" alt="user" /> */}
              <Icon
                icon={`ph:magnifying-glass${
                  location.pathname === "/AdvancedSearch" ? "-fill" : ""
                }`}
                fontSize={30}
                color={location.pathname === "/AdvancedSearch" ? "#4C7EE8" : ""}
              />
              <span>{t("Search")}</span>
            </NavLink>
          </div>
        </nav>
      </div>
    </header>
  );
}
