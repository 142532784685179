import React from "react";
import { useTranslation } from "react-i18next";
import { title } from "../../data/content";
import { Privacy } from "../Pages/MyAccount/content";
import { getSiteContent } from "../../Reducer/api";
import { useState } from "react";
import { useEffect } from "react";
import { marked } from "marked";
import { useSelector } from "react-redux";

export default function PrivacyPolicies() {
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();

  //   const Privacys = Privacy;
  //   document.title = title.Privacyp[i18n.language];

  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getSiteContent('privacyPolicy').then((res) => {
      res.json().then((r) => {
        console.log(r)
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });
  
  return (
    <div>
      <div class=" staticConditions container my-5 min-vh-100">
      <p>
        {/* <p>
          <p style={{ textAlign: "center" }}>
            <strong style={{ fontSize: "30px" }}>
              Ako Sayara Privacy Notice (last updated 22 November 2022)
            </strong>
          </p>
          <p>
            Ako Sayara is an Iraqi digital automotive marketplace that offers a
            vehicle buying and selling process. To provide you with the full
            range of Ako Sayara products and services, we need to collect and
            process certain information about you.
          </p>
          <p>
            This Notice and our Cookies Policy apply to all visitors to and
            users of:
          </p>
          <ul>
            <li>
              the Ako Sayara website at{" "}
              <a href="http://www.akosayara.com/" target="_blank">
                www.akosayara.com
              </a>{" "}
              (the "Website").
            </li>
            <li>
              any Ako Sayara website that links to this Notice and/or our
              Cookies Policy.
            </li>
            <li>Ako Sayara social media accounts.</li>
            <li>our mobile devices and Applications ("Apps").</li>
          </ul>
          <p>
            It also applies to anyone contacting us by phone, SMS, email, in
            letters and other correspondence, in person and via social media.
          </p>
          <p>
            “Data Protection legislation” means all applicable data protection
            and privacy legislation in force from time to time in Iraq including
            Iraq GDPR;
          </p>
          <ul>
            <li>
              <strong>Who are we? Data Controller and contact details.</strong>
            </li>
            <li>
              For the purposes of this Notice, the data controller is Ako Sayara
              (registered in Iraq under company registration number ***** and
              whose registered office address is at Albaz Globe Limited,
              Samawah, Sooq Maskof, Iraq. References in this Policy to “Ako
              Sayara" also apply to its sister channels on this Website,
              including: Ako Sayara Rentals. If you have any questions regarding
              the use of your personal data, please contact us at:
              dataprotection@akosayara.com
              <br />
              or
              <br />
              Data Protection, Albaz Globe Limited, Samawah, Sooq Maskof, Iraq.
            </li>
          </ul>
          <p>
            <strong>+ Account Creation</strong>
          </p>
          <p>
            To create an account, we will collect your email address and your
            chosen password and your full name and telephone number. Once you
            have registered you can update your personal details in your account
            at any time and you can view your adverts, payment history and
            subscriptions with us. At the time of creating an online order on
            the Website, this personal information will be saved in your
            account. Where you log in via Facebook or another social media
            account, we may be provided with limited personal details (including
            your account ID and email address) to verify you are logged in.
          </p>
          <p>Legal basis:</p>
          <ul>
            <li>Contract</li>
            <li>Legitimate Interest</li>
          </ul>
          <p>More Information</p>
          <p>
            We use your name and email address to register your account and send
            you service communications about how to access and manage your
            account (available within the "My Account" section of the Website).
            Where you have provided a telephone number, we may also use this to
            contact you about your account. You can access the "My Account" area
            at any time to update your details and can also change your options
            regarding marketing communications from Ako Sayara and/or third
            parties that you have selected in this area.
          </p>
          <p>
            If you log-in via Facebook or another social media account, those
            sites may automatically give us access to your limited personal
            details (including your account ID and email address) in order to
            verify if you are logged in with their site. Once you are logged-in
            to our Website/App your data will be handled by us in accordance
            with this Privacy Policy and the Cookie Policy. We are not
            responsible for any use of your personal data by Facebook, or any
            other social media site and you should review their privacy
            policies.
            <br />
          </p>
          <p>
            <strong>+ Placing an Advertisement </strong>
          </p>
          <p>
            As part of the advert placement process on the Website/App, we
            collect your name, telephone number, email address, as well as
            details of the vehicle you wish to sell (vehicle identification{" "}
            <br />
            number VIN) and device ID and IP address.
          </p>
          <p>Legal basis:</p>
          <ul>
            <li>Contract</li>
            <li>Legitimate interest</li>
            <li>Legal Obligation</li>
          </ul>
          <p>Click here for an explanation regarding the legal basis.</p>
          <p>
            <strong>More Information</strong>
          </p>
          <p>
            We use your name and email address to register your account and
            place your advert, confirm that your advert has been published and
            send you service communications about how to access and manage your
            account (available within the "My Account" section of the
            Website/App) and your advert. You can access the "My Account" area
            at any time to update your details and can also change your options
            regarding marketing communications from Ako Sayara and/or third
            parties that you have selected in this area.
          </p>
          <p>
            We use your address to provide a positioning of the vehicle you
            advertise within our database; this allows potential buyers to
            locate vehicles for sale within a set radius of their chosen
            location. Your address is not disclosed in your advert.
          </p>
          <p>
            Vehicle Identification Number (VIN) are required to place an advert
            on the Website/App for regulatory and security purposes. We will not
            disclose the Vehicle Identification Number (VIN) within the text of
            your advert to any person. However, if the Vehicle Identification
            Number (VIN) is displayed in photographs within your advert then
            these details will be visible to Website/App visitors who may use
            them without your authorisation.
          </p>
          <p>
            If your advert expires, we will contact you via phone or email to
            give you the option to rebook your advert.
          </p>
          <p>
            We may also use the information you provide for internal business
            records and purposes, including to analyse and develop our products
            and services and the Website/App.
          </p>
          <p>
            We use certain features in our advert placement process to protect
            the privacy of our customers when they are communicating with
            potential buyers via phone or email.
          </p>
          <p>
            For customers placing a Private advert, we apply a Protect Your
            Number facility as a default on all adverts when customers provide
            their phone number. For customers placing Private adverts, we also
            provide a customer-to-customer chat service that allows Website/App
            visitors and potential buyers who view your advert to contact you
            via a chat button displayed on your advert, without disclosing your
            information. We use this to disable any potential harmful links that
            may expose you to fraudulent activity. The use of the
            customer-to-customer chat service is mandatory for us to maintain
            security of our Website/App. We reserve the right to monitor and
            retain all communications via the email and chat service for
            security monitoring purposes.
          </p>
          <p>
            You will also be required to supply payment card details to place
            your advert. We use third party payment providers to manage online
            card payment transactions. Payment card information is collected
            over a secure online payment gateway provided by:
          </p>
          <p>(Name of the provider)</p>
          <p>
            All payment transactions processed are encrypted. Your payment card
            information is used solely to process payment for your advert. We
            retain no record of your payment card details.
          </p>
          <p>
            <strong>+ Communicate with us.</strong>
          </p>
          <p>
            When you communicate with us, you may provide personal data to us
            such as your name, address, contact details, organisation details,
            dietary requirements. We will use this in order to deal with your
            communication.
          </p>
          <p>Legal basis:</p>
          <ul>
            <li>Consent</li>
            <li>Legitimate interest</li>
            <li>Contract</li>
          </ul>
          <p>More information</p>
          <p>
            You may provide your personal data to us for one of the following
            reasons:
          </p>
          <ul>
            <li>
              To request information about advertising with Ako Sayara or about
              any of Ako Sayara services.
            </li>
            <li>To make a complaint.</li>
            <li>
              To enquire about working with us whether the enquiry is made by
              you personally or on behalf of an organisation.
            </li>
            <li>To register for and/or attend an event; and/or</li>
            <li>To respond to a survey.</li>
          </ul>
          <p>+ Using Services on Ako Sayara</p>
          <p>
            When using services on Ako Sayara you will need to provide certain
            personal information such as name, email address, telephone number
            and address and details regarding your vehicle (including vehicle
            identification number, Kilometres, make and model) to enable us or a
            third party to provide you with the services requested. Depending on
            the service, this information may be passed to a third party.
          </p>
          <p>Legal Basis</p>
          <ul>
            <li>Contract</li>
            <li>Consent</li>
            <li>Legitimate Interests</li>
          </ul>
          <p>
            <strong>More information</strong>
          </p>
          <p>
            <strong>Valuation</strong> – You will need to provide details
            regarding your vehicle in order to obtain a valuation. This may be
            the vehicle identification number and kilometres and/or other
            details regarding your vehicle such as the make and model.
          </p>
          <p>
            <strong>Vehicle Search facility</strong> - You will need to supply
            your address in order to use our vehicle search facility. We will
            use this to present adverts matching your search criteria with the
            distance from your selected location.
          </p>
          <p>
            <strong>Request for test drive/quote/brochure</strong> - If you book
            a test drive of a new vehicle, or request a quote or brochure,
            through the Website/App you will need to provide your{" "}
            <strong>
              name, email address, postal address and telephone number
            </strong>{" "}
            which will be passed to the relevant dealer or other third party.
            Each request form clearly indicates that you are giving
            authorisation for your details to be passed to the relevant dealer
            or other party for them to contact you and action your request.
          </p>
          <p>
            <strong>Chat Service</strong> - If you use the chat service,
            available on dealer adverts, to communicate with dealers ("Chat
            Service"), you may disclose your{" "}
            <strong>name, email address and other personal information</strong>{" "}
            to a dealer while using the service. The Chat service is provided by
            a third party's provider. Please refer to the relevant Chat Service
            provider's privacy policy for further information about how they
            process your personal data.
          </p>
          <p>
            All conversations and email exchanges are recorded. Any personal
            information that you disclose during a live chat or via an online
            form will be accessible to and processed by the relevant Chat
            Service provider, the dealer with whom you communicate, and will be
            made available to Ako Sayara. By using the Chat Service, you
            acknowledge and agree that any personal data disclosed by you while
            using the Chat Service will be processed and stored by the Chat
            Service provider on its systems outside Iraq and protected by
            adequate safeguards.
          </p>
          <p>
            <strong>
              + Information we collect if you use our mobile application.
            </strong>
          </p>
          <p>
            If you choose to download or use our Mobile Applications, or access
            one of our mobile optimised sites, we may receive information about
            your <strong>location and your mobile device</strong>, including a{" "}
            <strong>unique identifier</strong> for your device. We may use this
            information to provide you with location-based services, such as
            advertising, search results, and other personalised content.
          </p>
          <p>Legal Basis:</p>
          <ul>
            <li>Legitimate interest</li>
            <li>Consent</li>
          </ul>
          <p>More information</p>
          <p>
            Most mobile devices allow you to control or disable location
            services within the mobile device’s settings. If you have questions
            about how to disable your device's location services, we recommend
            you contact your mobile service carrier or the manufacturer of your
            device.
          </p>
          <p>
            <strong>
            + Administration, development and monitoring of the Website/App
              service.
            </strong>
          </p>
          <p>
            To administer, maintain, develop, and improve our Website/App and
            services, we use your contact details and details of your activity
            on the Website/App and interactions with us such as name, telephone
            number, email address and address, details of adverts you have
            published, details within the My Account section of the Website/App
            and your interactions with us.
          </p>
          <p>Legal Basis:</p>
          <ul>
            <li>Legitimate Interests</li>
            <li>Consent</li>
          </ul>
          <p>More information</p>
          <p>
            We will use your contact details such as name, telephone number,
            email address and Address, details of adverts you have published,
            details with the My Account section of the Website/App and details
            of your activity on the Website/App and your interactions with us in
            order to:
          </p>
          <ul>
            <li>
              provide and administer the Website/App and related services and
              manage internal operations, including troubleshooting, data
              analysis, testing, research, statistical and survey purposes, and
              to develop the Website/App and our products and services.
            </li>
            <li>
              generate an overall audience profile of visitors using the
              Website/App. We use this information to assist us in developing
              relevant content on the Website/App as well as encouraging
              automotive trade advertisers to place adverts for their stock or
              promotions on the Website/App.
            </li>
            <li>
              provide customers who have opted-in to receive marketing
              communications, with relevant and timely offers by email.
            </li>
            <li>
              support our 'Send to Mobile' functionality. This allows visitors
              to our Website/App to send details of vehicles for sale to their
              mobile phone.
            </li>
            <li>
              provide a user-friendly online search experience. This includes
              the use of website/App analytics tools as described further in our
              Cookie Policy.
            </li>
            <li>
              identify the location of visitors, to block disruptive or
              fraudulent use, to establish the number of visits from different
              countries.
            </li>
          </ul>
          <p>
            <strong>
              + The Legal Basis we rely on for processing your information.
            </strong>
          </p>
          <p>
            Under data protection laws, we are required to have a legal basis to
            process your personal information. We have set out below the legal
            bases we rely on:
          </p>
          <ul>
            <li>
              <strong>Contract</strong>: To perform a contract entered into with
              you and fulfil our contractual obligations to you or to take steps
              at your request prior to entering into a contract. This processing
              is necessary for us to provide the service you have requested
              including registering and managing your account publishing your
              advert and providing service updates and our support services.
            </li>
            <li>
              <strong>Consent</strong>: To provide information and take actions
              in relation to services which you have opted-in to receive. This
              includes where you specifically request for your details to be
              passed onto a third party (e.g. where you make an enquiry
              regarding a test drive or a vehicle for sale) or where you opt-in
              to receive relevant marketing communications from us (e.g.
              industry news and offers). For further information regarding
              consent please see below.
            </li>
            <li>
              <strong>Legal Obligation</strong>: Where processing is necessary
              to comply with our legal or statutory obligations. This may
              include cooperating with police in relation to their
              investigations.
            </li>
            <li>
              <strong>Legitimate interest</strong>: Where necessary for our
              legitimate interests or those of a third party. We rely on this
              basis in order to monitor and improve our Website/App, products
              and/or services, understand how visitors and customers use our
              Website/App, products and/or services, to personalise content
              provided to you, to deal with enquiries and complaints, undertake
              market research and inform our general marketing, to run our
              business and maintain the security or our Website/App and services
              for you, us and other Website/App visitors and customers.
            </li>
          </ul>
          <p>
            <strong>+ Content </strong>
          </p>
          <p>
            Where consent is required for us or a dealer to process certain
            types of your personal data, it must be clearly given. You may give
            us your consent to receive our marketing communications and/or when
            you use the Website/App to request services provided by dealers.
          </p>
          <p>
            Where you have given consent for Ako Sayara to process your personal
            data you may withdraw consent at any time by emailing
            dataprotection@akosayara.com. We will cease processing your data
            based on that consent, but this does not affect any processing we
            have undertaken at any time before withdrawal.
          </p>
          <p>
            If you have given consent for your details to be passed to a dealer
            or other third party your data may already have been used by them to
            respond to your request before we can action your consent
            withdrawal. In this event, you will need to contact them to withdraw
            your consent to their use of your personal data.
          </p>
          <ul>
            <li>
              <strong>Disclosure of your information </strong>
            </li>
          </ul>
          <p>
            Ako Sayara will not pass on your personal data to third parties
            except as set out in this Notice or with your consent. We may
            disclose your personal information to third parties for the
            following purpose(s) and in the following circumstances as part of
            our processing activities:
          </p>
          <ul>
            <li>
              We will send your personal information to dealers, vehicle
              manufacturers, bike manufacturers, finance providers and other
              relevant third parties to enable them to provide you with the
              services and products that you have requested or consented to
              receive details of via the Website or the Apps. In certain
              circumstances, vehicle manufacturers (including Ford Motor Company
              Limited) or bike manufacturers and other relevant third parties
              may subsequently transfer, process and store your personal
              information outside of Iraq for their business and administration
              purposes. in accordance with their own privacy policies. If you
              have queries or require more information on how these third
              parties use information, including transfers outside of Iraq,
              please read their privacy policies or contact them.
            </li>
            <li>
              We may use service providers to help us run the Website/App or
              services available on the Website/App, some of whom may be based
              outside Iraq. Some of our webpages use plugins from third party
              platforms (such as the 'Facebook Recommend' or 'Facebook Gestures'
              function). Information about your visit to the Website/App may be
              collected by these third-party providers on these pages. If you
              browse these pages whilst still also logged in to your account on
              the third-party platform, information collected by the third party
              may be connected to your account on the third party’s platform. If
              you have queries or require more information on how these third
              parties use information, including transfers outside of Iraq,
              please read their privacy policies or contact them.
            </li>
            <li>
              As a security measure we use 2 factor verification when you login
              to your account We send your mobile number and a code to a third
              party’s provider (IOVOX or Clickatell) who will send you a code to
              enable you to login.
            </li>
            <li>
              Where we are under a duty to disclose or share your personal
              information with a third party in order to comply with any legal
              obligation, or in order to enforce our terms and conditions and
              any other agreements.
            </li>
            <li>
              If we sell or buy any business or assets, in which case Ako Sayara
              may disclose your personal data to the prospective seller or buyer
              of such business or assets.
            </li>
            <li>
              We may share your personal information with our group companies.
            </li>
            <li>
              We may share any information that we collect with parties
              including: our legal and professional advisors, the police, other
              public or private sector agencies, governmental or representative
              bodies (which may include insurance companies, finance companies,
              automotive advertising companies and/or other agencies) in
              accordance with the relevant legislation for the purposes of the
              prevention or detection of offences, and/or the apprehension or
              prosecution of offenders.
            </li>
            <li>
              You acknowledge that we have a legitimate business interest in
              preventing the use of our platform or services in any manner that
              may facilitate fraudulent activity or deny consumers of their
              legal rights. We may share any information that we collect about
              you or the vehicles that you advertise for sale on our platform
              with third parties or their legal representatives to the extent
              necessary to prevent fraud or any adverse effect to those third
              parties consumer rights.
            </li>
            <li>
              We are not responsible for any use of your personal data by these
              parties except where they act as our processor.
              <br />
              <br />
            </li>
          </ul>
          <p>
            <br /><strong>+ Where we store your personal data</strong>. <br />
            Ako Sayara will take all reasonable steps to ensure that your data
            is treated securely and in accordance with this Policy. All
            information you provide to us is stored securely within Iraq, and
            not transferred outside Iraq unless specifically detailed in this
            Policy. <br />
          </p>
          <ul>
            <li>
              <strong>More information</strong>
              <br />
              Payment card information, details you provide when requesting
              dealer or other third party’s services through the Website/App and
              information you provide when using the Chat Service is handled in
              accordance with the relevant service providers’ privacy policies
              and may be transferred and stored or processed by them outside of
              Iraq in accordance with their privacy policies. <br />
              Where we transfer your data outside of Iraq, we will ensure that
              safeguards are in place to provide similar degree of protection is
              available to protect it. This may include contractual protections
              in place with our service providers or transferring your data to
              countries or parties which are recognised as providing suitable
              protection by the European Commission.
              <br />
              Certain parties to whom we disclose your personal data may
              transfer your data outside of Iraq after we disclose it to them as
              part of their business and administration activities (see above,
              Disclosure of your information). We are not responsible for any
              use of your personal data by these parties except where they act
              as our processor. If you have queries or require more information
              on how these third parties use your information, including such
              transfers outside of Iraq, please read their privacy policies or
              contact them.
              <br />
              Ako Sayara uses strict procedures and security features designed
              to prevent any unauthorised access to your data that we hold.
              <br />
              <br />
            </li>
          </ul>
          <p>
            <br /><strong>+ How long do we keep your data</strong>
            <br />
            Ako Sayara will process personal data for as long as necessary to
            fulfil the purpose we collected it for, including the purpose of
            legal, accounting and reporting requirements, and for as long as
            necessary for the prevention and detection of criminal activity.{" "}
          </p>
          <p>
            <br />
          </p>
          <ul>
            <li>
              <strong>More information</strong>
              <br />
              The period for which we process and store the personal data for
              varies depending on the use you make of the Website/App and the
              products and/or services we offer. Where you register an account
              or place an advert we will retain your data for as long as your
              account and/or advert is live and a period after your account is
              closed or advert ends to enable us to satisfy our legal,
              accounting and reporting requirements.
              <br />
              In some circumstances you can ask us to delete your data: see
              Right to be forgotten below for further information.
              <br />
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes in which case we may use this information
              indefinitely without further notice to you.
              <br />
            </li>
          </ul>
          <p>
            <br />
            <br />
            <strong>+ Your rights as a data subject</strong>
            <br />
            Under data protection law, you have the following rights:
          </p>
          <ul>
            <li>Access.</li>
            <li>Rectification.</li>
            <li>Erasure/Right to be forgotten.</li>
            <li>Restrict processing.</li>
            <li>Portability.</li>
            <li>Object to processing.</li>
            <li>Complain.</li>
            <li>
              Some of these rights may not always apply. You can read more about
              your rights below.
              <br />
            </li>
          </ul>
          <p>
            <strong>More information</strong>
            <br />
            You may be able to exercise the following rights:
          </p>
          <ul>
            <li>
              Right of access – you have the right to request a copy of the
              information that we hold about you. In the event that’s Ako Sayara
              refuses your request under rights of access, we will provide you
              with a reason as to why.
            </li>
            <li>
              Right of rectification – you have a right to correct data that we
              hold about you that is inaccurate or incomplete.
            </li>
            <li>
              Right or erasure/ to be forgotten – in certain circumstances you
              can ask for the data we hold about you to be erased from our
              records.
            </li>
            <li>
              Right to restrict processing – where certain conditions apply to
              have a right to restrict the processing.
            </li>
            <li>
              Right of portability – in certain circumstances you have the right
              to have the data we hold about you transferred to another
              organisation.
            </li>
            <li>
              Right to object – you have the right to object to certain types of
              processing such as direct marketing, automated processing or
              profiling.
            </li>
            <li>
              Right to complain to the supervisory authority – you have the
              right to complain as outlined in Section 8 below.
            </li>
            <li>
              Please email dataprotection@akosayara.com if you have any queries
              regarding your rights. You can exercise your rights as set out
              above by emailing dataprotection@akosayara.com. All of the above
              requests will be forwarded on should there be a third party
              involved (as set out in this Privacy Policy) in the processing of
              your personal data.
              <br />
              <br />
            </li>
          </ul>
          <strong>+ Complaints.</strong>
          <p>
            If you wish to make a complaint about how your personal data is
            being processed by Ako Sayara (or third parties as described above),
            or how your complaint has been handled, in the first instance please
            email your concerns to dataprotection@akosayara.com.
          </p>
        </p> */}
      </p>
        

      <p 
        dangerouslySetInnerHTML={{
          __html: marked.parse(data ? (data?.[lang]) : t("Please wait...")),
        }}
      ></p>

        {/* {data.map((item) => {
          return (
            <div className="container">
              <h4>{item.title[lang]}</h4>
              <p className="text-center">{item.description[lang]}</p>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
