import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertBase64 } from "../../../data/content";
import InputFormFile from "./../InputFormFile";
import ListItem from "../ListItem";
import i18n from "../../../i18n";
import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import { isImageOrVideo } from "../../../utils/constants";

export default function Media({
  setInputFileError,
  register,
  submit,
  InputFileError,
  base64,
  setBase64,
  setInputFile,
  inputFile,
  status,
  class1,
  class2,
  adId,
  type,
  uploadedFiles,
  setUploadedFiles,
  uploading,
  setUploading,
  storedList,
  callFunc,
  listOfImages,
  setListOfImages,
  userId,
}) {
  const { t } = useTranslation();
  const fileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/heic",
    "image/heif",
    "video/mp4",
    "video/quicktime",
  ];
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  // const [uploading, setUploading] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    if (storedList) {
      setImageList(storedList);
    }
  }, [storedList]);

  const selectFile = async (event) => {
    if (!event?.target?.files.length) {
      return;
    }
    if (event?.target?.files[0].size > 150000000) {
      setInputFileError(t("File upload with valid size"));
      return;
    } else if (
      !fileTypes.includes(event?.target?.files?.[0].type) &&
      !event?.target?.files?.[0].type == ""
    ) {
      setInputFileError(t("File upload with valid file type"));
      return;
    } else {

      let file = null;
      if (!event?.target?.files?.[0].type) {
        file = new File(event?.target?.files, event?.target?.files?.[0].name, {
          type: "image/jpeg",
        });
      } else {
        file = event?.target?.files?.[0];
      }

      let base64data = null;
      base64data = await convertBase64(file);
      // if (file.type.startsWith("video")) {
      //   base64data = "assets/images/default-image-thumbnail.webp";
      // } else {
      // }

      setInputFileError("");
      console.log(file)
      const formData = new FormData();
      formData.append("files", file);
      setUploading(true);
      apiManager(
        "POST",
        `storage/upload?lang=${lang}&type=${type}&adId=${
          adId ? adId : ""
        }&userId=${userId ? userId : ""}`,
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          if (res.status) {
            // toast.success(res.message);
            let newArr = [res?.data[0]?.s3URL];
            newArr = [...newArr, ...uploadedFiles];
            setUploadedFiles(newArr);
            setBase64([...base64, base64data]);
            setImageList([...imageList, res?.data[0]]);
            setListOfImages?.([...imageList, res?.data[0]]);
            setInputFile([]);
            setUploading(false);
            callFunc?.();
            setInputFile([...inputFile, file]);
          } else {
            toast.error(res.message);
            setUploading(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setUploading(false);
          console.log(err);
        });
    }
  };
  console.log(base64)
  const removeIndex = (i) => {
    if (!uploading) {
      const base = base64.filter((_, index) => index !== i);
      const file = inputFile.filter((_, index) => index !== i);
      console.log(imageList);
      apiManager(
        "DELETE",
        `storage/upload?lang=en&id=${
          imageList?.[i]?._id ? imageList?.[i]?._id : listOfImages?.[i]?._id
        }`
      )
        .then((res) => {
          if (res.status) {
            // toast.success(res.message)
            setInputFile(file);
            setBase64(base);
            const urlArr = uploadedFiles.filter((_, index) => index !== i);
            const itemArr = imageList.filter((_, index) => index !== i);

            setUploadedFiles(urlArr);
            setImageList(itemArr);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.error(err);
        });
    }
  };

  const [radio, setRadio] = useState(0);
  return (
    <div class="other_div other_div2">
      <h3>{status == "false" ? "" : t("Upload Media") + " *"}</h3>
      <div class="Information">
        <ul class={class1}>
          <li class={class2}>
            <div class="st uploaddetaildiv">
              {uploading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i class="fa-solid fa-plus"></i>
              )}
              <InputFormFile
                onChange={selectFile}
                event={register}
                accept={fileTypes.join(", ")}
                name={"file"}
                disabled={uploading}
              />
            </div>
          </li>
          {base64?.map((item, key) => {
            return (
              <ListItem
                key={key}
                style={class2}
                innerhtmlType={"html"}
                value={
                  <>
                    {isImageOrVideo(item) === "video" ? (
                      <label
                        htmlFor={`radioMain${key}`}
                        className={``}
                        onChange={(e) => console.log(e)}
                      >
                        <div
                          class="st"
                          style={{
                            width: "84px",
                          }}
                        >
                          <img
                            class="img-cros"
                            id="blah"
                            onClick={() => {
                              removeIndex(key);
                            }}
                            src={"/assets/images/close_img.png"}
                            alt="your image"
                            style={{ zIndex: 999 }}
                          />
                          <video
                            className="h-100 w-100"
                            style={{ objectFit: "cover" }}
                          >
                            <source src={item} />
                          </video>
                        </div>
                        <input
                          id={`radioMain${key}`}
                          type="radio"
                          name="main"
                          style={{ display: "none" }}
                        />
                      </label>
                    ) : (
                      <label
                        htmlFor={`radioMain${key}`}
                        className={``}
                        onChange={(e) => console.log(e)}
                      >
                        <div
                          class="st"
                          style={{
                            backgroundImage: `url(${item})`,
                            width: "84px",
                            backgroundSize: "cover",
                          }}
                        >
                          <img
                            class="img-cros"
                            id="blah"
                            onClick={() => {
                              removeIndex(key);
                            }}
                            src={"/assets/images/close_img.png"}
                            alt="your image"
                          />
                        </div>
                        <input
                          id={`radioMain${key}`}
                          type="radio"
                          name="main"
                          style={{ display: "none" }}
                        />
                      </label>
                    )}
                  </>
                }
              ></ListItem>
            );
          })}
        </ul>
        {/* <h6 class="text-start w-100">
          {t("Allowed file formats: JPG, PNG")}
          <span>
            {t("Optimal size:")} <b>{t("900*600px")}</b>
          </span>
        </h6> */}
        <div class="row justify-content-end">
          {/* <button
          class="btn btn-sm-light border mx-3"
          style={{width: '120px', height: '40px', fontSize: '12px'}}
          onClick={(e) => props?.handleUploadDocument(e)}
          disabled={props?.uploading}
        >
          {props?.uploading ? t('Please wait...') : t("Upload Media")}
        </button> */}
        </div>
      </div>
      {InputFileError && (
        <p
          style={{
            fontSize: "13px",
            textAlign: lang === "en" ? "left" : "right",
            color: "red",
          }}
        >
          {t(InputFileError)}
        </p>
      )}
    </div>
  );
}
