import { t } from "i18next";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import {
  sendResetPasswordOTP,
  sendVerificationOTP,
  sendWhatsappOtp,
  verifyOtp,
} from "../../../Reducer/api";
import { Icon } from "@iconify/react/dist/iconify.js";
import { convertDigitsToEnglish } from "../../../utils/constants";

export default function VerifyWithOtp({
  modal,
  handleClose,
  data,
  setVerifyErrors,
}) {
  const [sentOn, setSentOn] = useState(
    data?.type === "Email" ? "Email" : "Whatsapp"
  );
  useEffect(() => {
    setSentOn(data?.type === "Email" ? "Email" : "Whatsapp");
  }, [data]);
  const [otp, setOtp] = useState("");
  const lang = localStorage.getItem("cl");
  const [error, setError] = useState(data?.defaultAlert);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOtp("");
    setError("");
    setLoading(false)
  }, [handleClose]);
  const handleSubmit = () => {
    setError("");
    if (otp.length < 6) {
      setError(t("Enter code"));
      return;
    }
    setLoading(true);
    const otpEnDigits = convertDigitsToEnglish(otp);
    verifyOtp({ otp: otpEnDigits, type: sentOn, identifier: data.identifier }).then(
      (res) => {
        if (res.status) {
          localStorage.setItem(`${data.type}-verified`, true);
          handleClose();
        } else {
          setError(res.message);
        }
        setLoading(false);
      }
    );
  };
  const [showSuccess, setShowSuccess] = useState(false);
  console.log(data);

  const resendOtp = () => {
    const tmp = data?.type === "Email" ? "Email" : "Text";
    let formData = {};
    if (data.type === "Email") {
      formData = { email: data.identifier };
    } else {
      formData = { phoneNumber: data.identifier };
    }
    formData = { ...formData, type: 3 };
    setOtp("");
    setError("");
    sendVerificationOTP(formData).then((res) => {
      if (res.status) {
        setError(t("otp sent again"));
        setSentOn(tmp);
      } else {
        setError(res.message);
      }
    });
  };

  const resendWhatsappOtp = () => {
    const formData = {
      phoneNumber: data.identifier,
    };
    setOtp("");
    setError("");
    sendWhatsappOtp(formData).then((res) => {
      if (res.status) {
        setError(t("otp sent again"));
        setSentOn("Whatsapp");
      } else {
        setError(res.message);
      }
    });
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={handleClose} size="md" centered>
        <ModalHeader className="float-start">
          {data.type === "Email" ? t("Verify email") : t("Verify phone")}
        </ModalHeader>
        <ModalBody>
          {showSuccess ? (
            <>
              <h2>your {data.type} is verified</h2>
            </>
          ) : (
            <>
              <h6 className="my-1 text-center">
                {sentOn === "Email"
                  ? t("A one time code has been sent to your email")
                  : ""}
                {sentOn === "Whatsapp"
                  ? t("A Otp has been sent to your whatsapp")
                  : ""}
                {sentOn === "Text"
                  ? t("A Otp has been sent to your phone")
                  : ""}
              </h6>
              <h4 style={{ marginInline: "10px" }} className="my-3">
                {t("Enter code")}
              </h4>
              <div
                className="d-flex justify-content-center"
                style={{ direction: "ltr" }}
              >
                <OTPInput
                  inputStyle={{
                    width: "100%",
                    height: "35px",
                    borderRadius: "5px",
                    marginInline: "10px",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              {error && (
                <p
                  className="fs-6 text-danger mt-2"
                  style={{ marginInline: "10px" }}
                >
                  {error}
                </p>
              )}
              {/* <p
                className={`fs-6 mt-2 text-primary text-${
                  lang === "English" ? "end" : "start"
                }`}
                style={{ marginInline: "10px", cursor: "pointer", color: '#4C7EE8' }}
                onClick={() => resendOtp()}
              >
                {t("Resend Verification Code")}
              </p> */}
              {/* <div className="mt-3 d-flex justify-content-between">
                <p
                  className={`fs-6 w-100 text-primary text-start justify-content-between d-flex align-items-center p-2 border rounded-3`}
                  style={{
                    marginInline: "10px",
                    cursor: "pointer",
                    color: "#4C7EE8",
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#ecffec'
                  }}
                  onClick={() => resendOtp()}
                >
                  
                  {t("Resend on Whatsapp")}
                  <Icon icon="logos:whatsapp-icon" fontSize={30} />
                </p>
                <p
                  className={`fs-6 w-100 text-primary text-start justify-content-between d-flex align-items-center p-2 border rounded-3`}
                  style={{
                    marginInline: "10px",
                    cursor: "pointer",
                    color: "#4C7EE8",
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#efefff'  
                  }}
                  onClick={() => resendOtp()}
                >
                  {t("Resend as SMS")}<Icon icon="ic:round-sms"  style={{color: '#a2a2e9'}} fontSize={30} />
                </p>
              </div> */}
              <div className="row">
                <div className="col-sm-5 col-12 mt-5">
                  {data?.type !== "Email" && (
                    <p
                      className={`fs-6 text-primary text-${
                        lang === "English" ? "start" : "end"
                      }`}
                      style={{
                        marginInline: "10px",
                        cursor: "pointer",
                        color: "#4C7EE8",
                        margin: "0px 0px 0px 0px",
                      }}
                      onClick={() => resendWhatsappOtp()}
                    >
                      {t("Resend on Whatsapp")}
                    </p>
                  )}
                  <p
                    className={`fs-6 text-primary text-${
                      lang === "English" ? "start" : "end"
                    }`}
                    style={{
                      marginInline: "10px",
                      cursor: "pointer",
                      color: "#4C7EE8",
                      margin: "0px 0px 0px 0px",
                    }}
                    onClick={() => resendOtp()}
                  >
                    {data?.type === "Email"
                      ? t("Resend Verification Code")
                      : t("Resend as SMS")}
                  </p>
                </div>
                <div className="col-sm-7 col-12 d-flex justify-content-end mt-5">
                  <Button
                    outline={true}
                    style={{
                      marginInline: "10px",
                      width: "100px",
                      fontSize: "14px",
                      height: "50px",
                      borderRadius: "4px",
                    }}
                    onClick={handleClose}
                  >
                    {t("Verify later")}
                  </Button>
                  <Button
                    className="themeButton"
                    disabled={loading}
                    color="primary"
                    style={{
                      marginInline: "10px",
                      width: "100px",
                      height: "50px",
                      fontSize: "14px",
                      minWidth: "unset",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {loading ? (
                      <Spinner size={"sm"}>Loading...</Spinner>
                    ) : (
                      t("Submit")
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
