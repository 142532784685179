import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { fetchNewBrands } from "../../../Reducer/newBrandsSlice";
import OwlItem from "../../Tools/OwlItem";
import { homeOptions } from "../../../data/content";
import { NavLink } from "react-router-dom";
import OwlItemCars from "../../Tools/OwlItem/owlItemCars";

export default function BrandNewSection({ rtl, page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const new_brands = useSelector((state) => state.new_brands.new_brands);
  const loading = useSelector((state) => state.new_brands.loading);
  const error = useSelector((state) => state.new_brands.error);
  const auth = useSelector((state) => state.auth.auth);
  // const [respo, setData] = useState([]);

  let data = { id: auth && auth.id ? auth.id : "", page: page };
  useEffect(() => {
    dispatch(fetchNewBrands(data));
  }, [dispatch]);

  let responce = new_brands && new_brands.length > 0 ? new_brands : [];

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchNewBrands(data));
    }
  };
  return (
    <section class="recently_viewed">
      {responce.length > 0 && (
        <div class="container">
          <h2 class="hed">{t("Brand new car deals")}</h2>
          <OwlCarousel
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${rtl && 'invertButtons'}`}
            {...homeOptions}
            margin={10}
            rtl={rtl}
            nav
          >
            {/* <OwlItem key={0} fill={'heart_fill.png'} unfill={'heart.webp'} responce={responce} page={'BrandNewSection'} handleClick={handleClick}/> */}
            <OwlItemCars
              key={0}
              cars={responce}
              page={"BrandNewSection"}
              rentType={'daily'}
            />
          </OwlCarousel>
          <div class="w-100 float-left text-center">
            <NavLink className={"viewall"} to={"/BuyCar"}>
              {t("View All New Car Deals")}
            </NavLink>
          </div>
        </div>
      )}
    </section>
  );
}
