import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../Tools/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { openAddressOnMap } from "../../../utils/constants";

export default function DealersList({
  responce = [],
  pageCount,
  childData,
  currentPage,
  setCurrentPage,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const itemsPerPage = 3;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // const filteredResponse = responce.filter((item) =>
  //   item.title[i18n.language].toLowerCase().includes(searchKeyword.toLowerCase())
  // );
  // const displayedResponse = filteredResponse.slice(startIndex, endIndex);

  useEffect(() => {
    setSearchKeyword(childData);
  }, [childData]);

  const dealers = props?.dealersData;

  if (responce?.length == 0) {
    return <h3 style={{ height: "50vh" }} className="fs-4 fw-semibold">{t("No dealers found")}</h3>;
  }

  return (
    <div>
      {responce &&
        responce.map((item, key) => (
          <div className="car_set">
            <div className="row" key={key}>
              <div className="col-sm-4">
                <div className="img1">
                  <img
                    src={
                      item?.profilePicture
                        ? item?.profilePicture
                        : "/assets/images/notFound.png"
                    }
                    className="d-block w-100"
                    alt="No profile picture"
                  />
                </div>
              </div>
              <div className="col-sm-8">
                <h5>{item?.dealerShipName}</h5>
                <p>{item?.address}</p>

                <a
                  className="view_car"
                  onClick={() =>
                    navigate("/search_result_page" + "?id=" + item?._id)
                  }
                >
                  {t("View stock")}
                </a>

                <div className="cont">
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <a href={`tel:${item?.phoneNumber}`}>
                          <img src="../assets/images/call.png" alt="icon" />
                          {t("Call")} {item?.phoneNumber}
                        </a>
                      </p>
                    </div>
                    <div className="col-6 text-end">
                      <p>
                        <a onClick={() => openAddressOnMap(item?.address)}>
                          <img src="../assets/images/location.png" alt="icon" />
                          {t("Get directions")}
                        </a>
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        <a href={item?.additionalInfo?.website} target="_blank">
                          <img src="../assets/images/web.png" alt="icon" />
                          {t("Visit website")}
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <nav aria-label="Page navigation example">
        <Pagination
          style={`pagination float-end mb-5 mt-4 ${
            lang !== "en" && "paginationButtonAr"
          }`}
          next={"Next"}
          previous={"Previous"}
          forcePage={currentPage}
          pageCount={pageCount}
          rangeDisplayed={3}
          handlePageClick={handlePageClick}
        />
      </nav>
    </div>
  );
}
