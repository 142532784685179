import React from "react";
import { getSiteContent } from "../../Reducer/api";
import i18n from "../../i18n";
import { useEffect } from "react";
import { useState } from "react";
import { marked } from "marked";
import { t } from "i18next";
import { useSelector } from "react-redux";

export default function WhyAko() {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getSiteContent("whyAko").then((res) => {
      res.json().then((r) => {
        console.log(r);
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });

  return (
    <div class="staticConditions container my-5 min-vh-100">
      <p>
        {/* <p>
        <p style={{ textAlign: "center" }}>
          <strong style={{ fontSize: "30px" }}>Why Ako Sayara</strong>
        </p>
        <p>
          Unlike digital start-ups, we’ve got insight, data and advice that we
          can put into your car buying journey.
        </p>
        <p>
          Unlike heritage companies, we’re built for the modern age – agile and
          evolving to make sure you’re getting the best experience. Our data and
          insights are unparalleled in this industry, and we use it all to help
          you. Whether that’s offering Buy a Car or Rent a Car, we’re making the
          automotive industry more accessible and exciting for everyone, today
          and in the future.
        </p>
        <strong>About US</strong>
        <p>Ako Sayara is Iraq largest digital automotive marketplace.</p>
        <p>
          Ako Sayara prides itself on being the most trusted automotive
          marketplace. It’s the go-to destination for car buyers.{" "}
        </p>
        <p>
          Ako Sayara has over 90% prompted brand awareness with consumers and
          attracts over 50 million cross platform visits each month. The
          audience is not only large but highly engaged with a 75% share of
          minutes spent across automotive platforms.
        </p>
        <p>
          At every opportunity, we’ve pushed ourselves a bit further to make
          sure we’re giving you the best experience, and we’re still getting
          started.
        </p>
        <p>
          And as a company and employer, we’ve continuously refined our culture
          and values to ensure we’re inclusive, inventive, and sustainable.
        </p>
      </p> */}
      </p>

      <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data ? (data?.[lang]) : t("Please wait...")),
        }}
      ></p>

      {/* {data.map((item) => {
        return (
          <div className="container">
            <h4>{item.title[lang]}</h4>
            <p className="text-center">{item.description[lang]}</p>
          </div>
        );
      })} */}
    </div>
  );
}
