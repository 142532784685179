import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { title } from "../../../../../data/content";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";
import EditWorkingHour from "./EditWorkingHour";
import Profile from "./Profile";
import Security from "./Security";
import WorkingHour from "./WorkingHour";
import KycDocuments from "./DealershipDocuments";
import UploadCover from "./UploadCover";

export default function MyProfile({ page }) {
  const data = useSelector((state) => state.myprofile.profilearr);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    dispatch(myprofilefetch());
  }, [dispatch]);

  document.title = title.Myprofile[i18n.language];
  return (
    <div class="my_profile">
      <Profile data={data ? data : []} page={userType} />
      <Security data={data ? data : []} />
      {userType == "dealer" ? <KycDocuments data={data ? data : []} /> : ""}
      {userType == "dealer" ? <WorkingHour data={data ? data : []} /> : ""}
      {userType == "dealer" ? <UploadCover data={data ? data : []} /> : ""}
    </div>
  );
}
