import React, { useEffect, useState } from "react";
import Chat from "./Chat";
import ChatUser from "./ChatUser";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { messageFun } from "../../../../../Reducer/messageSlice";
import { title } from "../../../../../data/content";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { sideBarList } from "../../content";

export default function Messages() {
  const location = useLocation();
  const data = useSelector((state) => state.mychat.chats);
  const submitting = useSelector((state) => state.mychat.submitting);
  const [classs, setdata] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedChat, setSelectedChat] = useState();

  useEffect(() => {
    if (location?.state?.chatId) {
      console.log("location");
      setSelectedChat(location?.state?.chatId);
    } else if (data?.chats?.docs?.length) {
      setSelectedChat(data?.chats?.docs?.[0]?._id);
    } else {
      // console.log("else");
      // setSelectedChat(2);
    }
  }, [data]);

  useEffect(() => {
    dispatch(messageFun());
  }, [dispatch, classs]);

  document.title = title.Messages[i18n.language];

  const setClass = (m) => {
    if (classs === "main") {
      $(".sidebar").addClass("main");
    } else {
      setdata(m);
    }
  };
  const [loading, setLoading] = useState(false);
  // if(submitting) return 
  return (
    <div>
      <section class="chats close_amountmain ">
        <div class="container">
          <h2 onClick={() => setClass("main")}>
            <span className="">{t("Messages")}</span>
            <img
              style={{
                transform:
                  i18n.language === "English" ? "unset" : `rotate(180deg)`,
              }}
              src="../assets/images/aro_ap.png"
              className={`d-block d-sm-none float-${
                i18n.language === "English" ? "start" : "end"
              }`}
            />
          </h2>
          {data?.chats?.docs?.length > 0 > 0 ? (
            <div class="main_user test">
              <ChatUser
                data={data?.chats?.docs}
                classs={classs}
                setdata={setdata}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                setLoading={setLoading}
              />
              <Chat
                data={
                  data?.conversations?.filter((v) => v.id == selectedChat)?.[0]
                }
                selectedChat={selectedChat}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          ) : (
            <h4 className="text-center">{t("No users to chat")}</h4>
          )}
        </div>
      </section>
    </div>
  );
}
