import React from "react";
import { useTranslation } from "react-i18next";
import { title } from "../../data/content";
import { Privacy } from "../Pages/MyAccount/content";
import { getSiteContent } from "../../Reducer/api";
import { useState } from "react";
import { useEffect } from "react";
import { marked } from "marked";
import { useSelector } from "react-redux";

export default function PrivacyPolicies() {
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();

  //   const Privacys = Privacy;
  //   document.title = title.Privacyp[i18n.language];

  const lang =
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : "ku";
  const [data, setData] = useState();

  useEffect(() => {
    getSiteContent('termsCondition').then((res) => {
      res.json().then((r) => {
        console.log(r)
        setData(r?.data?.[0]?.attributes);
      });
    });
  }, []);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false
  });

  return (
    <div>
      <div class="staticConditions container min-vh-100">
        {/* <p>
          <p style={{ textAlign: "center" }}>
            <strong style={{ fontSize: "30px" }}>Terms of use</strong>
          </p>
          <p>
            Access to the Website/App is subject to the following terms of use.
          </p>
          <p>
            By accessing the Website/App, you accept all terms of use that apply
            to the facilities and services provided. We reserve the right to
            alter these terms of use at any time and it is your obligation to
            check regularly if changes have been made. Use of the Website/App
            after changes are posted on-line constitutes acceptance of any new
            terms of use.
          </p>
          <p>
            In these terms of use: "we/us/our" means Albaz Globe Limited;
            “Website/App" means the Ako Sayara Website/App www.akosayara.com;
            "you"/"your" means the user of the online services.
          </p>
          <strong >Access to Website/App</strong>
          <p>
            Access to our Website/App is permitted on a temporary basis, and we
            reserve the right to withdraw or amend the service we provide on our
            Website/App without notice (see below). We will not be liable if for
            any reason our site is unavailable at any time or for any period.
          </p>
          <p>
            From time to time, we may restrict access to some parts of our
            Website/App, or our entire Website/App, to users who have registered
            with us.
          </p>
          <strong>Content</strong>
          <p>
            By using the Website/App to search for vehicles, you agree that you
            will only use, reproduce and print materials comprising the results
            of such searches for your own personal, non-commercial purposes. You
            must only use the contact details provided by private advertisers to
            contact them in connection with a genuine enquiry relating to the
            purchase of the vehicle in question.
          </p>
          <p>
            Pages contained in the Website/App may contain technical
            inaccuracies and typographical errors. The information in these
            pages may be updated from time to time and may at times be out of
            date. We do not accept responsibility for keeping the information up
            to date or liability for any failure to do so.
          </p>
          <p>
            The material on the Website/App is only for information purposes. It
            does not constitute advice and you should not rely on any material
            on the Website/App to make (or refrain from making) any decision or
            take (or refrain from taking) any action.
          </p>
          <p>
            The Website/App contains material submitted and created by private
            and trade dealers and other third parties. We exclude all liability
            for any illegality arising from or error, omission or inaccuracy in
            such material.
          </p>
          <p>
            We give no warranties, conditions, guarantees or representations,
            express or implied, as to:-
          </p>
          <ul>
            <li>
              the completeness or accuracy of the advice and information
              contained on the Website/App or any associated website.
            </li>
            <li>
              the content of the advertisements for vehicles included in our
              database, including but not limited to the ownership, quality,
              authenticity of a vehicle or the content more generally including
              any photographs, compliance with description or fitness for
              purpose of any such vehicles. Whilst we use reasonable efforts to
              identify and remove fraudulent advertisements, we cannot guarantee
              to identify all fraudulent advertisements on the Website/App; and
            </li>
            <li>
              the results of any search of our database or circumstances where
              there is a limited number of vehicles selected by the search on
              our database which might meet the searcher's requirements.
            </li>
          </ul>
          <p>
            The Website/App publishes advertisements placed by third parties to
            advertise their vehicles and other goods and/or services. We do not
            own the vehicles or goods advertised for sale on the Website/App and
            have no liability for these or for any losses that you may incur as
            a result of purchasing a vehicle, goods and/or any services
            advertised by third parties. If you choose to purchase a vehicle,
            goods and/or services advertised for sale by third parties on the
            Website/App, the contract will be between you and the third party’s
            advertiser (or seller) and not with us. You are responsible for
            taking steps to safeguard you and your money before contacting an
            advertiser or seller and/or making a purchase. Advice on buying a
            car safely is provided{" "}
            <a href="https://www.autotrader.co.uk/content/advice/tips-on-buying-a-used-car-safely">
              here
            </a>{" "}
            . If you see anything suspicious, or believe an advertisement to be
            fraudulent, please report it to us immediately so we can
            investigate.
          </p>
          <strong>Copyright and trade mark.</strong>
          <p>
            All rights, including copyright, in the content of the web/App
            pages, in the photographs of any vehicles displayed on the
            Website/App and all database rights in our database of vehicles, are
            owned or controlled for these purposes by us.
          </p>
          <p>
            All trademarks, names and logos are the proprietary marks of Ako
            Sayara, or of our associated companies. Marks identifying third
            parties are owned or licensed by those third parties or their
            associated companies. No conditions within these terms of use
            confers on you any licence or right under any trademarks, names or
            logos.
          </p>
          <p>
            We are not liable for failure to perform any of our obligations
            under these terms of use caused by matters beyond our reasonable
            control.
          </p>
          <p>
            Unless, by exemption permitted within these terms of use, you may
            not copy, reproduce, redistribute, download, republish, transmit,
            display, adapt, alter, create derivative works from or otherwise
            extract or re-utilise any of the contents of the Website/App. In
            particular, you must not cache any of the contents for access by
            third parties, nor mirror or frame any of the content of the
            Website/App nor incorporate it into another Website/App without our
            express written permission.
          </p>
          <p>
            Any information belonging to the General Traffic Directorate which
            is made available on the Website/App (“Traffic Department
            Information”) is made available for your personal use only whilst
            visiting the Website/App. You agree not to use the General Traffic
            Directorate Information for any commercial or business purposes. You
            may print one copy of the General Traffic Directorate Information
            for future reference, but you must not otherwise reproduce or
            redistribute the General Traffic Directorate Information (whether on
            its own or as a component part of any other product, service or
            material). You agree that you will not use the General Traffic
            Directorate Information for any unlawful purposes or in a way which
            infringes the intellectual property rights of the General Traffic
            Directorate or any other third party.
          </p>
          <strong>Liability</strong>
          <p>
            The material displayed on our Website/App (“Content”) is provided
            without any guarantees, conditions or warranties as to its accuracy
            and as otherwise excluded in these terms of use. You understand and
            acknowledge that in using the Website/App, you may be exposed to
            Content that is factually inaccurate, offensive, indecent,
            fraudulent or otherwise objectionable to you. You agree to waive,
            and hereby do waive, any legal or equitable rights or remedies you
            have or may have against us with respect to any such Content.
          </p>
          <p>
            To the extent permitted by law, we, other members of our group of
            companies and third parties connected to us hereby expressly
            exclude:
          </p>
          <p>
            all conditions, warranties and other terms which might otherwise be
            implied by statute and
          </p>
          <p>
            any liability for a direct, indirect or consequential loss or damage
            incurred by any user in connection with our Website/App or in
            connection with the use, inability to use, or results of the use of
            our Website/App, any Website/App linked to it and any materials
            posted on it, including without limitation any liability for:
          </p>
          <ul>
            <li>loss of income or revenue.</li>
            <li>loss of business.</li>
            <li>loss of profits or contracts.</li>
            <li>loss of anticipated savings.</li>
            <li>loss of data.</li>
            <li>loss of goodwill.</li>
            <li>wasted management or office time; and</li>
            <li>
              for any other loss or damage of any kind, howsoever arising and
              whether caused by tort (including negligence), breach of contract
              or otherwise, even if foreseeable.
            </li>
          </ul>
          <p>
            This does not affect our liability for death or personal injury
            arising from our negligence, nor our liability for fraudulent
            misrepresentation or misrepresentation as to a fundamental matter,
            nor any other liability which cannot be excluded or limited under
            applicable law.
          </p>
          <p>
            We will need to carry out routine maintenance and servicing of our
            servers and equipment from time to time. While we will seek to keep
            disruption to a minimum, we cannot guarantee that the Website/App
            will be continuously available on-line. We therefore provide the
            Website/App on an "as is" and "as available" basis.
          </p>
          <p>
            We make no warranty that the Website/App (or associated
            websites/Apps) is free from computer viruses or any other malicious
            or impairing computer programs. It is your responsibility to ensure
            that you use appropriate virus checking software.
          </p>
          <p>
            We are not liable for any failure to perform any of our obligations
            under these terms of use caused by matters beyond our reasonable
            control.
          </p>
          <p>Links to non-Ako Sayara sites</p>
          <p>
            The Website/App contains hypertext links to websites that are not
            operated by us or by our associated companies. We do not control
            such websites and are not responsible for their content. Our
            inclusion of hypertext links to such websites does not imply any
            endorsement of the material contained on the websites or of the
            owners.
          </p>
          <p>Links to the Website</p>
          <p>
            You may establish a hypertext link to the home page of the Website,
            without the need for our written consent, provided there is no
            implied endorsement or sponsorship of you, your company or your
            website by us.
          </p>
          <p>Registration details</p>
          <p>
            To gain access to certain services on the Website/App you will need
            to register (free of charge). As part of the registration process,
            you will be given a username and password. You agree that the
            information supplied with your registration will be truthful,
            accurate and complete. You also agree that you will not attempt to
            register in the name of any other individual nor will you adopt any
            username which we deem to be offensive. All personal information
            supplied by you as part of the registration process will be
            protected in line with our Privacy Policy.
          </p>
          <strong>Vehicle Checks</strong>
          <br />
          <br />
          <p style={{ fontWeight: "bold" }}>General</p>
          <p>
            We reserve the right to assign or subcontract any or all of our
            rights and obligations under these terms of use to a third party. If
            we exercise this right, we shall name the assignee or subcontractor
            on the Website/App and you will be entitled to terminate the account
            within 5 working days. You may not without our prior written consent
            assign or dispose of any rights or obligations arising under these
            terms of use.
          </p>
          <p>
            These terms of use of access together with our Privacy Policy
            contain the entire agreement and understanding between you and us
            relating to the Website/App, and supersede any and all prior
            agreements, arrangements, statements and understandings, except for
            any fraud or fraudulent representation by either party. We exclude
            all representations and warranties relating to access to use of the
            Website/App, whether they are statutory or otherwise, as far as is
            possible by law.
          </p>
          <p>
            Our services are not suitable for those under sixteen years of age
            and, as such, by using our Website/App you represent that you are at
            least sixteen years of age. Persons who are at least sixteen years
            of age but under the age of eighteen years of age may only use the
            Website/App with legal parental or guardian consent.
          </p>
          <p>
            If any of these terms of use shall be held to be invalid or
            unenforceable, it shall not affect the enforceability of any of the
            remaining provisions.
          </p>
          <p>
            These terms of use shall be governed by and in accordance with Iraqi
            law and subject to the exclusive jurisdiction of the Iraqi Courts.
          </p>
          <p>
            If you do not agree to obey these terms of use you must stop using
            the Website/App immediately.
          </p>
          <p style={{ fontWeight: "bold" }}>Correspondence</p>
          <p>
            All correspondence relating to the Website/App or these terms of use
            should be sent to:
          </p>
          <p>Albazi Globe Limited,</p>
          <p>Ako Sayara</p>
          <p>
            Sooq Almaskof,
            <br />
            Samawah,
          </p>
          <p>
            Iraq
            <br />
            Email: enquiries@akosayara.com <br />
            Telephone: 0000000000
          </p>
          <p>Opening hours:</p>
          <p>Monday - Friday: 9am - 5.30pm</p>
          <p>
            Registered office: Albaz Globe Limited, Ako Sayara, Sooq Almaskof, ,
            Samawah,
          </p>
          <p>Iraq.</p>
          <p>Registered in Iraq with company number 902136743.</p>
          <br /><br />
          <p
            style={{
              fontSize: "30px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Terms and Conditions{" "}
          </p>
          <strong>Advertising terms &amp; conditions</strong>
          <p>
            Please read these Conditions carefully as they govern the provision
            of a digital advertising package by us to you. We may modify these
            Conditions from time to time and you should therefore ensure that
            you review these Conditions regularly.
          </p>
          <p>
            In these Conditions, "we", "us" or "our" means Albaz Globe Limited.
            When we refer to Ako Sayara this includes the website located at
            www.akosayara.com (“Ako Sayara Website"), and all other Ako Sayara
            platforms including mobiles and tablets and the Ako Sayara Apps
            owned and operated by us.
          </p>
          <p>
            These Conditions apply to any adverts that are placed, renewed
            and/or amended until that advert expires or is amended or renewed.
          </p>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>1. OUR CONTRACT WITH YOU</strong>
              <ul>
                <li>
                  These Conditions apply to any advert for the sale of a vehicle
                  that is placed on Ako Sayara by a private advertiser and
                  together with all other policies and terms posted on the Ako
                  Sayara Website/App, set out the terms on which we enter into a
                  contract with you to provide you with access to our platforms
                  and services.
                </li>
                <li>
                  By placing an advert with us, you are deemed to have accepted
                  these Conditions.
                </li>
                <li>
                  Each time we accept a request from you to place an advert on
                  Ako Sayara, a separate severable contract is formed between
                  you and us which is subject to these Conditions.
                </li>
              </ul>
            </li>
            <li>
              <strong>2. PLACING AN ADVERT</strong>
              <ul>
                <li>
                  When you place an advert on Ako Sayara, you will be given a
                  username and password which you are responsible for keeping
                  secure. Ako Sayara will not be liable to you if you fail to
                  keep your username and password safe. Unless caused by our
                  negligence, we will not be liable for any loss you might
                  suffer if a third party gains unauthorised access to your
                  account. You agree that the information supplied with your
                  registration will be truthful, accurate and complete. You also
                  agree that you will not attempt to register in the name of any
                  other individual nor will you adopt any username which is
                  offensive. All personal information supplied by you as part of
                  the registration process will be protected and used in
                  accordance with the terms of our Privacy Policy.
                </li>
                <li>
                  If we accept your advert, whilst we cannot give any guarantees
                  regarding timescales, we aim to process and publish adverts
                  supplied online within 24 hours of receipt.
                </li>
                <li>
                  Adverts placed may appear on all Ako Sayara platforms,
                  including desktop, mobile and tablet, and all third party’s
                  social media platforms on which Ako Sayara are active, and may
                  also feature on third party partner sites. To find out more
                  about our partner sites, visit Sell Your Car.
                </li>
                <li>
                  As soon as your advert has been accepted for publication, a
                  confirmation email will be sent to you explaining that the
                  advert has gone live on Ako Sayara and you will be given a
                  unique identification number in respect of your advert. In the
                  event that an advert has been rejected for publication, we
                  will attempt to contact you to explain why your advert has
                  been rejected. In the event that we reject the advert prior to
                  publishing, no charge will be incurred by you. Please note
                  that no contract will be deemed to exist between you and us
                  until we have sent you a confirmation email.
                </li>
              </ul>
            </li>
            <li>
              <strong>3. ADVERT CONTENT GUIDELINES</strong>
              <ul>
                <li>
                  Each advert may only feature one vehicle. If you have more
                  than one vehicle to sell, you will need to place a separate
                  advert for each vehicle.
                </li>
                <li>
                  We are only able to display one advert for each vehicle at any
                  one time.
                </li>
                <li>
                  We may accept photographs and videos (in a format to be
                  determined by Ako Sayara) of vehicles for use in adverts.
                  However, we reserve the right in our sole discretion not to
                  include a photograph and/or video in an advert.
                </li>
                <li>
                  You are solely responsible for uploading any photographs and
                  videos which form part of your advert unless agreed otherwise
                  by us. We shall have no responsibility for the quality of any
                  photographs and/or videos you upload or provide to us.
                </li>
                <li>
                  Photographs and videos which form part of the adverts must:-
                  <ul>
                    <li>show only one vehicle.</li>
                    <li>
                      be accurate in their description of the vehicle being
                      displayed.
                    </li>
                    <li>
                      comply with all applicable legislation and regulation.
                    </li>
                    <li>
                      not contain any material that is subject to third party
                      intellectual property rights or other third party’s
                      proprietary rights (including rights of privacy or rights
                      of publicity);
                    </li>
                    <li>
                      not be defamatory or derogatory of any person or
                      organisation.
                    </li>
                    <li>
                      not contain any strong profanity or inappropriate content
                      or be obscene, offensive, threatening, inflammatory,
                      abusive, hateful, racist, anti-religious or sexually
                      graphic.
                    </li>
                    <li>
                      not be in any way discriminatory or promote discrimination
                      of any kind.
                    </li>
                    <li>not be pornographic or violent.</li>
                    <li>
                      not disclose any personal information in respect of any
                      individual (and personal information for the purpose of
                      these Conditions is any information that can be used to
                      identify a person or contact a person by any means);
                    </li>
                    <li>
                      not impersonate any person or misrepresent your identity
                      or affiliation with any person or company.
                    </li>
                    <li>
                      not be unlawful or advocate, promote, incite any third
                      party to commit or assist any unlawful or criminal act or
                      contain any description or mention of illegal activity.
                    </li>
                    <li>
                      not contain a statement which is direct or indirect
                      encouragement or other inducement to the commission,
                      preparation or instigation of acts of terrorism.
                    </li>
                    <li>
                      not, other than solely in relation to the sale of the
                      vehicle which is the subject of your advert, contain any
                      advertising, promote any goods or services, drive traffic
                      to another Website/App or notify users of a promotion not
                      approved by Ako Sayara or otherwise attempt to make a
                      commercial gain.
                    </li>
                    <li>not contain any spam.</li>
                    <li>
                      not contain text or audio not relevant to the vehicle
                      being described.
                    </li>

                    <li>not be in any language other than English.</li>
                    <li>
                      not be irrelevant or off-topic and not contain a url or
                      link to any other site except the Advertiser’s own
                      website/App. Ako Sayara may check such photographs and
                      videos which form part of the adverts to ensure that such
                      content meets the above requirements.
                    </li>
                    <li>
                      If a photograph and/or video in your advert does not meet
                      the requirements set out in Condition 3.5, we reserve the
                      right not to publish the photograph and/or video (as
                      applicable), to remove the relevant photograph and/or
                      video (as applicable) from the advert or to remove the
                      advert in its entirety from Ako Sayara.
                    </li>
                    <li>
                      If you need to amend your advert, you can use the online
                      edit functionality featured on Ako Sayara.
                    </li>
                    <li>
                  You warrant that:
                  <ul>
                    <li>you are a resident in Iraq.</li>
                    <li>
                      you are not acting in the course of trade or business.
                    </li>
                    <li>
                      you have the right to sell the vehicle in the advert.
                    </li>
                    <li>
                      the advert you are placing does not relate to a vehicle
                      that is a non-declared write-off.
                    </li>
                    <li>
                      the information contained within the advert is a true and
                      accurate representation of the vehicle to which it
                      relates, and any statement made in relation to the mileage
                      of the vehicle being advertised is correct.
                    </li>
                    <li>
                      the advert has consistent and accurate pricing throughout
                      (in both the asking price section and the description) and
                      the correct mileage has been provided.
                    </li>
                    <li>
                      you will not act fraudulently and will deliver any vehicle
                      advertised that is lawfully bought from you.
                    </li>
                    <li>
                      all copy, information and materials that you provide to us
                      is complete, accurate, legal, non-defamatory, decent,
                      honest and truthful and complies with the Iraqi Code of
                      Advertising Practice.
                    </li>
                    <li>
                      the publication of the advert by us will not breach any
                      contract, infringe any third party’s intellectual property
                      rights or other rights; render us or any other company in
                      our group liable to any proceedings; and/or cause a
                      detriment to our reputation and/or the reputation of any
                      company within our group;
                    </li>
                    <li>
                      you will not upload any files to Ako Sayara that contain a
                      virus and/ or corrupted data; and
                    </li>
                    <li>
                      the advert complies with these Conditions including
                      without limitation Conditions 3.1 to 3.5.
                    </li>
                  </ul>
                </li>
                <li>
                  If you refer to a vehicle as being ‘Sold’ in any part of an
                  advert, we shall automatically remove such advert from Ako
                  Sayara.
                </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <ul style={{listStyleType: 'none'}}>
            <li>
              <strong>4. OUR RIGHTS AND OBLIGATIONS</strong>
              <ul>
                <li>
                  We shall provide the advertising service with reasonable care
                  and skill in a professional and timely manner. However, you
                  acknowledge and accept that it is technically impossible to
                  guarantee that Ako Sayara will be continuously available
                  online or to guarantee the corruption free or error free
                  transfer of an advert to Ako Sayara.
                </li>
                <li>
                  From time to time, we will need to carry out maintenance on
                  our equipment and systems, and we shall use our reasonable
                  endeavours to ensure that, in so doing, minimum disruption to
                  any adverts is caused.
                </li>
                <li>
                  We will use reasonable endeavours to remedy faults or errors
                  on Ako Sayara as soon as possible. [You acknowledge and accept
                  that once a fault or error has been reported to us, it may
                  take up to 48 hours for us to investigate and rectify the
                  problem].
                </li>
                <li>
                  We do not monitor or control and shall not be responsible for
                  the content of your advert which you agree is your sole
                  responsibility. Accordingly, you shall be responsible for any
                  losses, expenses or other costs incurred by us caused by an
                  untrue statement or inaccurate or unlawful content.
                </li>
                <li>
                  We reserve the right to refuse to publish any advert without
                  reason and to classify, edit and delete an advert at our sole
                  discretion so as to: comply with legal or moral obligations
                  placed on you or us; avoid infringing third party rights, the
                  Iraqi Code of Advertising Practice and all other codes under
                  the general supervision of the Advertising Standards Authority
                  and/ or any relevant legislation; respond to any complaints;
                  correct typographical errors or technical inaccuracies that
                  may appear from time to time; and for any other technical and/
                  or quality reasons. Where possible, we will attempt to contact
                  you to inform you of these changes prior to publication and/or
                  edit.
                </li>
                <li>
                  We may at any time remove any or all of the materials from the
                  advert which in our opinion are unlawful or are in breach of
                  these Conditions.
                </li>
                <li>
                  We may at any time vary the technical specifications of Ako
                  Sayara (or any parts of them) for operational reasons.
                </li>
              </ul>
            </li>
            <li>
              <strong>5. CHARGES AND PAYMENT</strong>
              <ul>
                <li>
                  The cost of placing an advert on Ako Sayara depends on the
                  advertising package you select, and all prices are quoted on
                  Ako Sayara. All prices are inclusive of Tax.
                </li>
                <li>
                  We have the right to change our advertising rates at any time
                  and for any reason, but any revised rates shall not apply to
                  any adverts placed with us before publication of the revised
                  rates.
                </li>
                <li>All adverts must be pre-paid prior to publication.</li>
                <li>Payment for adverts is made online through Ako Sayara. </li>
                <li>
                  Where payment is taken through Ako Sayara, we will refer you
                  to a dedicated commerce provider who will take payment via a
                  secure sever (SSL) connection to ensure the safety of your
                  payment online. Payment can be made using Visa, MasterCard or
                  Maestro. Any cards used for payment must be registered in Iraq
                  to be accepted. Whilst every effort is made to ensure the
                  safety of your credit card transaction, we cannot and do not
                  accept liability for any loss or damage incurred as a result
                  of using the secure payment mechanism.
                </li>
              </ul>
            </li>
            <li>
              <strong>6. INTELLECTUAL PROPERTY RIGHTS</strong>
              <ul>
                <li>
                  In respect of any materials which you supply to us
                  ("Materials"), you grant us a non-exclusive, irrevocable,
                  perpetual and royalty free licence to use such Materials for
                  any purpose. You confirm that neither you nor any other person
                  will assert any moral rights in or relating to the Materials
                  referred to above against us or any third party.
                </li>
                <li>
                  You agree that all intellectual property rights (including,
                  without limitation, copyright, trademarks, database rights and
                  design rights (whether registered or not)) in any copy, text,
                  artwork, photographs, videos or other materials which we have
                  created and/or in any way altered for you in connection with
                  the advert shall belong to us absolutely.
                </li>
              </ul>
            </li>
            <li>
              <strong>7. OUR RIGHT TO TERMINATE THE CONTRACT</strong>
              <ul>
                <li>
                  We shall be entitled to terminate the contract with you and
                  remove an advert at any time. We will provide you with notice
                  of the removal of your advert. In such circumstances we shall
                  provide a refund to you for the unexpired part of the
                  advertising term unless we have terminated the contract
                  pursuant to Condition 7.2 below.
                </li>
                <li>
                  We may immediately suspend or terminate the contract and
                  remove an advert where:
                  <ul>
                    <li>you breach a term of these Conditions.</li>
                    <li>you breach any of the provisions of Condition 3.8.</li>
                    <li>
                      we reasonably suspect that you are not complying with the
                      provisions of Condition 3.8.
                    </li>
                    <li>
                      you place an advert at our bargain rate but then price
                      your vehicle above the vehicle price limit to which the
                      bargain rate applies.
                    </li>
                    <li>
                      You place an advert for one vehicle and then modify
                      adverts details to sell multiple vehicles.
                    </li>
                    <li>
                      You are a trade seller masquerading as a private seller.
                    </li>
                    <li>
                      if in our reasonable opinion, we have reason to believe
                      that you have been abusive to our staff, or we receive a
                      complaint from a third party regarding your conduct in the
                      sale of an advertised vehicle; and/ or
                    </li>
                    <li>
                      any competent law enforcement or compliance authority
                      instructs, advises or makes a recommendation to us to take
                      down any of your adverts and/or suspend the delivery of
                      services to you.
                    </li>
                  </ul>
                </li>
                <li>
                  If we remove an advert in accordance with Condition 7.2 above,
                  you will not be entitled to any refund for the advert in
                  question.
                </li>
              </ul>
            </li>
            <li>
              <strong>8. YOUR RIGHT TO CANCEL</strong>
              <ul>
                <li>
                  Please note that the digital advertising package provided by
                  Ako Sayara is a bespoke package, tailored to your requirements
                  and is created instantaneously. By submitting your advert to
                  us for publication, you consent to us publishing your advert,
                  which begins on the day that we notify you that your advert
                  has gone live on Ako Sayara.
                </li>
                <li>
                  By submitting your advert for publication, you acknowledge
                  that your right to cancel the contract at no cost to you will
                  be lost.
                </li>
                <li>
                  An advert can be cancelled at any time by you, for example to
                  avoid unwanted responses but no refunds will be given.
                </li>
              </ul>
            </li>
            <li>
              <strong>9. LIABILITY</strong>
              <ul>
                <li>
                  Nothing in these Conditions shall exclude or restrict our
                  liability for death or personal injury caused by our
                  negligence, for fraudulent misrepresentation and/or for any
                  other liability which we are not permitted to exclude or limit
                  by law.
                </li>
                <li>
                  If we fail to comply with these Conditions, we are responsible
                  for loss or damage that you suffer as a foreseeable result of
                  our breach of the Conditions or our negligence, but we are not
                  responsible for any loss or damage that is not foreseeable or
                  for any loss or damage not caused by any breach on our part.
                  Loss or damage would be foreseeable if it were an obvious
                  consequence of our breach or if it were contemplated by you
                  and us at the time the advert was published, and the contract
                  was formed.
                </li>
                <li>
                  We will not be liable for any loss or damage caused by a
                  distributed denial of service attack, viruses, malicious or
                  impairing computer programs or other technologically harmful
                  material that may affect Ako Sayara, your advert and/ or may
                  infect your computer equipment, computer programs, data or
                  other proprietary material due to your use of Ako Sayara or
                  your downloading of any material posted on it or on any
                  website/app linked to it.
                </li>
                <li>
                  We cannot guarantee that by placing an advert on Ako Sayara,
                  you will sell the vehicle in question, and we do not commit to
                  finding a buyer for you. Our role is solely to provide a
                  platform on which you can advertise your vehicle for sale.
                </li>
                <li>
                  If, as a result of placing an advert, you are successful in
                  selling your vehicle, Ako Sayara will not be connected in any
                  way to that sale and will not be liable or responsible under
                  any contract for the sale of your vehicle which you might
                  enter into with a third party.
                </li>
                <li>
                  You agree that any questions and complaints regarding a
                  vehicle advertised in your advert will be your responsibility
                  only and that we do not have any liability in this regard.
                </li>
                <li>
                  In the event of any error or omission in an advert, we may at
                  our sole discretion either amend the relevant part of the
                  advert or refund or adjust the charges. No refund, amendment
                  or adjustment to the charges will be made where in our sole
                  opinion, the error or omission does not materially detract
                  from the advert or where it arises as a result of incorrect or
                  inadequate information provided by you.
                </li>
                <li>
                  We shall not be liable to you for any delay in performing
                  and/or any failure to perform our obligations to you if the
                  delay or failure is due to any event outside of our reasonable
                  control (including without limitation, any act of God, fire,
                  flood, explosion, storm, epidemic or natural disaster, strike,
                  terrorist attack or threat of terrorist attack, infrastructure
                  failure or power failure, denial of service attack, failure of
                  public or private telecommunications networks).
                </li>
                <li>Nothing in these Conditions affects your legal rights.</li>
              </ul>
            </li>
            <li>
              <strong>10. DATA PROTECTION AND SECURITY</strong>
              <ul>
                <li>
                  We take security very seriously and provide advice on the Ako
                  Sayara Website/ App on steps you can take to ensure your
                  safety and security when selling your vehicle.{" "}
                </li>
                <li>
                  By submitting an advert to us, you are requesting that your
                  advert appears on Ako Sayara. This means that the advert and
                  all information contained within it can potentially be viewed
                  by all persons with internet access throughout the world. You
                  consent to the publication of your data in this way.
                </li>
                <li>
                  We use Customer to Customer messaging service to protect the
                  privacy of our customers.
                </li>
                <li>
                  Customer to Customer messaging disguises your email address
                  from persons viewing your advert by enabling them to contact
                  you through a link on your advert without seeing your true
                  email address. This is not optional. By placing an advert with
                  us, you acknowledge and agree that your communication with
                  persons viewing your advert will be via consent to the use of
                  our Customer-to-Customer messaging service.
                </li>
                <li>
                  You acknowledge and agree that by placing an advert with us,
                  you will provide us with, and we will collect from you certain
                  personal data relating to you (e.g. name, address and contact
                  details). This personal data will be processed in accordance
                  with our Privacy Policy and may be used for a number of
                  purposes (as further detailed in our Privacy Policy) and which
                  may include the prevention and detection of fraud, assisting
                  with police investigations and/or enquiries and/or complying
                  with statutory and regulatory obligations. Our Privacy Policy
                  can be found on our website, and we advise you to read it. For
                  the privacy policy, visit Privacy Policy.
                </li>
              </ul>
            </li>
            <li>
              <strong>11. INFORMATION ABOUT US AND HOW TO CONTACT US</strong>
              <ul>
                <li>
                  Ako Sayara is the trading name of Albaz Globe Limited, a
                  company registered in Iraq with registered company number
                  902136743, and whose registered office address is Albaz Globe
                  Limited, Ako Sayara, Sooq Almaskof, Samawah, Iraq
                </li>
                <li>
                  If you wish to contact us in writing, you can do so by:
                  <ul>
                    <li>e-mailing enquiries@akosayara.com; or</li>
                  </ul>
                </li>
                <li>
                  If you would like to speak to us on the telephone to discuss
                  an advert that you have placed, to make a complaint about your
                  advert or for general enquiries, please call 00000000. Please
                  check Ako Sayara Website/App to find our current opening
                  hours.
                </li>
                <li>
                  Please note that if we have to contact you or give you notice
                  in writing, we may do so by e-mail.
                </li>
              </ul>
            </li>
            <li>
              <strong>12. GENERAL</strong>
              <ul>
                <li>
                  We may transfer our rights and obligations under these
                  Conditions to a third party and we will always notify you in
                  writing if this happens. This will not affect your rights or
                  our obligations under these Conditions.
                </li>
                <li>
                  Each of the paragraphs of these Conditions operates
                  separately. If any court or relevant authority decides that
                  any of them or any part of them are unlawful, the remaining
                  paragraphs or parts will remain in full force and effect.
                </li>
                <li>
                  If we fail to insist that you perform any of your obligations
                  under these Conditions, or if we delay in doing so, that will
                  not mean that we have waived our rights against you and will
                  not mean that you do not have to comply with those
                  obligations. If we do waive an obligation by you, we will only
                  do so in writing and that will not mean that we will
                  automatically waive any later default by you.
                </li>
                <li>
                  These Conditions form a contract between you and us. No other
                  person shall have any rights to enforce them.
                </li>
                <li>
                  These Conditions are governed and construed by Iraqi law. This
                  means that the contract formed between you and us and any
                  dispute or claim arising out of it will be governed by Iraqi
                  law. You and we both agree that the Courts of Iraq will have
                  non-exclusive jurisdiction. This means that a claim could be
                  brought in Iraq. Iraqi law will however, apply in all cases.
                </li>
              </ul>
            </li>
            <li>
              <strong>13. TRADE ADVERTISING</strong>
              <ul>
                <li>
                  These Conditions relate to the advertising of vehicles by
                  PRIVATE SELLERS ONLY. If you are a trader and you would like
                  to advertise vehicles for sale on the Ako Sayara Website/App,
                  please visit Ako Sayara portal.
                </li>
                <li> section of www.akosayara.com</li>
                <li>
                  If you are a trader acting in the course of your business, you
                  are not permitted to place any advert as a private advert
                  and/or otherwise pass yourself off as a private seller. All
                  adverts placed by people who seek to sell goods in the course
                  of business to make the fact clear. This requirement applies
                  whether the advertiser is acting on his own behalf, or for
                  some other person. Customers should be able to tell whether an
                  advert relates to a sale by a trade or private seller. It is
                  the responsibility of the advertiser to comply with the
                  Regulations.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    If you are a trader acting in the course of your business,
                    it is an offence, for which you may be prosecuted, not to
                    advertise the fact that you are a trader.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            We may, in our sole discretion, remove any advert that has been
            placed as private which we reasonably believe has been placed by a
            trader acting in the course of a business. In such circumstances,
            you will not be entitled to any refund for the advert in question
            and you may be charged an administration fee by us.
            <br />
            <br />
            <br />
            <br />
            <strong>Legal Disclaimer:</strong>
          </p>
          <p>
            Ako Sayara is provided on an "as is" and "as available" basis. We
            make no representations or warranties of any kind, express or
            implied, as to the operation of Ako Sayara or the information,
            content, materials, or products included on Ako Sayara. You
            expressly agree that your use of Ako Sayara is at your sole risk.
          </p>
          <p>
            To the full extent permissible by applicable law, we disclaim all
            warranties, express or implied, including, but not limited to,
            implied warranties of merchantability and fitness for a particular
            purpose. We do not warrant that Ako Sayara, its servers, or e-mail
            sent from Ako Sayara are free of viruses or other harmful
            components. We will not be liable for any damages of any kind
            arising from the use of Ako Sayara, including, but not limited to
            direct, indirect, incidental, punitive, and consequential damages.
          </p>
          <p>
            We make every effort to ensure that the information on Ako Sayara is
            accurate and up-to-date. However, we do not warrant or guarantee
            that the information provided is error-free, complete, or current.
            We reserve the right to correct any errors, inaccuracies, or
            omissions and to change or update information at any time without
            prior notice.
          </p>
          <p>
            The cars displayed on Ako Sayara are subject to availability and may
            be sold or removed from sale at any time. We reserve the right to
            make changes to pricing, features, specifications, and other
            information about the cars without prior notice. The images of the
            cars on Ako Sayara may not reflect the actual color, features, or
            condition of the cars.
          </p>
          <p>
            The use of Ako Sayara and the sale of cars through Ako Sayara are
            subject to applicable laws and regulations. We make no
            representation that the cars sold through Ako Sayara comply with all
            applicable laws and regulations in your jurisdiction. It is your
            responsibility to ensure that the purchase and use of the cars
            comply with all applicable laws and regulations.
          </p>
          <p>
            <strong>Terms of Responsibility:</strong>
          </p>
          <p>
            By using Ako Sayara, you agree that you are solely responsible for
            your use of Ako Sayara and any information or content that you
            submit or transmit through Ako Sayara. You agree that you will not
            use Ako Sayara for any unlawful purpose or in any way that violates
            any applicable law or regulation.
          </p>
          <p>
            You agree to indemnify and hold harmless Ako Sayara and its
            affiliates, directors, officers, employees, and agents from and
            against any claims, actions, demands, damages, costs, liabilities,
            and expenses, including reasonable attorneys' fees, arising out of
            or in connection with your use of Ako Sayara or any information or
            content that you submit or transmit through Ako Sayara.
          </p>
          <p>
            You agree that Ako Sayara is not responsible for any errors,
            inaccuracies, or omissions in any{" "}
          </p>
          <p>
            information or content that you submit or transmit through Ako
            Sayara. You acknowledge and agree that Ako Sayara is not responsible
            for any loss or damage that may arise from your use of Ako Sayara or
            any information or content that you submit or transmit through Ako
            Sayara.
          </p>
          <p>
            You acknowledge and agree that Ako Sayara is not responsible for any
            transactions that you enter into through Ako Sayara. We are not a
            party to any transaction between buyers and sellers of cars, and we
            have no control over the quality, safety, or legality of the cars
            advertised on
          </p>
        </p> */}

        <p
        dangerouslySetInnerHTML={{
          __html: marked.parse(data ? (data?.[lang]) : t("Please wait...")),
        }}
        ></p>

        {/* {data.map((item) => {
          return (
            <div className="container">
              <h4>{item.title[lang]}</h4>
              <p className="text-center">{item.description[lang]}</p>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
