import React, { useEffect, useState } from "react";
import Payments from "../../Payments";
import { useSelector } from "react-redux";
import { apiManager } from "../../../../api/connectAPI";

//stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublishableKey, stripePublishableTestKey } from "../../../../utils/constants";

export default function ThiredStep({ onClick, start, details }) {
  const language = useSelector((state) => state.auth.language);
  const [options, setOptions] = useState({
    locale:
      language === "English"
        ? "en"
        : language === "Arabic"
        ? "ar"
        : language === "Kurdish"
        ? "ku"
        : "en",
  });
  // const [stripePromise, setStripePromise] = useState();
  const [loading, setLoading] = useState(false);

  // const stripePromise = loadStripe(stripePublishableTestKey);
  const stripePromise = loadStripe(stripePublishableKey);

  useEffect(() => {
    setLoading(true);
    apiManager("POST", `user/createPaymentIntent?lang=en`, {
      adId: details?._id,
      planId: details?.pack?._id,
    })
      .then((res) => {
        if (res?.status) {
          setOptions({ ...options, clientSecret: res?.data?.paymentIntent });
          // const strPromise = loadStripe(res?.data?.publishableKey);
          // setStripePromise(strPromise);
        } else {
          console.log(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // const options =
  // {
  //   // passing the client secret obtained from the server
  //   // clientSecret: "pi_3OiCKZSBYRGRwjpe0JMKU17m_secret_R1KKGbA4Z8mGrzYwRzBbGS9L7",
  //   locale:
  //     language === "English"
  //       ? "en"
  //       : language === "Arabic"
  //       ? "ar"
  //       : language === "Kurdish"
  //       ? "ku"
  //       : "en",
  // }

  console.log({ adId: details?._id, planId: details?.pack?._id });

  if (loading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Elements stripe={stripePromise} options={options}>
        <Payments onClick={onClick} start={start} details={details} />
      </Elements>
    );
  }
}
