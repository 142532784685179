import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useSelector } from "react-redux";
import Carview from "../CarView";
import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import { camelToNormal, isImageOrVideo } from "../../../utils/constants";
import { cities } from "../../../data/content";
import {
  addOrUpdateAd,
  fetchFilteredSearchResults,
  fetchSingleCar,
} from "../../../Reducer/api";
import Media from "../Media";
import { useState } from "react";
import moment from "moment";
export default function UploadImages({
  data,
  setPage,
  onClick,
  back,
  edit,
  next,
  page,
  setData,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const [base64, setBase64] = useState([]);
  const [inputFile, setInputFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [InputFileError, setInputFileError] = useState();
  //upload document fucntion for adding new adverts only

  const getAdData = (id) => {
    fetchSingleCar({ id: id }).then((res) => {
      const dataTmp = res?.data?.[0];
      setData(dataTmp);
      setBase64(
        dataTmp?.uploads
          ? dataTmp?.uploads.map((v) => {
              // if (isImageOrVideo(v?.s3URL) === "video") {
              //   return "assets/images/default-image-thumbnail.webp";
              // }
              return v?.s3URL;
            })
          : []
      );
      setUploadedFiles(
        dataTmp?.uploads ? dataTmp?.uploads.map((v) => v.s3URL) : []
      );
    });
  };
  useEffect(() => {
    if (!uploading && data?._id) {
      getAdData(data?._id);
    }
  }, [uploading]);
  console.log(data);
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);


  const handlePostAd = async () => {
    if (!uploadedFiles?.length) {
      setInputFileError(t("File is required"));
      return;
    }
    if (uploadedFiles.length < 4) {
      setInputFileError(t("Please upload minimum four images"));
      return
    }
    // setSubmit(true);
    setData({ ...data, uploads: uploadedFiles });
    setPage(next);
    setBase64([]);
    setUploadedFiles([]);
    // const res = await addOrUpdateAd(data, page, "POST");
    // if (res) onClick(2, next, data);
  };

  function additionalTask() {
    console.log('wascfad',uploadedFiles);
    if (uploadedFiles.length < 3) {
      setInputFileError(t("Please upload minimum four images"));
    } else {
      setInputFileError("");
    }
  }
  return (
    <>
      <h6 className="back">
        <a
          onClick={() => {
            setPage(back);
          }}
        >
          <img src="assets/images/backbt.png" /> {t("Go back")}
        </a>
      </h6>

      {/* <h3 class="Advert">{t("Your Advert")}</h3> */}
      <div class="se">
        <div class="row">
          <div className="col">
            <Media
              setInputFileError={setInputFileError}
              inputFile={inputFile}
              //   register={register}
              InputFileError={InputFileError}
              base64={base64}
              setBase64={setBase64}
              setInputFile={setInputFile}
              // handleUploadDocument={handleUploadDocument}
              uploading={uploading}
              setUploading={setUploading}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              adId={data?._id}
              storedList={data?.uploads}
              type={6}
              callFunc={additionalTask}
            />
          </div>
        </div>
      </div>
      <div class="text-center">
        <Button
          inlineStyle={{
            backgroundColor:
              uploadedFiles?.length < 4 || uploading ? "gray" : null,
            cursor:
              uploadedFiles?.length < 4 || uploading
                ? "not-allowed"
                : "default",
          }}
          style="save_continue disabled"
          onClick={() => {
            handlePostAd();
          }}
          type="submit"
          // disabled={!uploadedFiles?.length < 4 || uploading}
          title={uploading ? t("Please wait...") : t("Save & Continue")}
        />
      </div>
    </>
  );
}
