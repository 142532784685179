import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { sideBar } from "./content";
import { rentalContent } from "./rentalContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchDefaultFilterData } from "../../../Reducer/searchFilterSlice";
import SearchItem from "../../Tools/SearchItem";
import Pagination from "../../Tools/Pagination";
import SortBy from "../../Tools/SortBy";
import $ from "jquery";

import FilterSideBar from "../../Tools/FilterSideBar";
import { title } from "../../../data/content";
import { fetchSaved } from "../../../Reducer/savedSlice";
import ListItem from "../../Tools/ListItem";
import SelectOptions from "../../Tools/SelctOptions";
import { itemLimit } from "../../../utils/constants";
import sortBy from "../../../data/sortBy.json";
import { getModels } from "../../../Reducer/api";
import ReactSelectCustom from "../../Tools/ReactSelectCustom";

export default function SearchResult({ page, back }) {
  const { t, i18n } = useTranslation();
  document.title = t("Search Results");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const sidebar = useSelector((state) => state.searching.sideBar);
  const search = useSelector((state) => state.searching.search);
  const totalPage = useSelector((state) => state.searching.totalPage);
  const loading = useSelector((state) => state.searching.loading);
  const error = useSelector((state) => state.searching.error);
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = totalPage;
  const SideBarDatSet = page == "normal" ? sideBar : rentalContent;
  const [params] = useSearchParams();
  const brandId = params.get("brandId");
  const ownerId = params.get("ownerId");
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [sortVal, setSortVal] = useState({
    sort: "",
    order: "",
  });
  const [selectedContent, setSelectedContent] = useState(
    ownerId ? "normal" : page
  );
  //if user changes page from tab switching (cars or rentals) ps: only available on viewing dealer cars
  // useEffect(() => {
  //   if(ownerId) {
  //     dispatch(fetchDefaultFilterData({ page: selectedContent, currentPage: currentPage+1, limit: itemLimit, brandId: brandId, ownerId: ownerId }));
  //     setCurrentPage(0)
  //   }
  // }, [selectedContent, ownerId]);
  const defaultValues = {
    city: [],
    make: "",
    model: "",
    price: {
      min: "",
      max: "",
      finance: false,
    },
    rentType: "daily",
    year: {
      min: "",
      max: "",
    },
    km: {
      min: "",
      max: "",
    },
    regional: [],
    condition: [],
    paint: [],
    colour: [],
    gearbox: [],
    fuel: [],
    bodyType: [],
    size: {
      min: "",
      max: "",
    },
    power: {
      min: "",
      max: "",
    },
    cylinders: {
      min: "",
      max: "",
    },
    doors: {
      min: "",
      max: "",
    },
    seats: {
      min: "",
      max: "",
    },
    brandNew: false,
  };
  // console.log(location?.state)

  const [allFilters, setAllFilters] = useState({
    ...defaultValues,
    ...location?.state?.filters,
  });
  const resetFilters = () => {
    setAllFilters(defaultValues);
  };

  //if user changes page from navigation (cars or rentals)
  // useEffect(() => {
  //   if (!ownerId) {
  //     dispatch(
  //       fetchDefaultFilterData({
  //         page: page,
  //         currentPage: currentPage + 1,
  //         limit: itemLimit,
  //         brandId: brandId,
  //         ownerId: ownerId,
  //         filters: allFilters,
  //       })
  //     );
  //     setCurrentPage(0);
  //     setSelectedContent(page);
  //   }
  // }, [page, ownerId, allFilters]);

  //pagination page update content (page number wise)
  useEffect(() => {
    dispatch(
      fetchDefaultFilterData({
        page: selectedContent,
        currentPage: currentPage + 1,
        limit: itemLimit,
        brandId: brandId,
        ownerId: ownerId,
        filters: allFilters,
        sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
      })
    );
  }, [currentPage, page, ownerId, allFilters, sortVal]);

  // useEffect(() => {
  //   dispatch(
  //     fetchDefaultFilterData({
  //       page: selectedContent,
  //       currentPage: currentPage + 1,
  //       limit: itemLimit,
  //       brandId: brandId,
  //       ownerId: ownerId,
  //       filters: allFilters,
  //     })
  //   );
  // }, [allFilters]);

  //list of saved cars of current user
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(fetchSaved());
      // navigate("/login");
      // return;
    }
  }, [dispatch]);

  //pagination page change (page number wise)
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleClick = (a, b, c) => {
    $(".switch-button-case").removeClass("left");
    $("#" + b).addClass("left");
  };

  //pagination previous button
  const previous = () => {
    let page = currentPage;
    if (page > 0) setCurrentPage(page - 1);
  };

  //pagination next button
  const next = () => {
    let page = currentPage;
    if (page < pageCount - 1) setCurrentPage(page + 1);
  };

  useEffect(() => {
    console.log(allFilters);
  }, [allFilters]);

  const [models, setModels] = useState(null);
  useEffect(() => {
    console.log(allFilters.make);
    if (allFilters.make) {
      setModels([]);
      getModels(allFilters.make, true, "", page).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }
  }, [allFilters.make]);
  return (
    <section class="advance_search car_search_results">
      <div class="container">
        <h6 className="back back2">
          <a onClick={() => navigate(-1)}>
            <img src="assets/images/backbt.png" /> {t("Go back")}
          </a>
        </h6>
        <div class="advance_search_in">
          <FilterSideBar
            dataset={SideBarDatSet}
            // data={sidebar}
            handleClickevent={handleClick}
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            page={page}
            models={models}
            resetFilters={resetFilters}
          />
          <SortBy
            data={sortBy.sortData}
            setSelectedContent={setSelectedContent}
            selectedContent={selectedContent}
            ownerId={ownerId}
            previous={previous}
            next={next}
            sortVal={sortVal}
            setSortVal={setSortVal}
          />
          <h4 className="d-lg-none d-flex align-items-center justify-content-between">
            <span
              class="d-flex align-items-center"
              style={{ fontSize: "16px" }}
            >
              <img src="assets/images/SortBy.png" height={50} alt="img" />
              <ReactSelectCustom
                values={sortBy.sortData}
                width={"180px"}
                // boxWidth={'140px'}
                placeholder={""}
                selectedOption={t("Relevance")}
                onChange={(e) =>
                  setSortVal({
                    sort: e.item.sort,
                    order: e.item.order,
                  })
                }
              />
            </span>
            <span
              class="filter_ic"
              style={{ cursor: "pointer" }}
              onClick={() => $(".sidebar").addClass("main")}
            >
              <img src="assets/images/filter_ic.png" />
              {t("Filters")}
            </span>
          </h4>
          {/* {ownerId && 
          <div className="d-flex justify-content-center d-lg-none">
            <div className="row" style={{ width: "60%" }}>
              <span className="login">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <ListItem
                    innerhtmlType={"button"}
                    key={0}
                    btnType={"button"}
                    onClick={() => setSelectedContent("normal")}
                    style={"nav-item"}
                    buttonStyle={
                      selectedContent == "normal"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    buttonTitle={t("cars")}
                  ></ListItem>
                  <ListItem
                    innerhtmlType={"button"}
                    key={1}
                    btnType={"button"}
                    onClick={() => setSelectedContent("rental")}
                    style={"nav-item"}
                    buttonStyle={
                      selectedContent == "rental"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    buttonTitle={t("rentals")}
                  ></ListItem>
                </ul>
              </span>
            </div>
          </div>          
          } */}

          {loading ? (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "100vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              class="tab-content"
              id="myTabContent"
              style={{ minHeight: "120vh" }}
            >
              {/* <LoginForm type={selectedOption}/> */}
              {search && search.length > 0 ? (
                search.map((item, index) => {
                  return <SearchItem data={item} key={index} />;
                })
              ) : (
                <h1
                  style={{
                    minHeight: "100vh",
                    textAlign: "center",
                    fontSize: "20px",
                    marginTop: "25vh",
                  }}
                >
                  {"0 ".concat(t("Cars found"))}
                </h1>
              )}
            </div>
          )}
          <nav aria-label="Page navigation example">
            <Pagination
              style={`pagination float-end mb-5 mt-4 ${
                lang !== "en" && "paginationButtonAr"
              }`}
              next={"Next"}
              forcePage={currentPage}
              previous={"Previous"}
              pageCount={pageCount}
              rangeDisplayed={3}
              handlePageClick={handlePageClick}
            />
          </nav>
        </div>
      </div>
    </section>
  );
}
