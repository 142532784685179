import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlans } from "../../../Reducer/planSlice";
import PlanCard from "../PlansCard";
import { generateFreeInvoice } from "../../../Reducer/api";

export default function Plan({ onClick, start, details, page }) {
  if (!details) details = {};

  const { t, i18n } = useTranslation();
  const plans = useSelector((state) => state.plans.plans);
  const submitting = useSelector((state) => state.plans.submitting);
  const dispatch = useDispatch();
  const [pack, setPack] = useState({});
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    dispatch(fetchPlans({ page: page, type: userType }));
  }, [dispatch]);

  if (Object.keys(pack).length !== 0) {
    details = { ...details, pack: pack };
    if (userType === "dealer") {
      generateFreeInvoice({
        adId: details?._id,
        planId: pack?._id,
      })
      // .then(res => {
      //   if(res.status) {
      //   }
      // })
      onClick(4, start, details);
    } else {
      onClick?.(3, start, details);
    }
  }

  return (
    <>
      <h6 class="back">
        <Button
          onClick={() => {
            onClick?.(1, start, details);
          }}
          title={
            <>
              <img src="assets/images/backbt.png" /> {t("Go back")}
            </>
          }
        />
      </h6>
      <div class="set basic">
        <div class="row ad_plan">
          {plans && plans?.plans?.length > 0 ? (
            [...plans?.plans]?.reverse()?.map((item, index) => {
              return (
                <PlanCard
                  item={item}
                  onClick={setPack}
                  key={index}
                  details={details}
                  page={page}
                />
              );
            })
          ) : submitting == true ? (
            <p>{t("Please wait...")}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
