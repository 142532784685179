import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import moment from "moment";
import { camelToNormal, handleDownload } from "../../../utils/constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getInvoiceUrl } from "../../../Reducer/api";
export default function Datatable({ data, column, title, submitting }) {
  const { t } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const handleDownloadTransaction = (id) => {
    getInvoiceUrl(id).then((res) => {
      if (res?.status) {
        handleDownload(res?.data);
      }
    });
  };
  return (
    <div>
      <div class="close_amount tansactions">
        <h3>
          {t(title)}
          {/* <form>
            <input
              class="form-control me-2"
              type="search"
              placeholder={t("Search")}
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              <img src="../assets/images/search.webp" alt="icon" />
            </button>
          </form> */}
        </h3>
        <div class="table-responsive">
          {data?.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  {column &&
                    column.map((item, key) => {
                      return <th key={key}>{t(item.name)}</th>;
                    })}
                </tr>
              </thead>
              {submitting ? (
                <div className="d-flex justify-content-center mt-5 pt-5">
                  <div className="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <tbody>
                  {data &&
                    data.map((item, key1) => {
                      return (
                        <tr key={key1}>
                          <td>
                            {moment
                              .unix(item?.paymentDetails?.created)
                              .format("DD/MM/YY")}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDownloadTransaction(item?._id);
                              // handleDownload(item?.invoices?.[lang]?.path)
                            }}
                          >
                            {item?.invoiceNumber ? (
                              <>
                                {item?.invoiceNumber}
                                <Icon
                                  icon="ic:round-download"
                                  fontSize={13}
                                  style={{ color: "#4C7EE8" }}
                                />
                              </>
                            ) : (
                              "--"
                            )}{" "}
                          </td>
                          <td>
                            <span class="paid ">
                              {t(camelToNormal(item?.status.toLowerCase()))}
                            </span>
                          </td>
                          {/* <td class="down">{item?.actions || '--'}</td> */}
                          <td>{item?.isFreeInvoice ? t("Free for dealers") : `$${item?.amount}` || "--" }</td>
                          {/* <td>{"$" + item?.amount || "--"}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          ) : (
            <h4 className="py-2 text-center">{t("No transactions found")}</h4>
          )}
        </div>
      </div>
    </div>
  );
}
