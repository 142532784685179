import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ObjectedList from "./objected";
import IndexedList from "./indexed";
import {
  camelToNormal,
  getFeatureName,
  getNameFromVal,
} from "../../../utils/constants";
import { translateInTwoLanguages } from "../../../Reducer/api";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

export default function CarDetailModel({ data, title }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  data = [data?.basicDetails, data?.specifications, data?.features];

  const setTitle = (i) => {
    switch (i) {
      case 0:
        return t("Basic Details");
      case 1:
        return t("Specifications");
      case 2:
        return t("Features");
    }
  };

  const defElse = {
    en: "",
    ar: "",
    ku: "",
  };
  const [elseState, setElse] = useState(defElse);
  useEffect(() => {
    if (data?.[2]?.anyThingElse) {
      translateInTwoLanguages("en", data?.[2]?.anyThingElse).then((res) => {
        setElse(res);
      });
    } else {
      setElse(defElse);
    }
  }, []);
  console.log(data[2], elseState)
  const makeValue = (name, value) => {
    // if (name === "color") {
    //   return (
    //     <li className="list-group-item d-flex justify-content-between align-items-center">
    //       {"Colour"}
    //       <span
    //         className="float-end w-25"
    //         style={{
    //           backgroundColor: value,
    //           height: "25px",
    //           border: "2px solid lightgrey",
    //           borderRadius: "5px",
    //         }}
    //       ></span>
    //     </li>
    //   );
    // }
    if (name === "carDescription") {
      // return (
      //   <div className="row px-3 w-100 pt-2">
      //     <div className="col-sm-4">
      //       <h6>{t('Car Description')}</h6>
      //     </div>
      //     <div className="col-sm-8">
      //       <p>{value?.[lang]}</p>
      //     </div>
      //   </div>
      // );
      return null;
    }
    if (name === "anyThingElse") {
      // const translatedValue = await translateInTwoLanguages(lang, value)
      // console.log(translatedValue)
      return null
      if (elseState?.[lang]?.length == 0) {
        return null;
      }
      return (
        <div className="row px-3 w-100 pt-2">
          <div className="col-sm-4">
            <h6>{t("Anything else")}</h6>
          </div>
          <div className="col-sm-8">
            <p>{elseState?.[lang]}</p>
          </div>
        </div>
      );
    }
    if (name === "engineSize" || name === "enginePower") {
      if (!value) return null;
      const obj = name.replace("engine", "").toLowerCase();
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {t(getFeatureName(name))}
          <span className="text-end">
            {value ? getNameFromVal(obj, value, lang) : "--"}
          </span>
        </li>
      );
    }
    const type = typeof value;
    switch (type) {
      case "boolean":
        return value ? (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}{" "}
            <span>
              <img
                src="assets/images/check.webp"
                className="text-end"
                height={15}
                alt=""
              />
            </span>
          </li>
        ) : null;
      case "string":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">
              {value && value?.length > 0 ? t(value) : "--"}
            </span>
          </li>
        );
      case "number":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">{value ? value : "--"}</span>
          </li>
        );
      case "object":
        if (!value?.[lang]) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {t(getFeatureName(name))}
            <span className="text-end">
              {value?.[lang] ? t(value?.[lang]) : "--"}
            </span>
          </li>
        );
    }
  };

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };

  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog specifications">
        <div class="modal-content" style={{ paddingTop: "18px" }}>
          <div class="modal-header d-flex justify-content-end">
            <a class="btnclose mb-3" data-bs-dismiss="modal" aria-label="Close">
              <img
                src="../assets/images/close_img.png"
                alt="icon"
                height={22}
              />
            </a>
          </div>
          <div class="modal-body">
            {/* <div class="accordion accordion-flush" id="accordionExample">
              {data &&
                data.map((item, index) => {
                  return (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`headingOne${index}`}>
                        <button
                          class={`accordion-button`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${index}`}
                          // aria-expanded="true"
                          aria-controls={`#collapseOne${index}`}
                        >
                          <img class="plus" src="../assets/images/plus.png" />
                          <img
                            style={{ display: "none" }}
                            src="../assets/images/cls.png"
                          />{" "}
                          {setTitle(index)}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${index}`}
                        class={`accordion-collapse collapse`}
                        // aria-labelledby={`headingOne${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <ul className="d-none d-sm-block w-100">
                          {Object.keys(item).map((val) => {
                            return makeValue(val, item[val]);
                          })}
                        </ul>
                        <div
                          className="d-block d-sm-none list-group"
                          style={{ listStyleType: "none" }}
                        >
                          {Object.keys(item).map((val) => {
                            return makeValue(val, item[val]);
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div> */}

            <div className="specifications">
              <Accordion open={accordion} toggle={toggleAccordion}>
                {data &&
                  data?.map((item, index) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={index + 1}>
                          <img class="plus" src="../assets/images/plus.png" />
                          <img
                            style={{ display: "none" }}
                            src="../assets/images/cls.png"
                          />
                          {setTitle(index)}
                        </AccordionHeader>
                        <AccordionBody accordionId={index + 1}>
                          <ul className="d-none d-sm-block w-100">
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </ul>
                          <div
                            className="d-block d-sm-none list-group"
                            style={{ listStyleType: "none" }}
                          >
                            {Object.keys(item).map((val) => {
                              let v = item?.[val];
                              if (v === "true") v = true;
                              if (v === "false") v = false;
                              const valu = makeValue(val, v);
                              return valu;
                            })}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
