import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import ListItem from "../../ListItem";
import TextAreaForm from "../../TextAreaForm";
import SelectForm from "../../SelectForm";
import FormInput from "../../FormInput";

export default function Features({data, register, errors, selected, setSelected}) {
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  return (
    <div class="other_div other_div2">
      <h3>{t("Select Available Features")}</h3>
      <ul class="Select">
        {upload_list?.features?.map((item, key) => {
          return (
            <ListItem
              key={key}
              innerhtmlType={"html"}
              value={
                <>
                  <FormInput
                    intype={"checkbox"}
                    checked={data?.properties?.features?.[item.value]}
                    event={register}
                    name={item.value}
                  />
                  {t(item.name)}
                </>
              }
            ></ListItem>
          );
        })}
      </ul>
      <div class="row">
        <div class="col-sm-12">
          <h5>{t("Have something else?")}</h5>
          <div class="ssp form-control">
            <TagsInput
              className="form-control"
              value={selected}
              name="feature"
              onChange={setSelected}
              onBlur={(e) => {
                const tag = e?.target?.value;
                if (!selected.includes(tag) && tag.length) {
                  setSelected([...selected, tag]);
                  document.getElementsByClassName("rti--input")[0].value = "";
                }
              }}
              placeHolder={t("Type here")}
            />
            {errors.feature && <p>{errors.feature.message}</p>}
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>{t("Car Description")}</label>
            <TextAreaForm
              style="form-control"
              event={register}
              value={data?.properties?.features?.carDescription?.[lang2]}
              name={"description"}
              placeholder={t("Write your description")}
            />
            {errors.description && <p>{errors.description.message}</p>}
          </div>
        </div>
        <div class="form-group mt-4">
          <h5>{t("Brand new")}*</h5>
          <SelectForm
            style={"form-control"}
            selectedOption={data?.properties?.specifications?.seats}
            event={register}
            name={"brandNew"}
            values={upload_list.brandNew ? upload_list.brandNew : []}
          />
          {errors.brandNew && <p className="ms-3">{errors.brandNew.message}</p>}
        </div>
      </div>
    </div>
  );
}
