import React, { Component, useEffect, useState } from "react";
import ViewedCar from "./ViewedCar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PerfomanceFetch } from "../../../../../Reducer/perfomanceSlice";
import Chart from "react-apexcharts";
import AppexChart from "./Chart";
import Datepickers from "../../../../Tools/Datepicker";
import SelectOptions from "../../../../Tools/SelctOptions";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { title } from "../../../../../data/content";
import moment from "moment";
export default function Perfomance() {
  const data = useSelector((state) => state.performance.performanceArr);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch(PerfomanceFetch({ type: "weekly" }));
  }, [dispatch]);

  const submitting = false;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onError = (errors) => {
    //  console.log(errors);
  };

  const onSubmitform = async (data) => {
    if (submitting == false) {
      let formData = new FormData();
      toast.success("Loading.............");
    }
  };
  document.title = title.Performance[i18n.language];
  const menu = [
    { name: t("Weekly"), value: "weekly" },
    { name: t("Monthly"), value: "monthly" },
    { name: t("Yearly"), value: "yearly" },
  ];
  const handleChangeType = (e) => {
    const type = e.target.value;
    dispatch(
      PerfomanceFetch({
        type: type,
        month: moment(new Date()).format("YYYY-MM"),
      })
    );
  };
  const [totalViews, setTotalViews] = useState("");
  return (
    <>
      <div class="Perfomance">
        <div class="st">
          <h4>
            <span>{totalViews}</span>
            {t("Total car views")}
          </h4>
        </div>
        <div class="set_main pb-0">
          <div className="row">
            <div class="col-8 fs-3 fw-semibold">{t("Shop views")} </div>
            <div className="col-4">
              <select
                className="form-control"
                onChange={handleChangeType}
                // style={{width: '200px'}}
              >
                {menu.map((item) => {
                  return <option value={item.value}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
          <AppexChart data={data} />
        </div>
        <div class="set_main ">
          <h3 class="a3">{t("Most Viewed Cars")}</h3>
          <div class="">
            <ViewedCar setTotalViews={setTotalViews} />
          </div>
        </div>
      </div>
    </>
  );
}
