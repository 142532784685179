import React from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "./BannerSection";
import PlaceSection from "./PlaceSection";
import SearchedSection from "./SearchedSection";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import Follow from "./Follow";
import BrandNewSection from "./BrandNewSection";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import RecentlyCarView from "../../Tools/RecentCarView";
import { useEffect } from "react";
import { useState } from "react";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import WelcomeModal from "../../Tools/WecomeModal";
import { useDispatch, useSelector } from "react-redux";
import { myprofilefetch } from "../../../Reducer/myprofileSlice";
export default function Home({ page }) {
  const { t, i18n } = useTranslation();
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const [carTypes, setCarTypes] = useState([]);
  const dispatch = useDispatch();
  const [selectedCarType, setSelectedCarType] = useState(null);
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  document.title = title.Home[i18n.language];
  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };

  useEffect(() => {
    getCarTypes();
    if (localStorage.getItem("token")) {
      dispatch(myprofilefetch());
    }
  }, []);
  useEffect(() => {
    if (profileData?.userType) {
      if (profileData?.userType === "dealer") {
        if (
          !profileData?.additionalInfo?.dealerWorkingHours ||
          profileData?.emailVerified == false ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      } else {
        if (
          !profileData?.isWelcomeMailSent ||
          profileData?.emailVerified == false ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      }
    }
    // if ((profileData?.userType && (profileData?.userType === "dealer" && !profileData?.additionalInfo?.dealerWorkingHours) || (profileData?.userType !== "dealer" && !profileData?.isWelcomeMailSent))) {
    //   openModal();
    // }
  }, [profileData]);

  const [makes, setMakes] = useState([]);
  useEffect(() => {
    getMake(true, 200, "", page).then((res) => {
      // res = res?.map((v) => ({ label: v, value: v }));
      let data = {
        name: t("Any"),
        value: "Any",
      };
      setMakes([data, ...res?.makes]);
    });
  }, []);
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  console.log(profileData);
  return (
    <>
      <BannerSection
        carTypes={carTypes}
        makes={makes}
        setMakes={setMakes}
        page={page}
      />

      <BrandNewSection rtl={rtl} page={page} />

      <BrandSection
        brandTitle={t("Browse by brand")}
        page={page}
        makes={makes}
      />
      <PlaceSection page={page} />
      <SearchedSection
        rtl={rtl}
        page={page}
        carTypes={carTypes}
        setCarTypes={setCarTypes}
        selectedCarType={selectedCarType}
        setSelectedCarType={setSelectedCarType}
      />
      <ArticlesSection rtl={rtl} page={page} />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed car deals"}
      />
      <Follow />
      <WelcomeModal
        modal={modal}
        closeModal={closeModal}
        userType={profileData?.userType}
      />
    </>
  );
}
