import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
import Details from "./details";
import FormInput from "../../Tools/FormInput";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { clearStatus, make_payment } from "../../../Reducer/paymentSlice";
import { errorSms } from "../../../data/content";
import { toast } from "react-toastify";

//stripe
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { apiManager } from "../../../api/connectAPI";

export default function Payments({ onClick, start, details }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const submitting = useSelector((state) => state.payment.submitting);
  const payment = useSelector((state) => state.payment.payment);
  const error = useSelector((state) => state.payment.error);
  const success = useSelector((state) => state.payment.success);
  const require = t("This field is requried");
  const [errorMsg, setErrorMsg] = useState('') // custom erorr message
  const schema = Yup.object().shape({
    card_number: Yup.string()
      .required(require)
      .min(8, "Must be exactly 8 digits")
      .max(19, "Must be exactly 19 digits"),
    card_name: Yup.string()
      .required(require)
      .min(5, "Must be exactly 5 digits")
      .max(10, "Must be exactly 10 digits"),
    expiry: Yup.string().required(require),
    code: Yup.string()
      .required(require)
      .min(3, "Must be exactly 3 digits")
      .max(3, "Must be exactly 3 digits"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  // const onSubmit = async (data) => {
  //   if (submitting == false) {
  //     let formData = new FormData();
  //     formData.append("card_number", data.chasis);
  //     formData.append("card_name", data.card_name);
  //     formData.append("expiry", data.expiry);
  //     formData.append("code", data.code);
  //     formData.append("type", "sell");
  //     formData.append("car_id", details.id);
  //     formData.append("packId", details.pack._id);
  //     dispatch(make_payment(formData));
  //   }
  // };

  if (success == true) {
    toast.success(t(payment.message));
    dispatch(clearStatus());
    onClick(4, start, details);
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }

  const stripe = useStripe();
  let elements = useElements();

  const [paymentLoad, setPaymentLoad] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setPaymentLoad(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setPaymentLoad(false);
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        // return_url: "https://example.com/order/123/complete",
      },
    });

    console.log(result);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMsg(t('card_declined'))
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setPaymentLoad(false);
      if (result?.paymentIntent?.status === "succeeded") {
        apiManager('POST', `user/confirmPayment?lang=${lang}`, {adId: details?._id}).then(res => {
          if(res.status) {
            onClick(4, start, details);
          } else {
            console.log(res)
          }
        })
        .catch(err => {
          console.log(err)
        })
      }
    }
  };
  const [show, setShow] = useState("payment");

  return (
    <div className="container min-vh-100">
      {/* <img class="w-100" src="../assets/images/check_my_car.png" alt="img" /> */}

      <div>
        <h6 class="back">
          <Button
            // onClick={() => {
            //   setStep(1);
            // }}
            title={
              <>
                <img src="assets/images/backbt.png" /> {t("Go back")}
              </>
            }
          />
        </h6>
        <div class="enquery">
          <h5>
            {t("Selected Plan")}
            <span  className="">
              <span style={{width: 'fit-content'}} className="fw-semibold packName">
                {t(details?.pack?.name)}
              </span>
              <span style={{width: 'fit-content', marginTop: "0px"}} className=" packName text-wrap">
                {t(details?.pack?.description)}
              </span>
            </span>
            <a
              class="cngplan cngplanNew"
              onClick={() => {
                onClick(2, start, details);
              }}
            >
              {t("Change Plan")}
              <img src="assets/images/arrow-narrow-right.png" alt="img" />
            </a>
          </h5>

          {show === "payment" ? (
            <>
              <div class="row">
                {/* {details?.pack && <Details details={details?.pack} />} */}
                <div className="col-lg-6">
                  {/* <div className="st">
                
              </div> */}
                  <Details details={details} />
                </div>
                <div class="col-lg-6">
                  <div class="st">
                    {/* <h4>
                  {t("Debit/Credit Cards")}{" "}
                  <span>
                    <img src="assets/images/card1.png" alt="img" />
                    <img src="assets/images/card2.png" alt="img" />
                    <img src="assets/images/card3.png" alt="img" />
                  </span>
                </h4> */}
                    <form class="mb-0" onSubmit={onSubmit}>
                      <div class="row">
                        {/* <div class="col-12">
                      <label>{t("Card Number")}*</label>
                      <FormInput
                        intype={"number"}
                        min={8}
                        max={19}
                        event={register}
                        name={"card_number"}
                        placeholder={t("Card Number")}
                      />
                      {errors.card_number && (
                        <p>{errors.card_number.message}</p>
                      )}
                    </div>
                    <div class="col-12">
                      <label>{t("Name on Card")}*</label>
                      <FormInput
                        intype={"text"}
                        min={5}
                        max={10}
                        event={register}
                        name={"card_name"}
                        placeholder={t("Name on Card")}
                      />
                      {errors.card_name && <p>{errors.card_name.message}</p>}
                    </div>
                    <div class="col-4 col-sm-3">
                      <label>{t("Expiry")}*</label>
                      <FormInput
                        intype={"text"}
                        event={register}
                        name={"expiry"}
                        placeholder={t("00/00")}
                      />
                      {errors.expiry && <p>{errors.expiry.message}</p>}
                    </div>
                    <div class="col-4 col-sm-3">
                      <label>{t("CVV Code")}*</label>
                      <FormInput
                        intype={"number"}
                        min={3}
                        max={3}
                        event={register}
                        name={"code"}
                        placeholder={t("Code")}
                      />
                      {errors.code && <p>{errors.code.message}</p>}
                    </div> */}

                        <PaymentElement />

                        <div class="col-12">
                          <Button
                            type="submit"
                            disabled={paymentLoad}
                            title={
                              !paymentLoad
                                ? t("Make Payment")
                                : t("Please wait...")
                            }
                          />
                        </div>
                      </div>
                      <p className="helperText text-center">{errorMsg}</p>
                    </form>
                  </div>
                  {/* <form onSubmit={handleSubmitCard}>
                <PaymentElement />
                <div class="col-12">
                  <Button
                    type="submit"
                    title={
                      submitting == false
                        ? t("Make Payment")
                        : t("Please wait...")
                    }
                  />
                </div>
              </form> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center py-5 my-5">
                <h4>Payment successfull</h4>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

// return (
//   <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
// <div className="d-flex justify-content-center">
//   <div class="spinner-grow" role="status">
//     <span class="visually-hidden">Loading...</span>
//   </div>
// </div>
//   </div>
// );
