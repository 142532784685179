import React, { useEffect, useState } from "react";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FirstForm from "./FirstForm";
import SecondForm from "./SecondForm";
import { fromDetail } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import {
  getAdFiltersList,
  getAllModels,
  getMake,
  getModels,
} from "../../../Reducer/api";
import { bannerBuy } from "../../../utils/paths";

export default function BannerSection({ carTypes, makes, setMakes, page }) {
  const { t, i18n } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.fromsdata);
  const loading = useSelector((state) => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fromDetail());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    bodyType: [],
    price: {
      min: "",
      max: "",
    },
  }
  
  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState(defaultValues);
  useEffect(() => {
    setModels([]);
    if (filterData.make) {
      getModels(filterData.make, true, "", page).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }

    // else {
    //   getAllModels().then((res) => {
    //     res = res?.map((v) => ({ label: v, value: v }));
    //     setModels(res);
    //   });
    // }
  }, [filterData.make]);

  useEffect(() => console.log(filterData), [filterData]);

  const resetFilters = () => {
    setFilterData(defaultValues);
    setModels([]);
  };

  const [cities, setCities] = useState([]);
  useEffect(() => {
    getAdFiltersList(page)
      .then((res) => {
        console.log(res?.cities);
        setCities(res?.cities);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section
      class="banner"
      style={{
        backgroundImage: `url(${
          bannerBuy ? bannerBuy : "/assets/images/bannerhome.jpg"
        })`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="setform" style={{overflow: 'unset'}}>
              <ul class="nav nav-tabs d-flex usedNewTabs" id="myTab" role="tablist">
                <ListItem
                  key={1}
                  style={"nav-item"}
                  innerhtmlType={"modalButton"}
                  innerData={{
                    class: "nav-link active",
                    id: "tab1-tab",
                    toggle: "tab",
                    target: "#tab1",
                    type: "button",
                    role: "tab",
                    controls: "tab1",
                    selected: "true",
                    title: t("Used cars"),
                  }}
                />
                <ListItem
                  key={2}
                  style={"nav-item"}
                  innerhtmlType={"modalButton"}
                  innerData={{
                    class: "nav-link",
                    id: "tab2-tab",
                    toggle: "tab",
                    target: "#tab2",
                    type: "button",
                    role: "tab",
                    controls: "tab2",
                    selected: "false",
                    title: t("New cars"),
                  }}
                />
              </ul>
              <div class="tab-content" id="myTabContent">
                <FirstForm
                  data={detail}
                  carTypes={carTypes}
                  makes={makes}
                  setMakes={setMakes}
                  models={models}
                  setModels={setModels}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  cities={cities}
                />
                <SecondForm
                  data={detail}
                  carTypes={carTypes}
                  makes={makes}
                  setMakes={setMakes}
                  models={models}
                  setModels={setModels}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  cities={cities}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2 style={{ textShadow: "1px 1px 5px gray" }}>
              {t("Find your perfect car")}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
