import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveModal from "./RemoveModal";
import SoldModal from "./SoldModal.";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { removeDealerAd } from "../../../Reducer/api";
import { useEffect } from "react";
import {
  formatPrice,
  isImageOrVideo,
  makeTagText,
} from "../../../utils/constants";
import { Modal } from "reactstrap";
export default function MyvehicleTool({ data, refresh }) {
  // const [data, setData] = useState(props?.data);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const [modal, setModal] = useState(false);
  const [modaltype, setModalType] = useState("");
  const [modalData, setModalData] = useState();
  const openModal = (type, data) => {
    setModalType(type);
    setModalData(data);
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  const getPrice = (currency, data) => {
    if(data?.adTypeName?.en === 'Sell') {
      return formatPrice(lang, data?.askPrice);
    } else {
      return formatPrice(lang, data?.askPrice).concat("/"+t('Day'))
    }
  };
  const goToCar = (id, type, sold) => {
    console.log(sold);
    if (type === "Sell") {
      navigate(`/SingleCar?adId=${id}${sold ? "&sold=true" : ""}`);
    } else {
      navigate(`/SingleRental?adId=${id}${sold ? "&sold=true" : ""}`);
    }
  };
  const getBackgroundColor = (name) => {
    switch (name) {
      case "Sold":
        return "#2da84f";
      case "Active":
        return "#f0ad4e";
      default:
        return "#e44e4e";
    }
  };

  return (
    <>
      {data &&
        data.map((item, key) => {
          const tagText = makeTagText(item, lang);
          return (
            <div class="col-lg-4 col-md-6 col-xs-12 mb-2" key={key}>
              <div class="item">
                <div
                  class="set"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    padding: " 0px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: lang === "en" ? "0px" : "unset",
                      left: lang === "en" ? "unset" : "0px",
                      top: "0px",
                      fontSize: "11px",
                      padding: "3px 10px 3px 10px",
                      backgroundColor: getBackgroundColor(
                        item?.statusName?.["en"]
                      ),
                      color: "white",
                      borderRadius:
                        lang === "en" ? "0px 8px 0px 8px" : "8px 0px 8px 0px",
                    }}
                  >
                    {t(item?.statusName?.[lang])}
                  </div>
                  <div class="img" style={{cursor: 'pointer'}} onClick={() => goToCar(item?._id, item?.adTypeName?.["en"], item?.sold)}>
                    {isImageOrVideo(item?.uploads.map((v) => v.s3URL)?.[0]) ===
                    "video" ? (
                      <video
                        class="w-100"
                        height={180}
                        style={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          marginBottom: "3px",
                        }}
                      >
                        <source src={item?.uploads.map((v) => v.s3URL)?.[0]} />
                      </video>
                    ) : (
                      <img
                        src={
                          item?.uploads.map((v) => v.s3URL)?.[0]
                            ? item?.uploads.map((v) => v.s3URL)?.[0]
                            : "/assets/images/notFound.png"
                        }
                        alt="blog1"
                        class="w-100"
                        height={180}
                        style={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      goToCar(item?._id, item?.adTypeName?.["en"], item?.sold)
                    }
                  >
                    <span className="d-flex justify-content-between">
                      <h5
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                        {t(item?.properties?.basicDetails?.model?.[lang])}
                      </h5>

                      <div className={``}>
                        <div
                          className="float-end1 bg-success text-light fw-semibold text-center"
                          style={{
                            borderRadius: "3px",
                            padding:
                              lang === "en"
                                ? "1px 0px 1px 0px"
                                : "1px 0px 3px 0px",
                            fontSize: "13px",
                            width: "40px",
                          }}
                        >
                          {item?.adTypeName?.[lang]}
                        </div>
                        {/* <div
                          className="float-end1 bg-warning text-light fw-semibold text-center"
                          style={{
                            borderRadius: "3px",
                            padding:
                              lang === "en"
                                ? "1px 0px 1px 0px"
                                : "1px 0px 3px 0px",
                            fontSize: "13px",
                            width: "40px",
                          }}
                        >
                          {item?.adTypeName?.[lang]}
                        </div> */}
                      </div>
                    </span>
                    <p
                      className="fs-6"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        height: "45px",
                      }}
                    >
                      {tagText}
                    </p>
                    <p>
                      {item?.properties?.specifications?.fuelType?.[lang]
                        ? t(item?.properties?.specifications?.fuelType?.[lang])
                        : "--"}
                    </p>
                    <h6>{getPrice(item?.currency, item)} </h6>
                  </div>

                  {!item?.sold ? (
                    <>
                      <div class="edit_div">
                        <a
                          className="edit"
                          onClick={() =>
                            navigate("/MyAccount/Edit_car", { state: item })
                          }
                        >
                          {t("Edit Vehicle")}
                        </a>
                        <a
                          class="trash"
                          // data-bs-toggle="modal"
                          // data-bs-target={`#exampleModalRemove${item?._id}`}
                          onClick={() => openModal("remove", item)}
                        >
                          <img src="../assets/images/trash.png" alt="img" />
                        </a>
                      </div>
                      <a
                        onClick={() => openModal("sold", item)}
                        class="markbtn"
                        // data-bs-toggle="modal"
                        // data-bs-target={`#exampleModal${item?._id}`}
                      >
                        {t("Mark as Sold")}
                      </a>
                    </>
                  ) : (
                    // <a
                    //   // onClick={() => setModal(true)}
                    //   class="btn btn-success w-100 text-light mt-2 h-100"
                    //   style={{ cursor: "default" }}
                    //   // data-bs-toggle="modal"
                    //   // data-bs-target={`#exampleModal${item?._id}`}
                    // >
                    //   {t("Sold")}
                    // </a>
                    <div
                      className="w-100 rounded-1 text-light d-flex align-items-center justify-content-center"
                      style={{
                        height: "43px",
                        marginTop: "15px",
                        backgroundColor: "#2DA84F",
                      }}
                    >
                      {t("Sold")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      <Modal isOpen={modal} toggle={handleCloseModal} centered>
        {modaltype === "sold" && (
          <SoldModal
            handleCloseModal={handleCloseModal}
            modalData={modalData}
            refresh={refresh}
          ></SoldModal>
        )}
        {modaltype === "remove" && (
          <RemoveModal
            refresh={refresh}
            handleCloseModal={handleCloseModal}
            modalData={modalData}
          />
        )}
      </Modal>
    </>
  );
}
