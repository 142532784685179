import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../SelctOptions";
import ListItem from "../ListItem";
import i18n from "../../../i18n";
import { useEffect } from "react";
import { useState } from "react";
export default function Specification({
  data,
  allFilters,
  setAllFilters,
  showFilteredValues,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const handleChange = (e) => {
    const value = e.target.name;
    if (e.target.checked) {
      setAllFilters({ ...allFilters, colour: [...allFilters?.colour, value] });
    } else {
      setAllFilters({
        ...allFilters,
        colour: allFilters?.colour?.filter((v) => v !== value),
      });
    }
  };

  console.log(data);

  return (
    <div class="set fuel1">
      <h4>{t("Specifications")}</h4>
      <label class="colorr">{t("Colour")}</label>
      <ul
        class="fuel-new  row"
        style={{ listStyleType: "none", padding: "0px" }}
      >
        {data?.colour?.map((item, index) => {
          return (
            <ListItem
              innerhtmlType={"html"}
              style={"col-md-2 col-6"}
              inlineStyle={{
                height: "fit-content",
              }}
              value={
                <>
                  <input
                    type="checkbox"
                    id={`colorBox${index}`}
                    onChange={handleChange}
                    checked={allFilters?.colour?.includes(
                      item?.name?.[i18n.language] ||
                        item?.name?.[lang] ||
                        item?.name
                    )}
                    name={
                      item?.name?.[i18n.language] ||
                      item?.name?.[lang] ||
                      item?.name
                    }
                  />{" "}
                  <label htmlFor={`colorBox${index}`}>
                    {t(item?.name?.[i18n.language]) ||
                      t(item?.name?.[lang]) ||
                      t(item?.name)}
                  </label>
                </>
              }
            />
          );
        })}
      </ul>
      <div class="row">
        <div class="col-sm-12">
          <label>{t("Doors")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.doors?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                doors: { min: e.target.value, max: e.target.value },
              })
            }
            name={"doors"}
            values={data?.doors?.max?.data}
          />
        </div>

        <div class="col-sm-6">
          <label>{t("Min seats")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.seats?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                seats: { ...allFilters.seats, min: e.target.value },
              })
            }
            name={"min_seats"}
            values={showFilteredValues?.(
              "seats",
              data?.seats?.min?.data,
              allFilters.seats.max,
              "min"
            )}
          />
        </div>
        <div class="col-sm-6">
          <label>{t("Max seats")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.seats?.max || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                seats: { ...allFilters.seats, max: e.target.value },
              })
            }
            name={"max_seats"}
            values={showFilteredValues?.(
              "seats",
              data?.seats?.max?.data,
              allFilters.seats.min,
              "max"
            )}
          />
        </div>
      </div>
    </div>
  );
}
