import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
// import Details from "./details";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import GeneralDetails from "./generalDetails";
import { fetchPlansData } from "../../../Reducer/api";

export default function PaymentForm({
  onClick,
  start,
  details,
  onPaymentComplete,
  data,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);
  const submitting = useSelector((state) => state.payment.submitting);
  const payment = useSelector((state) => state.payment.payment);
  const error = useSelector((state) => state.payment.error);
  const success = useSelector((state) => state.payment.success);
  const require = t("This field is requried");
  const [errorMsg, setErrorMsg] = useState('') // custom erorr message
  const schema = Yup.object().shape({
    card_number: Yup.string()
      .required(require)
      .min(8, "Must be exactly 8 digits")
      .max(19, "Must be exactly 19 digits"),
    card_name: Yup.string()
      .required(require)
      .min(5, "Must be exactly 5 digits")
      .max(10, "Must be exactly 10 digits"),
    expiry: Yup.string().required(require),
    code: Yup.string()
      .required(require)
      .min(3, "Must be exactly 3 digits")
      .max(3, "Must be exactly 3 digits"),
  });
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    fetchPlansData({ page: "RentYourCar" }).then((res) => {
      if (res.status) {
        setPlanData(res?.data?.plans?.[0]);
      }
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmit = async (e) => {
    setErrorMsg('')
    e.preventDefault();
    setLoading(true)
    // if (submitting == false) {
    //   onPaymentComplete();
    // }
    //   dispatch(make_payment(formData));
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        // return_url: "https://example.com/order/123/complete",
      },
    });

    console.log(result);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setLoading(false)
      setErrorMsg(t('card_declined'))
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (result?.paymentIntent?.status === "requires_capture") {
        // onClick(4, start, details);
        navigate("/check_your_cars", {
          state: { vin: data?.chasis, intentId: result?.paymentIntent?.id },
        });
      }
      setLoading(false)
    }
  };

 



  return (
    <div className="container min-vh-100">
      {/* <img class="w-100" src="../assets/images/check_my_car.png" alt="img" /> */}

      <div>
        <h6 class="back">
          <Button
            onClick={() => {
              // setStep(1);
              navigate("/check_your_cars");
            }}
            title={
              <>
                <img src="assets/images/backbt.png" /> {t("Go back")}
              </>
            }
          />
        </h6>
        <div class="enquery">
          <h5>
            {t("Make Payment")}
            {/* <span>
              {details?.pack && details?.pack?.description?.[language]}
            </span>{" "} */}
            {/* <a
            class="cngplan"
            onClick={() => {
              onClick(2, start, details);
            }}
          >
            {t("Change Plan")}
            <img src="assets/images/arrow-narrow-right.png" alt="img" />
          </a> */}
          </h5>
          <div class="row">
            {/* {details?.pack && <Details details={details?.pack} />} */}
            <div className="col-lg-6">
              {/* <div className="st">
                
              </div> */}
              <GeneralDetails details={details} planData={planData} />
            </div>

            <div class="col-lg-6">
              <div class="st">
                {/* <h4>
                  {t("Debit/Credit Cards")}{" "}
                  <span>
                    <img src="assets/images/card1.png" alt="img" />
                    <img src="assets/images/card2.png" alt="img" />
                    <img src="assets/images/card3.png" alt="img" />
                  </span>
                </h4> */}
                <form class="mb-0" onSubmit={onSubmit}>
                  <div class="row">
                    {/* <div class="col-12">
                      <label>{t("Card Number")}*</label>
                      <FormInput
                        intype={"number"}
                        min={8}
                        max={19}
                        event={register}
                        name={"card_number"}
                        placeholder={t("Card Number")}
                      />
                      {errors.card_number && (
                        <p>{errors.card_number.message}</p>
                      )}
                    </div>
                    <div class="col-12">
                      <label>{t("Name on Card")}*</label>
                      <FormInput
                        intype={"text"}
                        min={5}
                        max={10}
                        event={register}
                        name={"card_name"}
                        placeholder={t("Name on Card")}
                      />
                      {errors.card_name && <p>{errors.card_name.message}</p>}
                    </div>
                    <div class="col-4 col-sm-3">
                      <label>{t("Expiry")}*</label>
                      <FormInput
                        intype={"text"}
                        event={register}
                        name={"expiry"}
                        placeholder={t("00/00")}
                      />
                      {errors.expiry && <p>{errors.expiry.message}</p>}
                    </div>
                    <div class="col-4 col-sm-3">
                      <label>{t("CVV Code")}*</label>
                      <FormInput
                        intype={"number"}
                        min={3}
                        max={3}
                        event={register}
                        name={"code"}
                        placeholder={t("Code")}
                      />
                      {errors.code && <p>{errors.code.message}</p>}
                    </div> */}

                    <PaymentElement  className="paymentECheck" id="paymentE"  />

                    <div class="col-12">
                      <Button
                      disabled={loading}
                        type="submit"
                        title={
                          !loading ? t("Make Payment") : t("Please wait...")
                        }
                      />
                    </div>
                  </div>
                  <p className="helperText text-center">{errorMsg}</p>
                </form>

              </div>
              {/* <form onSubmit={handleSubmitCard}>
                <PaymentElement />
                <div class="col-12">
                  <Button
                    type="submit"
                    title={
                      submitting == false
                        ? t("Make Payment")
                        : t("Please wait...")
                    }
                  />
                </div>
              </form> */}
            </div>
          </div>
          <br />
          <span className="fw-semibold">{t("Note")}:</span>
          <br />
          <span className="w-100 fw-semibold">
            {t("check_your_car_helper_text_3")}
          </span>
        </div>
      </div>
    </div>
  );
}
