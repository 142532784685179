import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import FormInput from "../../../../Tools/FormInput";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiManager } from "../../../../../api/connectAPI";
import { cities, getAllGenders } from "../../../../../data/content";
import SelectForm from "../../../../Tools/SelectForm";
import PhoneNumberInput from "../../../../Tools/PhoneNumberInput";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import GoogleMapAddress from "../../../../Tools/MapInput";
import { useEffect } from "react";
import TextAreaForm from "../../../../Tools/TextAreaForm";
import {
  sendWelcomeMail,
  translateInTwoLanguages,
} from "../../../../../Reducer/api";
import { useSelector } from "react-redux";
import { convertDigitsToEnglish, twoDig } from "../../../../../utils/constants";
import CustomPhoneInput from "../../../../Tools/CustomPhoneInput";
import CustomDatepicker from "../../../../Tools/Datepicker";
import { utils } from "react-modern-calendar-datepicker";
import moment, { months } from "moment";

export default function EditProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.auth.language);
  const [selectedImage, setSelectedImage] = useState(null);

  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      // reader.onload = (e) => {
      //   setSelectedImage(e);
      // };
      setSelectedImage(file);
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };
  const meta = location?.state?.data;
  console.log(meta);
  const userType = localStorage.getItem("userType");
  const { t, i18n } = useTranslation();
  const [profiledata, setstate] = useState(meta);
  const [submitting, setSubmitting] = useState(false);
  const schema = Yup.object().shape({
    // phoneNumber: Yup.string().required(t("phone number is required")),
    fullName: Yup.string().required(t("Full name is required")),
    email: Yup.string(),
    gender: Yup.string()
      .test("match", t("Gender is required"), (v) => v != t("Select"))
      .required(),
    city: Yup.string().required(t("City is required")),
    address: Yup.string()
      .min(6, t("Address must be at least 6 characters"))
      .required(t("Address is a required field")),
    dealerShipName: Yup.string().required(t("Dealership is required")),
    website: Yup.string(),
    about: Yup.string(),
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
    dob: Yup.mixed()
      .required(t("This field is requried"))
      .label(t("Date of birth")),
  });

  const {
    register,
    control,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: profiledata?.phoneNumber,
      fullName: profiledata?.fullName,
      email:
        profiledata?.email && profiledata?.email !== "N/A"
          ? profiledata?.email
          : "",

      address: profiledata?.address,
      dealerShipName: profiledata?.dealerShipName,
      website: profiledata?.additionalInfo?.website,
      about: profiledata?.additionalInfo?.about?.[lang],
      dob: {
        day: moment.unix(profiledata.dateOfBirth).toDate().getDate(),
        month: moment.unix(profiledata.dateOfBirth).toDate().getMonth() + 1,
        year: moment.unix(profiledata.dateOfBirth).toDate().getFullYear(),
      }
    },
    mode: "onBlur",
  });
  const onError = (errors) => {
    console.log(errors);
  };
  console.log(moment.unix(profiledata.dateOfBirth).format('DD/MM/YYYY'))
  console.log('wow', moment.unix(profiledata.dateOfBirth).toDate().getMonth())
  const onSubmitform = async (data) => {
    if (!submitting) {
      // if (phoneNumberErrors) {
      //   return;
      // }

      data.city = cities.filter(
        (v) => t(v?.name?.[lang]) === data.city
      )?.[0]?.name?.["en"];
      console.log(cities);
      data.dateOfBirth = moment(
        twoDig(data.dob.month) +
          "/" +
          twoDig(data.dob.day) +
          "/" +
          data.dob.year
      ).valueOf();
      if (selectedImage) {
        const formData = new FormData();
        formData.append("files", selectedImage);

        apiManager(
          "POST",
          "storage/upload?lang=en&type=3",
          formData,
          null,
          "multipart/form-data"
        )
          .then((res) => {
            if (res.status) {
              data["profilePicture"] = res.data[0].s3URL;
              handleSaveProfile(data);
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(err.message);
          });
      } else {
        handleSaveProfile(data);
      }
    }
    // if()
    // {
    // let formData=new FormData();
    // formData.append('name',data.name);
    // formData.append('phone',data.phone);
    // formData.append('address',data.address);
    // formData.append('companyname',data.companyname);
    // formData.append('city',data.city);
    // formData.append('address',data.address);
    // formData.append('language',i18n.language);
    // formData.append('profile',selectedImage ? selectedImage : []);
    // formData.append('about',data.about);
    // formData.append('website',data.website);
    // dispatch(saveprofileForm(formData));
    // console.log('formData',formData);
    // toast.success('Form Submit successfully');
    // }
  };

  const [genders, setGenders] = useState([]);

  useEffect(() => {
    getAllGenders()
      .then((res) => {
        const data = res;
        for (let i = 0; i <= data.length; i++) {
          if (data?.[i]?.["name"]?.["en"] === profiledata?.gender["en"]) {
            setValue("gender", data?.[i]?.["name"]?.[lang]);
            break;
          }
        }
        setGenders(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSaveProfile = async (data) => {
    const genderId = genders.filter((v) => v.name[lang] === data.gender);
    data["genderId"] = genderId?.[0]?._id;

    const allLangAbout = await translateInTwoLanguages(lang, data.about);
    data.about = allLangAbout;

    let websiteTemp =
      data?.website?.length != 0
        ? data?.website?.startsWith?.("https")
          ? data.website
          : "https://".concat(data.website)
        : "";

    data.additionalInfo = {
      ...profiledata?.additionalInfo,
      website: websiteTemp,
      about: data.about,
    };

    if (profiledata?.email !== data.email) data.emailVerified = false;
    if (profiledata?.phoneNumber !== data.phoneNumber)
      data.phoneNumberVerified = false;

    if (!submitting) {
      setSubmitting(true);
      const temp = {
        status: true,
      };

      let isEmailAvailable = {};
      let isPhoneAvailable = {};
      if (
        data?.email !== profiledata?.email &&
        data?.email &&
        data?.email !== "N/A"
      ) {
        isEmailAvailable = await apiManager(
          "GET",
          `general/checkAvailability?lang=${lang}&attribute=email&value=${data.email}`
        );
      } else {
        isEmailAvailable = temp;
      }
      if (
        data.phoneNumber !== profiledata?.phoneNumber &&
        data?.phoneNumber &&
        data?.phoneNumber !== "N/A"
      ) {
        isPhoneAvailable = await apiManager(
          "GET",
          `general/checkAvailability?lang=${lang}&attribute=phoneNumber&value=${data.phoneNumber}`
        );
      } else {
        isPhoneAvailable = temp;
      }
      console.log(data);
      if (isEmailAvailable?.status && isPhoneAvailable?.status) {
        apiManager("PUT", `user/profile?lang=${lang}`, data)
          .then((res) => {
            if (res.status) {
              // toast.success(res.message);
              if (!profiledata?.isWelcomeMailSent) {
                sendWelcomeMail();
              }
              navigate("/MyAccount/MyProfile");
              setSubmitting(false);
            } else {
              toast.error(res.message);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error("Something went wrong!");
            setSubmitting(false);
          });
      } else {
        setSubmitting(false);
        if (!isEmailAvailable.status) {
          setError("email", { message: isEmailAvailable.message });
        }
        if (!isPhoneAvailable.status) {
          setError("phoneNumber", { message: isPhoneAvailable.message });
          // setPhoneNumberErrors(isPhoneAvailable.message);
        }
      }
    }
  };

  const page = localStorage.getItem("userType");

  // const [phoneNumber, setPhoneNumber] = useState(profiledata?.phoneNumber);
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");

  const [modal, setModal] = useState(false);
  const handleCloseModal = () => {
    setModal(false);
  };
  const [cityByMap, setCityByMap] = useState(profiledata?.city);
  return (
    <div>
      <div class="my_profile">
        <form onSubmit={handleSubmit(onSubmitform, onError)}>
          <h3 class="pt-0">
            {t("Edit Basic Details")}{" "}
            <span class="d-none d-sm-block">
              <button
                class="save"
                style={{ width: "auto" }}
                disabled={submitting}
                type="submit"
              >
                {submitting ? t("Please wait...") : t("Save Changes")}
              </button>
              <NavLink to="/MyAccount/MyProfile">{t("Cancel")}</NavLink>
            </span>
          </h3>
          <div class="row">
            <div class="col-sm-3 col-xl-2">
              <div class="imgs">
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {selectedImage ? (
                  <img
                    style={{ objectFit: "cover" }}
                    class="w-100"
                    src={URL.createObjectURL(selectedImage)}
                    align="img"
                  />
                ) : profiledata?.profilePicture &&
                  profiledata?.profilePicture != "N/A" ? (
                  <img
                    style={{ objectFit: "cover" }}
                    class="w-100"
                    src={profiledata?.profilePicture}
                    align="img"
                  />
                ) : (
                  <img
                    style={{ objectFit: "cover" }}
                    class="w-100 bg-light p-4"
                    src="/assets/images/ap2.png"
                    align="img"
                  />
                )}
                <span>
                  <img
                    src="../assets/images/cmra.png"
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                  />
                </span>
              </div>
            </div>
            <div class="col-sm-9 col-xl-10">
              <div class="row">
                <div class="form-group col-sm-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>{t("Name")}</h6>
                    <FormInput
                      style={"form-control"}
                      intype={"text"}
                      event={register}
                      value={profiledata?.fullName}
                      name={"fullName"}
                      placeholder={t("Full Name")}
                    />
                    {errors.fullName && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.fullName.message)}
                      </p>
                    )}
                  </span>
                </div>
                <div class="form-group col-sm-6  form-group">
                  <span style={{ position: "relative" }}>
                    <h6>{t("Gender")}</h6>
                    <SelectForm
                      style={"form-control"}
                      event={register}
                      name={"gender"}
                      selectedOption={profiledata?.gender[lang]}
                      values={genders}
                    />
                    {errors.gender && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.gender.message)}
                      </p>
                    )}
                  </span>
                </div>

                {/* for dealer */}
                {userType == "dealer" ? (
                  <div class="form-group col-sm-6 col-lg-6 ">
                    <span style={{ position: "relative" }}>
                      <h6>{t("Dealership Name")}</h6>
                      <FormInput
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        // value={profiledata.companyname[i18n.language]}
                        name={"dealerShipName"}
                        placeholder={t("Dealership Name")}
                      />
                      {errors.dealerShipName && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.dealerShipName.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}

                {/* for dealer */}
                {userType == "dealer" ? (
                  <div class="form-group col-sm-6 col-lg-6 ">
                    <span style={{ position: "relative" }}>
                      <h6>{t("Website")}</h6>
                      <FormInput
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        value={profiledata?.website}
                        name={"website"}
                        placeholder={t("Website")}
                      />
                      {errors.website && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.website.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}

                <div class="form-group col-sm-6 col-lg-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>{t("Email")}</h6>

                    <FormInput
                      style={"form-control"}
                      intype={"email"}
                      event={register}
                      value={profiledata?.email}
                      name={"email"}
                      placeholder={t("Email")}
                    />
                    {errors.email && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {t(errors.email.message)}
                      </p>
                    )}
                  </span>
                </div>
                <div
                  class="col-sm-6 col-lg-6 form-group mb-2"
                  style={{ position: "relative" }}
                >
                  <h6>{t("Phone")}</h6>
                  {/* <PhoneNumberInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberErrors={phoneNumberErrors}
                    setPhoneNumberErrors={setPhoneNumberErrors}
                  /> */}
                  <CustomPhoneInput
                    event={register}
                    name={"phoneNumber"}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  {errors.phoneNumber ? (
                    <p
                      style={{
                        top: "68px",
                        left: lang === "en" ? "12px" : "-12px",
                      }}
                      className={`helperText w-100 text-${
                        lang === "en" ? "start" : "end"
                      }`}
                    >
                      {t(errors.phoneNumber.message)}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <div class="form-group col-sm-6 col-lg-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>{t("City")}</h6>
                    <SelectForm
                      style={"form-control"}
                      event={register}
                      name={"city"}
                      selectedOption={cityByMap}
                      values={cities}
                    />
                    {errors.city && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.city.message)}
                      </p>
                    )}
                  </span>
                </div>
                <div class="form-group d-flex flex-column  col-sm-6 col-lg-6">
                  <h6>{t("Date of birth")}</h6>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <CustomDatepicker
                        onChange={(date) => field.onChange(date)}
                        value={field.value}
                        placeholder={t("Select")}
                        maxDate={utils().getToday()}
                        inputClassName={`form-control text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      />
                    )}
                  />
                  {errors.dob && (
                    <p className="helperText" style={{ bottom: "-1px" }}>
                      {t(errors.dob.message)}
                    </p>
                  )}
                </div>
                {page === "dealer" ? (
                  <div
                    class="col-xl-12 form-group"
                    onClick={() => (page === "dealer" ? setModal(true) : null)}
                  >
                    <span style={{ position: "relative" }}>
                      <h6>{t("Address")}</h6>
                      <FormInput
                        style={"form-control"}
                        intype={"textarea"}
                        event={register}
                        name={"address"}
                        placeholder={t("Address")}
                        value={profiledata?.address}
                      />
                      {errors.address && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.address.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : null}

                {/* for dealer */}
                {userType == "dealer" ? (
                  <div class="col-sm-12 form-group">
                    <span style={{ position: "relative" }}>
                      <h6>{t("About")}</h6>
                      <TextAreaForm
                        event={register}
                        name={"about"}
                        style={"form-control"}
                        value={profiledata?.website}
                        placeholder={t("About")}
                      />
                      {errors.about && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.about.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}
              </div>
            </div>
            <div class="col-12 d-block d-sm-none albtn">
              <button class="save my-2" disabled={submitting} type="submit">
                {submitting ? t("Please wait...") : t("Save Changes")}
              </button>
              <NavLink className=" my-2" to="/MyAccount/MyProfile">
                {t("Cancel")}
              </NavLink>
              {/* <button type="submit">{t('Save')}</button>
        <button type="button" class="w-100 mb-34" >{t('Cancel')}</button> */}
            </div>
          </div>
        </form>
      </div>
      <Modal isOpen={modal} centered toggle={handleCloseModal}>
        <ModalHeader>{t("Point the map to your location")}</ModalHeader>
        <ModalBody>
          <GoogleMapAddress
            handleCloseModal={handleCloseModal}
            setValue={setValue}
            setCityByMap={setCityByMap}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
