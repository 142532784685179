import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { openAddressOnMap } from "../../../utils/constants";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useSelector } from "react-redux";
import { initiateChat } from "../../../Reducer/api";

export default function DealerCarInfo({ responce, dealerCars, ...props }) {
  const { t, i18n } = useTranslation();
  const udata = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  useEffect(() => {
    const adIdToChat = localStorage.getItem("adIdToChat");
    if (adIdToChat) {
      const tmp = localStorage.getItem("chatInitiated");
      if (tmp == "true") {
        localStorage.setItem("chatInitiated", "false");
        localStorage.setItem("adIdToChat", "");
        handleInitiateChat(adIdToChat);
      }
    }
  }, [localStorage]);

  const singleDealer = props?.singleDealer;
  const [readMore, setReadMore] = useState(false);

  const goToViewAllCars = (id) => {
    navigate(`/DealerCarView?ownerId=${id}`);
  };

  const formatValue = (time) => {
    const a = time.substring(time.length - 2);
    let res = "";
    if (a.toLowerCase() == "am") {
      res = t("AM");
    } else if (a.toLowerCase() == "pm") {
      res = t("PM");
    } else {
      return time;
    }
    if (!res) {
      return time;
    }
    return time.substring(0, time.length - 2).concat(" " + res);
  };

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!udata.token) {
      navigate("/Login", {
        state: {
          backPage: location.pathname + location.search,
          request: "chat",
          adIdToChat: id,
        },
      });
      return;
    }
    setChatNow({ ...chatNow, adId: id, loading: true });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate("/MyAccount/Messages", {
            state: { chatId: res.chatId },
          });
        }
        setChatNow({ ...chatNow, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {responce ? (
        <div class="container">
          <h6 class="back">
            <a onClick={() => navigate(-1)}>
              <img src="../assets/images/backbt.png" /> {t("Go back")}
            </a>
          </h6>
          <div class="row">
            <div class="col-lg-6 col-xxl-7">
              <div class="singlepage_left">
                <img
                  style={{
                    objectFit: "cover",
                    border: "1px solid gray",
                    borderRadius: "9px",
                    height: "400px",
                  }}
                  class={`w-100 mb-3`}
                  src={
                    responce?.coverPicture
                      ? responce?.coverPicture
                      : "../assets/images/notFound.png"
                  }
                  alt="bg"
                />
                <h3 class="mt-5 mb-3 fw-bold">{t("About us")}</h3>
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: readMore ? 100 : 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    // minWidth: "350px",
                  }}
                >
                  {responce?.additionalInfo?.about?.[lang]
                    ? responce?.additionalInfo?.about?.[lang]
                    : "--"}
                </p>
                {responce?.additionalInfo?.about?.[lang]?.length > 25 && (
                  <a
                    className="mb-3"
                    onClick={() => {
                      setReadMore(!readMore);
                    }}
                  >
                    {readMore ? t("Read Less") : t("Read More")}
                  </a>
                )}

                {/* <p>{responce.aboutus[i18n.language]}</p> */}
              </div>
            </div>
            <div class="col-lg-6 col-xxl-5">
              <div class="singlepage_right second">
                <h3 className="mb-5">{responce?.dealerShipName}</h3>
                {/* <h4>
                  <img src="../assets/images/bld.png" alt="img" />
                  {t("Business")}
                </h4> */}
                <div class="row btns">
                  <div class="col-12 mb-3">
                    <a
                      onClick={() => goToViewAllCars(responce?._id)}
                      class="btn call w-100"
                    >
                      {t("View all cars")}
                    </a>
                  </div>
                  {responce?._id === udata.auth.id ? (
                    <></>
                  ) : (
                    <>
                      <div class="col-6">
                        <a
                          href={`tel:${responce?.myvehicleshoneNumber}`}
                          class="btn w-100"
                        >
                          <img src="../assets/images/call-K2.png" alt="img" />
                          {t("Call dealer")}
                        </a>
                      </div>
                      <div class="col-6">
                        {/* <a class="btn w-100">
                      <img src="../assets/images/message-K.png" alt="img" />{" "}
                      {t("Message dealer")}
                    </a> */}
                        <UncontrolledDropdown>
                          <DropdownToggle className="bg-light w-100">
                            {chatNow?.loading ? (
                              t("Please wait...")
                            ) : (
                              <>
                                <img
                                  src="../assets/images/message-K.png"
                                  alt="img"
                                />{" "}
                                {t("Message dealer")}
                              </>
                            )}
                          </DropdownToggle>
                          <DropdownMenu
                            className={`w-100 mt-2 text-${
                              lang === "en" ? "start" : "end"
                            }`}
                          >
                            <DropdownItem header>
                              {t("Dealer Cars")}
                            </DropdownItem>
                            <DropdownItem divider />
                            {dealerCars?.map((item, index) => {
                              return (
                                <DropdownItem
                                  onClick={() => handleInitiateChat(item?._id)}
                                >
                                  <span
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  >
                                    {`${t(
                                      item?.properties?.basicDetails?.make?.en
                                    )} ${t(
                                      item?.properties?.basicDetails?.model?.en
                                    )} ${t(
                                      item?.properties?.basicDetails?.makeYear
                                    )}`}
                                  </span>
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </>
                  )}
                </div>

                <h5>{t("Get In Touch")}</h5>
                <div class="row">
                  <div
                    class="col-sm-4 "
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      openAddressOnMap(
                        responce?.address ? responce?.address : null
                      )
                    }
                  >
                    <h6>{t("Address")}</h6>
                    <p className="hoverUnderline text-primary">
                      {responce?.address ? responce?.address : "--"}
                    </p>
                  </div>
                  <div class="col-sm-4">
                    <h6>{t("Website")}</h6>
                    <a
                      href={`${responce?.additionalInfo?.website}`}
                      target="_blank"
                    >
                      {responce?.additionalInfo?.website
                        ? t("Visit website")
                        : "--"}
                    </a>
                  </div>
                  <div class="col-sm-4">
                    <h6>{t("Phone")}</h6>
                    <a href={`tel:${responce?.phoneNumber}`}>
                      {responce?.phoneNumber
                        ? "+" + responce?.phoneNumber
                        : "--"}
                    </a>
                  </div>
                </div>
                {/* <p class="tim">{'10 km away from you'}</p> */}
                <h6 class="mb-3">{t("Working hours")}</h6>
                <ul class="d-flex flex-column">
                  {responce?.additionalInfo?.dealerWorkingHours
                    ? responce?.additionalInfo?.dealerWorkingHours?.map(
                        (item, key) => {
                          return (
                            <div
                              key={key}
                              className="d-flex justify-content-between"
                              style={{
                                paddingLeft: "0px",
                                marginBottom: "4px",
                              }}
                            >
                              <span>{t(item?.day)}</span>
                              <span
                                style={{
                                  color: !item?.active ? "#e44e4e" : "black",
                                }}
                              >
                                {!item?.active
                                  ? t("Closed")
                                  : lang === "en"
                                  ? formatValue(item?.startTime) +
                                    " - " +
                                    formatValue(item?.endTime)
                                  : `${formatValue(
                                      item?.endTime
                                    )} - ${formatValue(item?.startTime)}`}
                              </span>
                            </div>
                          );
                        }
                      )
                    : "--"}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "Loading....."
      )}
    </>
  );
}
