import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";
import { t } from "i18next";
import i18n from "../../../i18n";

export default function PhoneNumberInput({
  phoneNumber,
  setPhoneNumber,
  phoneNumberErrors,
  setPhoneNumberErrors,
  dontShowErrors,
  styles,
  errorStyles
}) {
  // const [lang, setLang] = useState(localStorage.getItem('cl'))
  const lang = i18n.language;
  // console.log(lang)

  // useEffect(() => {
  //   setPhoneNumberErrors(" ");
  // }, [localStorage.getItem('cl')]);

  const validate = () => {
    if (phoneNumber.length === 0) {
      setPhoneNumberErrors(t("Please Enter the Phone Number"));
    } else if (phoneNumber.length < 13) {
      setPhoneNumberErrors(t("Phone number is not valid"));
      console.log(phoneNumber.length);
    } else {
      setPhoneNumberErrors("");
    }
  };
  return (
    <div
      className="form-group"
      style={{ paddingBottom: "10px", position: "relative", direction: 'ltr' }}
    >
      <PhoneInput
        inputStyle={{
          // direction: lang === "English" ? "ltr" : "rtl",
          width: "100%",
          height: "45px",
          border: "1px solid #989898",
          padding: lang === "English" ? "0px 0px 0px 45px" : "0px 0px 0px 45px",
          ...styles
        }}
        buttonStyle={{
          border: "1px solid #989898",
          direction: "ltr",
          // marginInline: '30px'
        }}
        inputClass="form-control"
        country={"iq"}
        countryCodeEditable={false}
        onlyCountries={['iq']}
        placeholder="78945..."
        value={phoneNumber}
        onBlur={validate}
        onChange={(value) => {
          if(value.length >= 4 && value.charAt(3) == 0) {
            setPhoneNumber(value.substring(0,3)+value.substring(4))
          } else {
            setPhoneNumber(value)
          }
        }}
        autoFormat={false}
        enableLongNumbers={false}
      />
      {phoneNumberErrors && !dontShowErrors && (
        <p
          style={{
            color: '#ff0000b8',
            fontSize: "13px",
            position: "absolute",
            left: "0px",
            bottom: "-10px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            ...errorStyles
          }}
          className={`${
            lang === "English" ? "text-start" : "text-end"
          }  m-0`}
        >
          {phoneNumberErrors}
        </p>
      )}
    </div>
  );
}
