import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../../../i18n";
import { apiManager } from "../../../../../api/connectAPI";
import { typeCoverPicture } from "../../../../../utils/types";
import { useDispatch } from "react-redux";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";
export default function UploadCover({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Basicetails = data.Basicetails;
  var length = Object.keys(data).length;
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const handleChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    apiManager(
      "POST",
      `storage/upload?lang=${lang}&type=${typeCoverPicture}`,
      formData,
      null,
      "multipart/form-data"
    )
      .then((res) => {
        if (res.status) {
          dispatch(myprofilefetch());
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {length > 0 ? (
        <>
          <h3 class="securityh3">
            {t("Cover image")}
            {data?.coverPicture && data?.coverPicture !== "N/A" && (
              <label
                htmlFor="coverFileEdt"
                class="edt"
                style={{ cursor: "pointer" }}
              >
                <img src="../assets/images/pencil.png" alt="pencil" />{" "}
                {t("Edit cover picture")}
                <input
                  id="coverFileEdt"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </label>
            )}
          </h3>
          <div class="row">
            {data?.coverPicture && data?.coverPicture !== "N/A" ? (
              <div class="col-sm-12 d-flex justify-content-center">
                <img src={data?.coverPicture} height={150} alt="" className="img-fluid" style={{maxHeight: '300px'}} />
              </div>
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <div className="row p-3">
                  <div className="col-lg-12 text-center">
                    {t("Upload cover picture")}
                  </div>
                  <div className="col-lg-12">
                    <label
                      htmlFor="coverFile"
                      style={{ cursor: "pointer" }}
                      className="d-flex justify-content-center"
                    >
                      <img
                        src="../assets/images/pencil-alt.webp"
                        height={25}
                        alt="pencil"
                      />
                    </label>
                    <input
                      id="coverFile"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
