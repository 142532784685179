import React, { useEffect, useState } from "react";
import SelectOptions from "../SelctOptions";
import { useTranslation } from "react-i18next";
export default function DWM({
  title,
  data,
  id,
  handleChange,
  handleClick,
  allFilters,
  setAllFilters,
  showFilteredValues,
}) {
  const { t, i18n } = useTranslation();
  const [priceRadio, setPriceRadio] = useState(allFilters?.rentType || "daily");
  const handleChangePriceRadio = (e) => {
    setPriceRadio(e.target.value);
    setAllFilters({ ...allFilters, rentType: e.target.value });
  };

  return (
    <ul class="dropdown-menu" id={id}>
      {data && data.min && data.max ? (
        <div class="setp">
          <div class="row">
            <div
              class="btn-group w-100"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <div className="col-4">
                <input
                  type="radio"
                  class="btn-check"
                  value={"daily"}
                  name="btnradioDMW"
                  onChange={(e) => handleChangePriceRadio(e)}
                  id="btnradioDMW1"
                  autocomplete="off"
                  checked={priceRadio === "daily"}
                />
                <label
                  class={`btn rental-price-radio ${
                    priceRadio === "daily" ? "active" : ""
                  }`}
                  style={{
                    height: "43px",
                    borderRadius:
                      i18n.language === "English"
                        ? "5px 0px 0px 5px"
                        : "0px 5px 5px 0px",
                    borderTop: "1px solid #606060",
                    borderBottom: "1px solid #606060",
                    borderLeft:
                      i18n.language === "English"
                        ? "1px solid #606060"
                        : "0px solid #606060",
                    borderRight:
                      i18n.language === "English"
                        ? "0px solid #606060"
                        : "1px solid #606060",
                  }}
                  for="btnradioDMW1"
                >
                  {t("Daily")}
                </label>
              </div>
              <div className="col-4">
                <input
                  type="radio"
                  class="btn-check"
                  value={"weekly"}
                  name="btnradioDMW"
                  onChange={(e) => handleChangePriceRadio(e)}
                  id="btnradioDMW2"
                  autocomplete="off"
                  checked={priceRadio === "weekly"}
                />
                <label
                  class={`btn rental-price-radio ${
                    priceRadio === "weekly" ? "active" : ""
                  }`}
                  for="btnradioDMW2"
                  style={{
                    height: "43px",
                    borderRadius: "0px",
                    borderTop: "1px solid #606060",
                    borderBottom: "1px solid #606060",
                    borderLeft: "1px solid #606060",
                    borderRight: "1px solid #606060",
                  }}
                >
                  {t("Weekly")}
                </label>
              </div>
              <div className="col-4">
                <input
                  type="radio"
                  class="btn-check"
                  value={"monthly"}
                  name="btnradioDMW"
                  onChange={(e) => handleChangePriceRadio(e)}
                  id="btnradioDMW3"
                  autocomplete="off"
                  checked={priceRadio === "monthly"}
                />
                <label
                  class={`btn rental-price-radio ${
                    priceRadio === "monthly" ? "active" : ""
                  }`}
                  for="btnradioDMW3"
                  style={{
                    height: "43px",
                    borderRadius:
                      i18n.language === "English"
                        ? "0px 5px 5px 0px"
                        : "5px 0px 0px 5px",
                    borderTop: "1px solid #606060",
                    borderBottom: "1px solid #606060",
                    borderLeft:
                      i18n.language === "English"
                        ? "0px solid #606060"
                        : "1px solid #606060",
                    borderRight:
                      i18n.language === "English"
                        ? "1px solid #606060"
                        : "0px solid #606060",
                  }}
                >
                  {t("Monthly")}
                </label>
              </div>
            </div>
            <div class="col-6">
              <label>{t(data.min.name[i18n.language])}</label>
              {/* <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input type="number" min="0" class="form-control" />
              </div> */}
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data.min.daily.filter(
                    (v) => v?.value?.toString?.() == allFilters?.price?.min
                  )?.[0]?.name || t("Any")
                }
                name={data.min.field}
                values={showFilteredValues(
                  "min_max_price",
                  data.min.daily,
                  allFilters?.price?.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    price: { ...allFilters.price, min: e.target.value },
                  })
                }
              />
              {/* <SelectOptions
                style={"form-control"}
                selectedOption={""}
                name={data.min.field}
                values={data.min.data}
                onChange={handleChange}
              /> */}
            </div>
            <div class="col-6">
              <label>{t(data.max.name[i18n.language])}</label>
              {/* <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input type="number" min="0" class="form-control" />
              </div> */}
              {/* <SelectOptions
                style={"form-control"}
                selectedOption={""}
                name={data.max.field}
                values={data.max.data}
                onChange={handleChange}
              /> */}
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data.max.daily.filter(
                    (v) => v.value == allFilters?.price?.max
                  )?.[0]?.name || t("Any")
                }
                name={data.max.field}
                values={showFilteredValues(
                  "min_max_price",
                  data.max.daily,
                  allFilters?.price?.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    price: { ...allFilters.price, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </ul>
  );
}
