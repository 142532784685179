import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../FormInput";
import Button from "../../Button";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fromChacie, clearStatus } from "../../../../Reducer/chaiceSlice";
import { toast } from "react-toastify";
import { closestValue, errorSms } from "../../../../data/content";
import { myprofilefetch } from "../../../../Reducer/myprofileSlice";
import SellFind from "../../SellFind";
import SellFindModel from "../../SellFindModel";
import { dataInFormat } from "./dataFormattingUtil";
export default function FirstView({ setPage, setData, title }) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const dispatch = useDispatch();
  const udata = useSelector((state) => state.myprofile.profilearr);
  const submitting = useSelector((state) => state.chacie.submitting);
  const chasis = useSelector((state) => state.chacie.chacie);
  const error = useSelector((state) => state.chacie.error);
  const success = useSelector((state) => state.chacie.success);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    chasis: Yup.string()
      .required(t("Chassis number is required"))
      .min(17, t("Chasis number must be exactly 17 characters"))
      .max(17, t("Chasis number must be exactly 17 characters")),
  });
  useEffect(() => {
    dispatch(myprofilefetch());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    if (submitting == false) {
      dispatch(fromChacie({ chasis: data.chasis, type: "sellRent" }));
    }
  };
  const onError = (errors) => {
    console.log(errors);
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }
  const handleClick1 = () => {
    if (token && title != "Check your Car") {
      setPage("FirstFormWithoutVin");
    } else {
      navigate("/login");
    }
  };

  if (success == true) {
    let updatedResult = {};
    const result1 = chasis.carApiarResponse;
    const result2 = chasis.vinDecoderResponse;
    console.log(chasis);
    if (result1 || result2) {
      updatedResult = dataInFormat(result1, result2).then((res) => {
        setData(res);
        setPage("SecondChildVIn");
        dispatch(clearStatus());
      });
    }
  }
  return (
    <div attr={title} class={title == "Check your Car" ? "check_my_car" : ""}>
      <h6 class="back">
        <NavLink to={"/"}>
          <img src="../assets/images/backbt.png" /> {t("Go back")}
        </NavLink>
      </h6>

      <form
        className={
          title == "Check your Car" ? "set incell position-relative" : ""
        }
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <h5>{t(title)}</h5>
        <div class="form-group position-relative ft pb-1 text-center">
          <FormInput
            style={"form-control chasis"}
            intype={"text"}
            event={register}
            name={"chasis"}
            disabled={!udata?.phoneNumberVerified}
            placeholder={t("Chassis number (VIN)")}
          />
          {errors.chasis && (
            <p
              className="text-danger text-center"
              style={{ fontSize: "14px", marginTop: "2px" }}
            >
              {errors.chasis.message}
            </p>
          )}
        </div>
        <Button
          style="Search_VIN"
          disabled={submitting || !udata?.phoneNumberVerified}
          title={
            submitting == false ? t("Search VIN Number") : t("Please wait...")
          }
          type="submit"
        />
        {udata?.phoneNumberVerified && (
          <p className="d-flex justify-content-center">
            <a
              className="mt-4 d-block text-center"
              onClick={handleClick1}
              style={{
                textDecoration: "underline",
                color: "black",
                width: "fit-content",
              }}
            >
              {t("Proceed without VIN number")}
            </a>
          </p>
        )}
      </form>
      <SellFind />
      <SellFindModel />
    </div>
  );
}
