import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "../ListItem";
import { useDispatch, useSelector } from "react-redux";

const PlanCard = ({ item, onClick, details, page }) => {
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();
  const userType = localStorage.getItem("userType");

  const soldText = () => {
    if(page === 'SellYourCar') {
      return t("until sold")
    }
    if(page === 'RentYourCar') {
      return t("until rent")
    }
    return t("until rent")
  }
  
  return (
    <div class="col-lg-3 mb-4">
      <div
        className="planCard"
        style={{
          padding: "unset",
          borderRadius: "10px",
          paddingBottom: "10px",
        }}
      >
        {/* <h6>{t("MOST VALUABLE")}</h6> */}
        <div
          className={`hd text-${
            item?.name === "Premium" ? "light" : ""
          } border-bottom`}
          style={{
            position: "unset",
            backgroundColor:
              item?.name === "Premium"
                ? "#4C7EE8"
                : item?.name === "Ultimate"
                ? "#FCBC00"
                : "",
            height: "180px",
            padding: "20px",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <h3
            className={`hd fw-bold text-${
              item?.name === "Premium" ? "light" : ""
            }`}
          >
            {t(item?.name)}
          </h3>
          <h5 className="fs-6">{t(item?.description)}</h5>
        </div>
        <h3
          className="planCardPrice"
          style={{ position: "relative", paddingInline: "20px" }}
        >
          <span
            className={
              userType === "dealer" ? "text-decoration-line-through" : ""
            }
          >
            ${item?.price}
          </span>
          <br />
          <span className="fs-6">
            {t("Plan duration")}:{" "}
            {item?.duration == -1
              ? soldText()
              : item?.duration + " " + t("days")}
          </span>
          {userType === "dealer" ? (
            <span
              class="badge rounded-pill badge-sm bg-success bg-opacity-75"
              style={{
                fontSize: "13px",
                position: "absolute",
                top: "5px",
                left: language === "English" ? "100px" : "unset",
                right: language !== "English" ? "100px" : "unset",
              }}
            >
              {t("Free for now")}
            </span>
          ) : null}
        </h3>
        <a
          style={{ marginInline: "20px", width: "unset" }}
          class="continue"
          onClick={() => {
            onClick(item);
          }}
        >
          {t("Select")}
        </a>
        <h4 className="fw-bold fs-6" style={{ paddingInline: "20px" }}>
          {t("What’s Included")}
        </h4>
        <ul style={{ paddingInline: "20px" }}>
          {item?.additionalInfo?.features?.map((it, i) => {
            return (
              <ListItem key={i} innerhtmlType={"html"} value={t(it)}></ListItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default PlanCard;