import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CheckMyCar from "./CheckMyCar";
import { clearStatus, fromChacie } from "../../../Reducer/chaiceSlice";
import { cities, errorSms, title } from "../../../data/content";
import { toast } from "react-toastify";
import { dataInFormatCheck } from "./dataFormatUtil";

export default function CheckCar() {
  const { t, i18n } = useTranslation();
  document.title = title.CheckYourCar[i18n.language];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.chacie.submitting);
  const chasis = useSelector((state) => state.chacie.chacie);
  const error = useSelector((state) => state.chacie.error);
  const success = useSelector((state) => state.chacie.success);
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [vin, setVin] = useState(null);
  const [carData, setCarData] = useState({});
  // const [startup, setStart] = useState("FirstChildVIn");
  // const [details, setDetails] = useState({});

  // const onPaymentComplete = (data) => {
  //   console.log(vin);
  //   dispatch(fromChacie({ chasis: vin, type: "check" }));
  //   // setCarData(findMyCarDataStatic)
  //   // setStep(3)
  // };

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      dispatch(fromChacie({ chasis: location.state, type: "check" }));
    }
    // let obj = {}
    // let data = cities.map((v) => {
    //   return {
    //     // en: v?.name?.en,
    //     // ar: t(v?.name?.en, { lng: "Arabic" }),
    //     // ku: t(v?.name?.en, { lng: "Kurdish" }),
    //     [`${v?.name?.en}`]: t(v?.name?.en, {lng: 'Kurdish'}),
    //   };
    // });
    // data.forEach(element => {
    //   obj = {...obj, ...element}
    // });
    // console.log(obj)
  }, [location]);

  const onError = (errors) => {
    console.log(errors);
  };

  if (success == true) {
    const result = chasis?.result?.report;
    let updatedResult = dataInFormatCheck(result);
    // setCarData(updatedResult);
    // setStep(3);
    if (result) {
      navigate("/SingleCarCheck", { state: { data: updatedResult } });
    }
    dispatch(clearStatus());
  }

  if (error) {
    const err = error;
    dispatch(clearStatus());
    navigate("/SingleCarCheck", {
      state: { data: { found: false, msg: err } },
    });
  }

  // var data = { startpage: startup, backpage: "", data: details };
  // document.title = title.SellYourCar[i18n.language];

  // if (location.state && location.state.startpage != "") {
  //   data.startpage = location.state.startpage;
  //   data.backpage = location.state.backpage;
  //   data.data = location.state.data;
  // }

  // const handleClick = (steps, from, data) => {
  //   setStep(steps);
  //   setStart(from);
  //   setDetails(data);
  // };

  if (location.state) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return <CheckMyCar step={step} setStep={setStep} setVin={setVin} vin={vin} />;
}
