import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CompareBox from "../../../Tools/CompareBox";
import Button from "../../../Tools/Button";
import { fetchSaved } from "../../../../Reducer/savedSlice";
import { homeOptions } from "../../../../data/content";
import OwlItemSaved from "../../../Tools/OwlItem/owlItemSaved";
import ReactSelectCustom from "../../../Tools/ReactSelectCustom";
import sortBy from "../../../../data/sortBy.json";
export default function Adverts({ rtl }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const saved = useSelector((state) => state.saved.saved);
  const loading = useSelector((state) => state.saved.loading);
  const error = useSelector((state) => state.saved.error);
  const auth = useSelector((state) => state.auth.auth);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState("");
  const [comp, setcomp] = useState([]);

  const [loadFirstTime, setLoad] = useState(1);
  const [listOfChecked, setlistOfChecked] = useState([]);
  const [sortVal, setSortVal] = useState({ sort: "", order: "" });

  let data = { id: auth && auth.id ? auth.id : "" };

  useEffect(() => {
    dispatch(
      fetchSaved({
        sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
      })
    );
  }, [dispatch, page, comp, sortVal]);

  useEffect(() => {
    setLoad(loadFirstTime + 1);
  }, [saved]);

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchSaved(data));
    }
  };
  const clearAll = (state, page) => {
    setShow(state);
    setPage(page);
    // setcomp([]);
  };

  return saved?.length === 0 ? (
    <div
      class="d-flex justify-content-center"
      style={{ minHeight: "100vh", marginTop: "25vh" }}
    >
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    // compare box visible when show is true
    <>
      {show === true && (
        <CompareBox
          data={comp}
          listOfChecked={listOfChecked}
          setlistOfChecked={setlistOfChecked}
          onChange={setcomp}
          mainTitle={t("Compare")}
          subTitle={`${comp.length + " " + t("Vehicles selected")}`}
          buttonTitle={t("Compare now")}
          onClick={() => {
            clearAll(false, "");
          }}
        />
      )}
      {saved && loadFirstTime < 2 ? null : (
        <div style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-sm-7 col-12">
              <span className="fs-5 fw-semibold text-center w-100">
                {saved?.totalAds
                  ? saved?.totalAds + " " + t("Vehicles saved")
                  : t("No saved cars")}
              </span>
              <br />
              {/* <span>
            {saved?.ads?.length > 1 ? (
              <p>{`${t("Compare up to") + " 4 " + t("vehicles now")}`}</p>
            ) : null}
            </span> */}
            </div>
            <div className="col-sm-5 col-12">
              <span className="d-flex align-items-center justify-content-sm-end">
                <img
                  src="assets/images/sort-descending.png"
                  height={18}
                  className="px-2"
                  alt="icon"
                />{" "}
                <ReactSelectCustom
                  values={sortBy.sortData}
                  width={"180px"}
                  // boxWidth={'140px'}
                  placeholder={""}
                  selectedOption={t("Relevance")}
                  onChange={(e) =>
                    setSortVal({
                      sort: e.item.sort,
                      order: e.item.order,
                    })
                  }
                />
              </span>
            </div>
          </div>
          {loading ? (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "25vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="d-sm-block">
                <OwlCarousel
                  className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${
                    rtl && "invertButtons"
                  }`}
                  {...homeOptions}
                  margin={10}
                  rtl={rtl}
                  nav
                >
                  <OwlItemSaved
                    key={0}
                    fill={"red_fill.png"}
                    unfill={"red_unfill.png"}
                    checkBoxText={t("Comparing")}
                    iconText={t("heartSaved")}
                    responce={saved?.ads}
                    selectedList={comp}
                    handleClick={handleClick}
                    onChange={setcomp}
                    listOfChecked={listOfChecked}
                    setlistOfChecked={setlistOfChecked}
                    page={page}
                  />
                </OwlCarousel>
              </div>
              <div className="d-sm-none d-block saved_cr">
                {saved?.length > 0 ? (
                  <OwlItemSaved
                    key={1}
                    fill={"red_fill.png"}
                    unfill={"red_unfill.png"}
                    iconText={t("Saved")}
                    checkBoxText={t("Comparing")}
                    responce={saved}
                    selectedList={comp}
                    handleClick={handleClick}
                    onChange={setcomp}
                    page={page}
                  />
                ) : (
                  loading == true && t("Please wait...")
                )}
              </div>

              {saved && (
                <div class="text-center stscrl">
                  {show == false ? (
                    <>
                      <Button
                        style="srcbtn"
                        onClick={() => {
                          clearAll(true, "saved");
                        }}
                        title={t("Compare now")}
                      />
                      <br />
                    </>
                  ) : (
                    <Button
                      style="reset"
                      onClick={() => {
                        clearAll(false, "");
                      }}
                      title={t("Cancel")}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
