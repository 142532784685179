import React, { useEffect, useRef } from "react";
import { isImageOrVideo } from "../../../utils/constants";
import i18n from "../../../i18n";

export default function ScrollMenuComponent({ imageArray }) {
  const active = i18n.language === "English" ? 0 : imageArray.length - 1;
  const horiRef = useRef();

  useEffect(() => {
    const container = horiRef.current;
    if (i18n.language !== "English") {
      container.scroll({ left: container.scrollWidth });
    }
    container.addEventListener("wheel", function (e) {
      if (e.deltaY > 0) {
        e.preventDefault();
        container.scroll({
          left: container.scrollLeft - 300,
          behavior: "smooth",
        });
      } else {
        e.preventDefault();
        container.scroll({
          left: container.scrollLeft + 300,
          behavior: "smooth",
        });
      }
    });
  }, []);

  const generateJustifyContent = () => {
    if (imageArray.length < 6) {
      if (i18n.language === "English") {
        return "flex-start";
      } else {
        return "flex-end";
      }
    } else {
      return "unset";
    }
  };

  return (
    <div className="reverseScroll" style={{ direction: "ltr" }}>
      <ol
        ref={horiRef}
        class="carousel-indicators slides thinScroll"
        style={{
          overflowX: "scroll",
          justifyContent: generateJustifyContent()
        }}
      >
        {imageArray && imageArray.length > 0
          ? imageArray.map((item, index) => {
              return (
                <li
                  data-bs-target="#carouselSingle"
                  data-bs-slide-to={index}
                  aria-label={`Slide ${index}`}
                  style={{ minWidth: "100px", maxWidth: "100px" }}
                  class={index == active ? "active" : ""}
                >
                  {isImageOrVideo(item) === "video" ? (
                    <video
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    >
                      <source src={item} />
                    </video>
                  ) : (
                    <img
                      class={`w-100 `}
                      style={{ objectFit: "cover" }}
                      src={item ? item : "/assets/images/notFound.png"}
                      alt="bg"
                    />
                  )}
                </li>
              );
            })
          : ""}
      </ol>
      {/* <div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselSingle"
          data-bs-slide="prev"
        >
          <img src="../assets/images/crol_left.png" alt="img" />
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselSingle"
          data-bs-slide="next"
        >
          <img src="../assets/images/crol_right.png" alt="img" />
        </button>
      </div> */}
    </div>
  );
}
