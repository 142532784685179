// import axios from 'axios';
import BrandsData from "../data/brands.json";
import SearchedData from "../data/searchedCars.json";
import LoginResponce from "../data/loginresponce.json";
import RecentView from "../data/recentView.json";
import HomeArticle from "../data/homeArticle.json";
import RegisterResponce from "../data/register.json";
import DetailResponce from "../data/detail.json";
import fetchFormsDetails from "../data/formsdata.json";
import fetchChacieDetails from "../data/chacie.json";
// import chacieNotFound from '../data/chacieNotFound.json';
import fetchSaveCar from "../data/saveCar.json";
import uploadFormData from "../data/uploadFormData.json";
import withoutVin from "../data/withoutVin.json";
import plansData from "../data/plans.json";
import feature from "../data/feature.json";
import stock from "../data/stock.json";
import popularitem from "../data/popular.json";
import benefites from "../data/benefites.json";
import SingleCar from "../data/singleCar.json";
import { describes } from "../Components/Pages/MyAccount/content";
import myvehicle from "../data/myvechicle.json";
import editVehicle from "../data/editVehicle.json";
import myprofile from "../data/myprofile.json";
import transactions from "../data/transaction.json";
import messages from "../data/messages.json";
import perfomance from "../data/perfomance.json";
import advanceSearch from "../data/advance_search.json";
import filter from "../data/filter.json";
import recentSearch from "../data/recentSearch.json";
import SearchResultpageDealer from "../data/searchResultpageDealer.json";
import dealerFilterSilce from "../data/dealerFilterSlice.json";
import dealerRental from "../data/dealerRental.json";
import cookies from "../data/cookies.json";
import { apiManager } from "../api/connectAPI";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { cities } from "../data/content";
import {
  camelToNormal,
  checkNullUndefineReturnEmpty,
  createFiltersString,
  getYearsFrom1950ToCurrent,
  itemLimit,
} from "../utils/constants";
import { t } from "i18next";

const API_BASE_URL = process.env.APIURL; // Replace with your API base URL

const lang =
  i18n.language === "English" ? "en" : i18n.language === "Arabic" ? "ar" : "ku";

export const fetchcookiesdetails = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/brands/`);
    const response = cookies;
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchdealerCarInfo = async ({ dealerId, setLoading }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  const response = SearchResultpageDealer;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = apiManager("GET", `opp/dealers?lang=${lang}&id=${dealerId}`)
    .then((res) => {
      if (res.status) {
        setLoading(false);
        return res;
      }
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
      setLoading(false);
    });

  return apiCall;
};

export const fetchDealerList = async ({
  dataId,
  page,
  itemLimit,
  setLoading,
  filters,
  sort,
}) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  const staticData = dealerFilterSilce;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  // {{baseURL}}opp/dealers?lang=en&dealerShipName=&userName=&fullName=Rishabh&email=&mobile=&city=&country=&page=1&limit=10&sort=createdAt,desc

  const apiCall = apiManager(
    "GET",
    `opp/dealers?lang=${lang}&page=${page}&limit=${itemLimit}&city=${
      filters.city
    }&distance=${""}&dealerShipName=${filters.name}&fullName=${
      filters.name
    }&sort=${sort ? sort : ""}`
  )
    .then((res) => {
      if (res.status) {
        setLoading(false);
        return res;
      }
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
      setLoading(false);
    });

  return apiCall;
};

export const fetchEditVehicle = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/brands/`);
    const response = editVehicle;
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchmyprofileinfo = async (rejectWithValue) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  //   const response = myprofile;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = apiManager("GET", `user/profile?lang=${lang}`)
    .then((res) => {
      if (res.status) {
        localStorage.setItem("myProfile", JSON.stringify(res.data));
        
        return res.data;
      } else {
        return rejectWithValue(res.message);
      }
    })
    .catch((err) => {
      return rejectWithValue(err.response);
    });

  return apiCall;
};

export const fetchTransactioneDetails = async ({page}) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  //   const response = transactions;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = await apiManager("GET", `user/transactions?lang=${lang}&page=${page}`)
    .then((res) => {
      if (res.status) {
        return res.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

//  fetchchat 9 date changes
export const fetchchat = async () => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  // const response = messages;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = await apiManager("GET", `chat/list?lang=${lang}`)
    .then((res) => {
      if (res.status) {
        return res;
      } else {
        return { chats: [] };
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

export const fetchMessages = async ({ chatId, currentPage }) => {
  const apiCall = apiManager(
    "GET",
    `chat/message?lang=${lang}&chatId=${chatId}&limit=500`
  )
    .then((res) => {
      if (res.status) {
        return res.data;
      } else {
        console.log(res);
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

export const fetchPerfomance = async ({ type, month }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  const response = perfomance;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }
  console.log(month);
  const apiCall = await apiManager(
    "GET",
    `user/performance?lang=${lang}&type=${type}${
      type === "monthly" ? `&month=${month}` : ""
    }`
  )
    .then((res) => {
      if (res.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const mostViewed = async () => {
  const apiCall = await apiManager("GET", `user/mostViewed?lang=${lang}`)
    .then((res) => {
      if (res.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getStats = async () => {
  const apiCall = await apiManager("GET", `user/states?lang=${lang}`)
    .then((res) => {
      if (res.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

//

export const fetchBrandsData = async () => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  //   const response = BrandsData;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = await getBrands();
  return apiCall.data;
};

export const fetchSearchedData = async ({ brandId, id, page }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/searched/`);
  //   const response = SearchedData;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const getDealerCars = async () => {
    const cars = await fetchFilteredSearchResults({
      page: page,
      limit: 10,
      currentPage: 0,
      brandId: brandId,
      ownerId: null,
      //filter for recently viewed cars
    });
    return { default_active: cars.data };
  };

  return getDealerCars();
};

export const fetchBrandByPostData = async ({ bodyType, id, page }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/searched/`);
  //   const response = SearchedData;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }
  console.log(bodyType);
  const getDealerCars = async () => {
    const cars = await fetchFilteredSearchResults({
      page: page,
      limit: 10,
      currentPage: 0,
      ownerId: null,
      //filter for recently viewed cars
      filters: { bodyType: [bodyType] },
    });
    return { default_active: cars.data };
  };

  return getDealerCars();
};

export const fetchHomeArticleData = async ({ pageSize, sortBy }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/homeArticle/`);
  //   const response = HomeArticle;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  let config = {
    method: "GET",
    headers: {
      authorization:
        "Bearer 22219e3bcdcce6cb9613dd61cf567678268e9ba7b0fae76bb0272c3e79fdf4b4104539582031b7a263ba56153a6c6e13141713d09ea666bbd09d70f3e6a2a16a5f72d012c3096df772c2284109d295e5911e9627f72c7f624093a241072294e259bb9a5d78f8fe5443662b1dea6deae889dbb24c762573b815fc5f5f1a96ea3a",
    },
  };
  //pagination[page]=1&pagination[pageSize]=4
  return fetch(
    `https://strapi.akosayara.app/api/articles?sort[0]=createdAt:${sortBy}&pagination[page]=1&pagination[pageSize]=${pageSize}&filters[id][$gt]=4`,
    config
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchRecentData = async ({ page }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/recentView/`);
  //   const response = RecentView;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  // const getDealerCars = async () => {
  //   const cars = await fetchDefaultSearch({
  //     page: page,
  //     limit: 10,
  //     currentPage: 0,
  //     brandId: null,
  //     ownerId: null,
  //     //filter for recently viewed cars
  //   });
  //   return cars.data;
  // };
  let adTypeId = "";
  const adData = await getAdTypes();
  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  // return getDealerCars();
  const apiCall = await apiManager(
    "GET",
    `opp/recentlyViewedAds?lang=${lang}&adType=${adTypeId}`
  ).then((res) => {
    if (res.status) {
      return res?.data?.ads;
    } else {
      return null;
    }
  });

  return apiCall;
};

export const submitLoginForm = async (formData, rejectWithValue) => {
  // try {
  //   const response = LoginResponce;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = apiManager("POST", `auth/login?lang=${lang}`, formData)
    .then((res) => {
      if (res.status) {
        let finalData = res;

        finalData = {
          ...finalData,
          data: {
            ...finalData.data,
            form: "pending",
            name: finalData.data.fullName,
            id: finalData.data.userId,
            profile_image: "",
          },
        };

        localStorage.setItem("user", JSON.stringify(finalData.data));
        localStorage.setItem("token", finalData.token);
        localStorage.setItem("userType", finalData.data.userType);
        // toast.success(res.message);
        return finalData;
      } else {
        toast.error(res.message);
        return rejectWithValue(res.message);
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong!");
      return rejectWithValue(err.response);
    });

  return apiCall;
};

export const registerFormSubmit = async (data, setLoading, rejectWithValue) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/registerUser/`);
  //   const response = RegisterResponce;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  const apiCall = await apiManager("POST", `user/profile?lang=${lang}`, data)
    .then((res) => {
      if (res.status) {
        let finalData = res;

        finalData = {
          ...finalData,
          data: {
            ...finalData.data,
            form: "pending",
            name: finalData.data.fullName,
            id: finalData.data.userId,
            profile_image: "",
          },
        };

        localStorage.setItem("user", JSON.stringify(finalData.data));
        localStorage.setItem("token", finalData.token);
        localStorage.setItem("userType", data.userType);

        setLoading(false);
        return finalData;
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong!");
      setLoading(false);
      return rejectWithValue(err.response);
    });

  return apiCall;
};

export const detailFormSubmit = async (formData) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/registerUser/`);
  // const response = DetailResponce;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  console.log(formData);

  const apiCall = await apiManager("PUT", "user/profile?lang=en", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

export const fetchFormDetail = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/---------/`);
    const response = fetchFormsDetails;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchCheapFormDetail = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/---------/`);
    const response = fetchFormsDetails;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchFeatureDetail = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/-------/`);
    const response = feature;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchpopularModalDetail = async ({ page }) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/--------/`);
  //   const response = RecentView;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  const getDealerCars = async () => {
    const cars = await fetchFilteredSearchResults({
      page: page,
      limit: 10,
      currentPage: 0,
      brandId: null,
      ownerId: null,
      //filter for recently viewed cars
    });
    return cars;
  };

  return getDealerCars();
};

export const fetchNewcarsDetail = async () => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = fetchFormsDetails;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchStockDetail = async () => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = stock;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchPopularItemsDetail = async () => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = popularitem;
    return response;
  } catch (error) {
    throw error;
  }
};

export const benefitsFuncation = async () => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = benefites;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSearchedElectricCar = async () => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = SearchedData;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchCarByChaice = async ({ chasis, type }) => {
  // try {
  //   const response = fetchChacieDetails;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  console.log(chasis);
  let apiCall = null;
  if (type === "check") {
    apiCall = await apiManager("POST", `opp/findMyCar?lang=${lang}`, chasis)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  } else {
    apiCall = await apiManager(
      "GET",
      `opp/getCarVinDecoder?lang=${lang}&vin=${chasis}`
    )
      .then((res) => {
        return { status: res.status, message: res.message, data: { ...res } };
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  }

  return apiCall;
};

export const sendResetPasswordOTP = (data) => {
  const apiCall = apiManager(
    "POST",
    `auth/sendresetPasswordOTP?lang=${lang}`,
    data
  )
    .then(async (res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

export const sendVerificationOTP = (data) => {
  if (!data?.email && !data?.phoneNumber) return;
  const type = data?.email ? "email" : "phoneNumber";

  const apiCall = apiManager(
    "POST",
    `auth/${type === "email" ? "sendEmailOtp" : "sendTextOtp"}?lang=${lang}`,
    data
  )
    .then(async (res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};
export const sendWhatsappOtp = (data) => {
  const apiCall = apiManager('POST', `auth/sendWhatsAppOtp?lang=${lang}`, data).then(res => {
    return res
  })
  .catch(err => {
    console.log(err)
  })
  return apiCall
}

export const sendWelcomeMail = () => {
  const apiCall = apiManager("POST", `user/sendWelcomeMail?lang=${lang}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err.message);
    });
  return apiCall;
};

export const saveCarFormSubmit = async (formData, rejectWithValue) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  // const response = fetchSaveCar;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  const apiCall = await apiManager(
    "POST",
    "storage/upload?lang=en&type=2",
    formData,
    null,
    "multipart/form-data"
  )
    .then((res) => {
      if (res.status) {
        const data = {
          additionalInfo: {
            dealerKycDocuments: [res.data[0].s3URL],
          },
        };

        apiManager("PUT", "user/profile?lang=en", data)
          .then((res1) => {
            if (res1.status) {
              // toast.success(res1.message);
            } else {
              toast.error(res1.message);
            }
            return res;
          })
          .catch((err) => {
            console.error(err);
            toast.error("Could not upload document");
          });
      } else {
        toast.error(res.message);
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong!");
      return rejectWithValue(err.response);
    });

  console.log(apiCall);
};

export const fetchUploadFormData = async (formData) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
    const response = uploadFormData;
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEntityTypes = () => {
  const apiCall = apiManager("GET", `general/entityType?lang=${lang}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

const getBrands = () => {
  const apiCall = apiManager("GET", `general/brand?lang=${lang}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const getAdTypes = () => {
  const apiCall = apiManager("GET", `general/adType?lang=${lang}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const getMake = async (isLive, limit, ownerId, page) => {
  const live = isLive ? "1" : "0";
  let adTypeId = "";
  const adData = await getAdTypes();

  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const apiCall = apiManager(
    "GET",
    `general/makes?lang=${lang}&limit=${
      limit ? limit : "200"
    }&adType=${adTypeId}&isLiveAds=${live || ""}&ownerId=${
      ownerId ? ownerId : ""
    }`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const getModels = async (makeId, live, ownerId, page) => {
  if (makeId === t("Any")) makeId = "";

  let adTypeId = "";
  const adData = await getAdTypes();
  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  console.log(page);
  const apiCall = apiManager(
    "GET",
    `opp/getModelNames?lang=${lang}&makeId=${makeId}&isLiveAds=${
      live ? "1" : "0"
    }&ownerId=${ownerId ? ownerId : ""}&adType=${page ? adTypeId : ""}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const getAdFiltersList = async (page, ownerId) => {
  let adTypeId = "";
  const adData = await getAdTypes();
  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }
  const apiCall = apiManager(
    "GET",
    `general/adFilters?lang=${lang}&adType=${adTypeId}&ownerId=${
      ownerId ? ownerId : ""
    }`
  )
    .then((res) => {
      if (res?.status) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const getAllModels = () => {
  const apiCall = apiManager("GET", `opp/getAllModels?lang=${lang}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });
  return apiCall;
};

export const fetchWithoutVinData = async (formData) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  // const response = withoutVin;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  let response = withoutVin;

  let makeData = await getMake(false, 200);
  let bodyTypes = await getEntityTypes();
  let adData = await getAdTypes();
  let makeTemp = makeData?.makes;

  response.data["make"] = makeTemp;
  response.data["body"] = bodyTypes.data;
  response.data["adTypes"] = adData.data;
  response.data["yearsList"] = getYearsFrom1950ToCurrent();

  return response;
};
export const saveCarWithoutVinFormSubmit = async (
  formData,
  add,
  adId,
  entityId,
  setLoading,
  rejectWithValue
) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  //   const response = fetchSaveCar;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  console.log(adId);
  const apiCall = apiManager(
    add ? "POST" : "PUT",
    add ? `opp/ad?lang=${lang}` : `opp/ad?lang=${lang}&adId=${adId}`,
    formData
  )
    .then((res) => {
      if (res.status) {
        // if (!add) toast.success(res.message);
        setLoading(false);
        if (!add) {
          return { adId: adId };
        }
        return res;
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    })
    .catch((err) => {
      toast.error(err.message);
      setLoading(false);
      rejectWithValue(err.message);
    });

  // if (!add) {
  //   const res = await addOrUpdateAd(formData, "", "PUT", adId);
  // }

  return apiCall;
};

export const addOrUpdateAd = async (data, page, method, adId, dataList) => {
  let tags = [];
  Object.keys(data?.properties?.features).forEach((key) => {
    if (data?.properties?.features[key] && key !== "anyThingElse") {
      tags.push(camelToNormal(key));
    }
  });
  const city = cities.filter(
    (v) => v?.name?.[i18n.language] === data?.properties?.city
  );
  let anythingElse = data?.properties?.features?.anyThingElse?.split(",");
  tags = [...tags, ...anythingElse];

  const adTypes = await getAdTypes();

  const adData = {
    entityId: data?._id,
    adTypeId:
      page === "RentYourCar"
        ? adTypes?.data?.[1]?._id
        : adTypes?.data?.[0]?._id,
    categoryId: data.categoryId,
    subcategoryId: data.subcategoryId,
    adTitle: {
      en: data?.name?.["en"],
      ar: data?.name?.["ar"],
      ku: data?.name?.["ku"],
    },
    adDescription: {
      en: data?.description?.["en"],
      ar: data?.description?.["ar"],
      ku: data?.description?.["ku"],
    },
    city: {
      en: city[0]?.name?.English,
      ar: city[0]?.name?.Arabic,
      ku: city[0]?.name?.Kurdish,
    },
    country: {
      en: "country",
      ar: "country",
      ku: "country",
    },
    latitude: "32",
    longitude: "23",
    askPrice: data?.properties?.price?.daily
      ? data?.properties?.price?.daily
      : data?.properties?.price
      ? data?.properties?.price
      : "",
    askPrice1: data?.properties?.price?.weekly
      ? data?.properties?.price?.weekly
      : "",
    askPrice2: data?.properties?.price?.monthly
      ? data?.properties?.price?.monthly
      : "",
    currency: "$",
    tags: tags,
  };

  if (method === "PUT") delete adData["adTypeId"];

  const apiCall = apiManager(
    method,
    method === "POST"
      ? `opp/ad?lang=${lang}`
      : `opp/ad?lang=${lang}&adId=${adId}`,
    adData
  )
    .then((res) => {
      if (res.status) {
        return res;
      } else {
        toast.error(res.message);
      }
    })
    .catch((err) => {
      toast.error(err.message);
      console.error(err);
    });
  return apiCall;
};

export const verifyOtp = async ({ otp, type, identifier }) => {
  const data = {
    otp: otp,
    identifier: identifier, //email || phoneNumber
    type: type //Email || Text
  };
  console.log(data);
  const apiCall = await apiManager("POST", `auth/verifyOTP?lang=${lang}`, data)
    .then((res) => {
      if (res.status) {
        return res;
      } else {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const fetchNewBrandsData = async (formData) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  //   const response = RecentView;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const getDealerCars = async () => {
    const cars = await fetchFilteredSearchResults({
      page: formData.page,
      limit: 10,
      currentPage: 0,
      brandId: null,
      ownerId: null,
      //filter for brand new cars
      filters: {
        brandNew: true,
      },
    });
    return cars.data;
  };

  return getDealerCars();
};

export const likeDislikePost = async (adId, perform, setLoading) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  //   const response = {
  //     status: "success",
  //     message: "your post liked successfully",
  //   };
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  const method = perform === "add" ? "POST" : "DELETE";

  const apiCall = apiManager(
    method,
    `opp/ad/favorite?lang=${lang}&adId=${adId}`,
    {}
  )
    .then((res) => {
      if (res.status) {
        // toast.success(res.message);
        setLoading?.(false);
        return res;
      } else {
        toast.error(res.message);
        setLoading?.(false);
      }
    })
    .catch((err) => {
      // toast.error(err.message);
      setLoading?.(false);
      return err;
    });
  return apiCall;
};

export const fetchPlansData = async (data) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/saveCar/`,formData);
  //   const response = plansData;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  const adData = await getAdTypes();
  const type =
    data.type === "personal" || data.type === "user"
      ? "individual"
      : data.type === "dealer"
      ? "dealer"
      : "checkMyCar";
  let adTypeId = "";
  if (data.page == "SellYourCar") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (data.page == "RentYourCar") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const apiCall = await apiManager(
    "GET",
    `admin/subscriptions?lang=${lang}&adType=${adTypeId}&type=${type}`
  )
    .then((res) => {
      if (res?.status) {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return apiCall;
};

export const makePayment = async (data) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/makePayment/`,formData);
    const response = { status: "success", message: "Process completed" };
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSavedCar = async (data) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/makePayment/`,formData);
  //   const response = RecentView;
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  console.log(data);
  const apiCall = apiManager(
    "GET",
    `opp/ads/favorite?lang=${lang}&sort=${data?.sort ? data?.sort : ""}`
  )
    .then((res) => {
      data?.setLoading?.(false);
      if (res.status) {
        return res;
      } else {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.message);
      data?.setLoading?.(false);
      // rejectWithValue(err.message);
    });

  return apiCall;
};

export const fetchSingleCar = async ({ id, sold }) => {
  // try {
  //   const responce = SingleCar;
  //   return responce.data;
  // } catch (error) {
  //   throw error;
  // }
  const apiCall = apiManager(
    "GET",
    `opp/filteredAds?lang=${lang}&adId=${id}${sold ? "&sold=YES" : ""}`
  )
    .then((res) => {
      if (res.status) {
        let response = { ...filter.data, data: res.data.ads };
        return response;
      } else {
        toast.error(res.message);
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.message);
    });

  return apiCall;
};

export const fetchMyvehicle = async ({
  setLoading,
  sold,
  live,
  soonExpire,
}) => {
  // try {
  //   //const response = await axios.get(`${API_BASE_URL}/brands/`);
  // const response = myvehicle;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }
  const ownerId = JSON.parse(localStorage.getItem("user")).id;
  const apiCall = apiManager(
    "GET",
    `opp/filteredAds?lang=${lang}&limit=200&ownerId=${ownerId}${
      sold ? "&sold=YES" : ""
    }${live ? "&sold=NO&display=ON" : ""}${
      soonExpire ? "&isExpiringSoon=YES" : ""
    }`
  ) //&sold=YES
    .then((res) => {
      if (res.status) {
        setLoading?.(false);
        return res;
      } else {
        setLoading?.(false);
        return res;
      }
    })
    .catch((err) => {
      toast.error(err.message);
      console.error(err);
      setLoading?.(false);
    });

  return apiCall;
};

export const fetchcontactUsdetails = async () => {
  try {
    //const response = await axios.get(`${API_BASE_URL}/brands/`);
    const response = describes;
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchRentalDetail = async ({ page }) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = RecentView;
    return response;
  } catch (error) {
    throw error;
  }
};

export const contactFormSubmit = async ({
  formData,
  setLoading,
  reset,
  setSuccessMessage,
}) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
  //   const response = [];
  //   return response;
  // } catch (error) {
  //   throw error;
  // }
  if (setLoading) setLoading(true);
  const apiCall = apiManager("POST", `general/contactUs?lang=${lang}`, formData)
    .then((res) => {
      if (res.status) {
        if (setLoading) setLoading(false);
        setSuccessMessage?.(res.message);
        reset?.();
        return res;
      }
      if (setLoading) setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      if (setLoading) setLoading(false);
    });
  return apiCall;
};

export const fetchRecentSearchDataSet = async (formData) => {
  try {
    const response = recentSearch;
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchAdvanceSearchDataSet = async (formData) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = advanceSearch;
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userActivity = (id) => {
  const data = {
    type: "view",
    adId: id,
  };
  apiManager("POST", `user/userActivity?lang=${lang}`, data)
    .then((res) => {
      if (res.status) {
        console.log(`Ad ${id} saved in user activity`);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchDefaultSearch = async ({
  page,
  limit,
  currentPage,
  brandId,
  ownerId,
  setLoading,
}) => {
  if (!ownerId) ownerId = "";
  if (!brandId) brandId = "";

  const adData = await getAdTypes();
  let adTypeId = "";

  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const apiCall = apiManager(
    "GET",
    `opp/ads?lang=${"en"}&display=ON&adType=${adTypeId}&page=${currentPage}&limit=${limit}&brandId=${brandId}&ownerId=${ownerId}`
  )
    .then((res) => {
      if (res.status) {
        let response = {
          ...filter.data,
          data: res.data.ads,
          totalPage: res.data.totalPages,
        };
        if (setLoading) setLoading(false);
        return response;
      } else {
        if (setLoading) setLoading(false);
        let response = {
          ...filter.data,
          data: [],
          totalPage: 0,
        };
        return response;
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.message);
      if (setLoading) setLoading(false);
    });

  return apiCall;
};

export const fetchFilteredSearchResults = async ({
  filters,
  page,
  limit,
  currentPage,
  ownerId,
  setLoading,
  sort,
}) => {
  if (!ownerId) ownerId = "";

  const adData = await getAdTypes();
  let adTypeId = "";

  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const filtersString = createFiltersString(filters);
  const apiCall = await apiManager(
    "GET",
    `opp/filteredAds?lang=${"en"}&display=ON&adType=${adTypeId}&page=${currentPage}&limit=${limit}&ownerId=${ownerId}${filtersString}&sort=${
      sort ? sort : ""
    }`
  )
    .then((res) => {
      if (res.status) {
        let response = {
          // ...filter.data,
          data: res.data.ads,
          totalPage: res.data.totalPages,
        };
        if (setLoading) setLoading(false);
        return response;
      } else {
        if (setLoading) setLoading(false);
        let response = {
          ...filter.data,
          data: [],
          totalPage: 0,
        };
        return response;
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.message);
      if (setLoading) setLoading(false);
    });

  return apiCall;
};

export const fetchSingleSearching = async (formData) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    const response = filter;
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const serachFilterSearch = async (formData) => {
  try {
    //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
    // const response = filter;
    return {};
  } catch (error) {
    throw error;
  }
};

export const fetchDefaultSearchRental = async ({
  page,
  currentPage,
  ownerId,
  filters,
  sort,
}) => {
  // try {
  //   //const response = await axios.post(`${API_BASE_URL}/-----/`,formData);
  const response = dealerRental;
  //   return response.data;
  // } catch (error) {
  //   throw error;
  // }

  const getDealerCars = async () => {
    const cars = await fetchFilteredSearchResults({
      page: page,
      limit: itemLimit,
      currentPage: currentPage,
      brandId: null,
      ownerId: ownerId,
      filters: filters,
      sort: sort,
    });
    let finalData = {
      data: cars.data,
      totalPage: cars.totalPage,
      sideBar: response.data.sideBar,
    };
    return finalData;
  };

  return getDealerCars();
};

export const getSiteContent = async (type) => {
  // const apiCall = await apiManager(
  //   "GET", `https://strapi.akosayara.app/api/contents/2lang=${lang}&type=${type}`, null, {"Authorization": 'headerForSiteContent'}
  // )
  //   .then((res) => {
  //     if (res.status) {
  //       return res;
  //     } else {
  //       toast.error(res.message);
  //     }
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //     toast.error(err.message);
  //   });

  let config = {
    method: "GET",
    headers: {
      authorization:
        "Bearer 22219e3bcdcce6cb9613dd61cf567678268e9ba7b0fae76bb0272c3e79fdf4b4104539582031b7a263ba56153a6c6e13141713d09ea666bbd09d70f3e6a2a16a5f72d012c3096df772c2284109d295e5911e9627f72c7f624093a241072294e259bb9a5d78f8fe5443662b1dea6deae889dbb24c762573b815fc5f5f1a96ea3a",
    },
  };

  return fetch(
    `https://strapi.akosayara.app/api/contents/?filters[type][$eq]=${type}`,
    config
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getArticles = async (id) => {
  let config = {
    method: "GET",
    headers: {
      authorization:
        "Bearer 22219e3bcdcce6cb9613dd61cf567678268e9ba7b0fae76bb0272c3e79fdf4b4104539582031b7a263ba56153a6c6e13141713d09ea666bbd09d70f3e6a2a16a5f72d012c3096df772c2284109d295e5911e9627f72c7f624093a241072294e259bb9a5d78f8fe5443662b1dea6deae889dbb24c762573b815fc5f5f1a96ea3a",
    },
  };

  return fetch(`https://strapi.akosayara.app/api/articles/${id}`, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const removeDealerAd = (id) => {
  const apiCall = apiManager("DELETE", `opp/ad?lang=${lang}&adId=${id}`)
    .then((res) => {
      if (res.status) {
        // toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error(err.message);
    });

  return apiCall;
};

export async function translateInTwoLanguages(from, text) {
  console.log(from);
  console.log(text);
  let toLangsArray = [];
  if (from === "ku") from = "ckb";

  if (from == "en") {
    toLangsArray = ["ar", "ckb"];
  } else if (from == "ar") {
    toLangsArray = ["en", "ckb"];
  } else if (from == "ckb") {
    toLangsArray = ["en", "ar"];
  }

  function data() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[0], text));
      reject("error");
    });
  }

  function data1() {
    return new Promise((resolve, reject) => {
      resolve(googleTranslate(from, toLangsArray[1], text));
      reject("error");
    });
  }

  let translatedText = await data();
  let translatedText1 = await data1();

  let translatedObj = {
    [toLangsArray[0]]: translatedText.data.translations[0].translatedText,
    [toLangsArray[1]]: translatedText1.data.translations[0].translatedText,
    [from]: text,
  };
  translatedObj = { ...translatedObj, ku: translatedObj?.ckb };

  delete translatedObj["ckb"];

  return translatedObj;
}

export const googleTranslate = async (from, to, text1) => {
  let fromLang = from;
  let toLang = to; // translate to norwegian
  let text = text1;

  const API_KEY = "AIzaSyAxgfTH1nTQOILPbg3PfmRwAztgDeFWR0Q";

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.log("There was an error with the translation request: ", error);
    });
};
export const googleTranslateFromEnglish = async (to, text1) => {
  if (to === "en") {
    return { data: { translations: [{ translatedText: text1 }] } };
  }

  let fromLang = "en";
  let toLang = to; // translate to norwegian
  let text = text1;

  const API_KEY = "AIzaSyAxgfTH1nTQOILPbg3PfmRwAztgDeFWR0Q";

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.log("There was an error with the translation request: ", error);
    });
};

export const initiateChat = async (adId) => {
  const data = {
    adId: adId,
    messageMetadata: {
      type: "enquiry",
      enquiryType: "test drive",
      enquiryDate: "2020-10-30",
    },
    enquiry: false,
  };
  const apiCall = await apiManager("POST", `chat/init?lang=${lang}`, data)
    .then((res) => {
      if (res.status) {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getInvoiceUrl = (id) => {
  const apiCall = apiManager(
    "GET",
    `storage/getLink?lang=${lang}&transactionId=${id}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const changePreferredLanguage = ({ value }) => {
  const langValue =
    value === "English" ? "en" : value === "Arabic" ? "ar" : "ku";
  const apiCall = detailFormSubmit({ preferredLanguage: langValue }).then(res => {
    return res
  })
  .catch(err => {
    console.log(err)
  })
  return apiCall
};

export const generateFreeInvoice = (data) => {
  const apiCall = apiManager('POST', `opp/generateFreeInvoice?lang=${lang}`, data).then(res => {
    return res;
  })
  .catch(err => {
    console.log(err)
  })
  return apiCall
}