import React from "react";
import SelectCustomItems from "../../SelectCustomItems";
import SelectForm from "../../SelectForm";
import { useSelector } from "react-redux";
import { t } from "i18next";

export default function Basic({
  data,
  selectedMake,
  setSelectedMake,
  register,
  errors,
  models,
  setValue,
  getValues,
}) {
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  return (
    <div class="other_div other_div2">
      <h3>{t("Basic Details")}</h3>
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Make")}*</label>
            {/* <SelectForm
                  style={"form-control"}
                  selectedOption={data?.properties?.basicDetails?.make?.[lang2]}
                  event={register}
                  name={"make"}
                  values={upload_list?.make}
                /> */}
            <SelectCustomItems
              selectedOption={selectedMake}
              items1={upload_list?.make?.slice(0, 10)}
              items2={upload_list?.make}
              title1={"Most searched for"}
              title2={"All makes"}
              placeholder={t("Select")}
              handelChange={(e) => {
                setValue("make", e?.value);
                setSelectedMake(e?.item);
              }}
              showDropdownIcon
              width={"100%"}
              hasSelected={data?.properties?.basicDetails?.make?.[lang2]}
            />
            {errors.make && getValues("make") === t("Select") && (
              <p>{errors.make.message}</p>
            )}
            {/* <DynamicSelectComponent
                  name={"make"}
                  placeholder={t("Select")}
                  setValue={setValue}
                  optionsBuilt={upload_list.make}
                />
                {errors.make && <p>{errors.make.message}</p>} */}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Model")}*</label>
            <SelectForm
              style={"form-control"}
              // selectedOption={data?.properties?.basicDetails?.model?.[lang2]}
              event={register}
              name={"model"}
              values={models}
              disabled={!models || models?.length == 0}
            />
            {/* <DynamicSelectComponent
                  name={"model"}
                  placeholder={t("Select")}
                  setValue={setValue}
                  optionsBuilt={models}
                  disabled={!hasMake()}
                /> */}
            {errors.model && <p>{errors.model.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Year")}*</label>
            {/* <FormInput
                  style={"form-control"}
                  intype={"number"}
                  value={data?.properties?.basicDetails?.makeYear}
                  event={register}
                  name={"makeYear"}
                  placeholder={t("Select year")}
                /> */}
            <SelectForm
              style={"form-control"}
              selectedOption={parseInt(
                data?.properties?.basicDetails?.makeYear
              )}
              event={register}
              name={"makeYear"}
              values={upload_list.yearsList ? upload_list.yearsList : []}
            />
            {errors?.makeYear && <p>{errors?.makeYear.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Regional Specs")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={
                data?.properties?.basicDetails?.regionalSpecs?.[lang2]
              }
              event={register}
              name={"regional"}
              values={upload_list.regional ? upload_list.regional : []}
            />
            {errors.regional && <p>{errors.regional.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Body Condition")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={
                data?.properties?.basicDetails?.condition?.[lang2]
              }
              event={register}
              name={"condition"}
              values={upload_list.condition ? upload_list.condition : []}
            />
            {errors.condition && <p>{errors.condition.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Colour")}*</label>
            <SelectForm
              type={"color"}
              style={"form-control"}
              selectedOption={data?.properties?.basicDetails?.color?.[lang2]}
              event={register}
              name={"colour"}
              values={upload_list.colour ? upload_list.colour : []}
            />
            {/* <input
                  type="color"
                  placeholder={t("Select Colour")}
                  onChange={(e) => setValue("colour", e.target.value)}
                  className={"form-control"}
                  defaultValue={data?.properties?.basicDetails?.color}
                /> */}
            {errors.colour && <p>{errors.colour.message}</p>}
          </div>
          {/* <div className="col-1"></div>
              <div className="col-3 border rounded-2" style={{marginTop: '44px', backgroundColor: showColor}}></div> */}
        </div>
      </div>
    </div>
  );
}
