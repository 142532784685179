import React, { useState } from "react";
import FormInput from "../../Tools/FormInput";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Tools/Button";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";

//form utils imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import ListItem from "../../Tools/ListItem";
import { sendResetPasswordOTP } from "../../../Reducer/api";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = localStorage.getItem("type");
  const [selectedOption, setSelectedOption] = useState('phone')
  const [submitting, setSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrors, setPhoneNumberErrors] = useState("");


  console.log(selectedOption);
  const schema = Yup.object().shape({
    email:
    selectedOption == "email"
        ? Yup.string().required().email(t("Please Enter Valid email address"))
        : Yup.string(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmit = async (data) => {
    if (!submitting) {
      if (selectedOption === "phone") {
        if(phoneNumber.length == 0) {
          setPhoneNumberErrors(t('Phone number is required'));
          return;
        }

        data["phoneNumber"] = phoneNumber;
        delete data.email;
      }
      setSubmitting(true);

      const res = await sendResetPasswordOTP(data);
      if (res.status) {
        toast.success(res.message);
        navigate("/ForgotPassword/EnterOTP", { state: data });
      } else {
        setError('email', 'res.message')
        toast.error(res.message);
      }
    }
  };



  return (
    <section className="login">
      <div className="container">
        <div className="form-signin">
          <h1>{t("Forgot Password?")}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              class="tab-pane fade show active"
              id="With_Phone"
              role="tabpanel"
              aria-labelledby="With_Phone-tab"
            >
              {/* <div class="form-group">
            <label>{type == "email" ? t("Email") : t("Phone")}</label>
            <FormInput
              style={"form-control no-zoom"}
              intype={type == "phone" ? "number" : "email"}
              event={register}
              name={type}
              placeholder={
                type == "email"
                  ? t("Please Enter the Email Address")
                  : t("Please Enter the Phone Number")
              }
            />
            {errors.email ? (
              <p>{t("Email is a required field")}</p>
            ) : (
              errors.phone && <p>{t("Phone number is not valid")}</p>
            )}
          </div> */}
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <ListItem
                  innerhtmlType={"button"}
                  key={1}
                  btnType={"button"}
                  onClick={() => setSelectedOption("phone")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedOption == "phone" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("With Phone")}
                ></ListItem>
                <ListItem
                  innerhtmlType={"button"}
                  key={0}
                  btnType={"button"}
                  onClick={() => setSelectedOption("email")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedOption == "email" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("With Email")}
                ></ListItem>
              </ul>

              {selectedOption === "email" ? (
                <div class="form-group">
                  <label>{t("Email")}*</label>
                  <FormInput
                    style={"form-control"}
                    intype="email"
                    event={register}
                    name="email"
                    placeholder={t("Please Enter the Email Address")}
                  />
                  {errors.email ? <p>{t("Email is a required field")}</p> : ""}
                </div>
              ) : (
                <div>
                  <label>{t("Phone")}*</label>
                  <PhoneNumberInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberErrors={phoneNumberErrors}
                    setPhoneNumberErrors={setPhoneNumberErrors}
                  />
                </div>
              )}

              <div class="form-group">
                <div style={{ textAlign: "right" }}>
                  <a onClick={() => navigate("/Login")}>{t("Login")}</a>
                </div>
              </div>

              <Button
                style="btn"
                title={submitting == false ? t("Submit") : t("Please wait...")}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
